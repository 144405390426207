import React from "react";
import { useSelector } from "react-redux";
import { getRoomOption } from "../../room";
import { DocumentStyled } from "../../share-layout/components/styles.web";
import { translate } from "../i18n";

function CommonNoteLayout({
    t, isMobile, mode, expand, isLeft, setIsLeft, renderShareLeft, style
}){
    const roomCode = useSelector(state => getRoomOption(state)?.code);
    const groupId = ""; //useSelector(state => getGroupId(state) || "");

    return (
        <DocumentStyled name={APP.config.name} {...APP.config.ui.document} expand={expand}>
            { isLeft && <div className={`share_list ${!expand && !isMobile && 'non_expand'}`}>
                <div className="share_header">
                    <p>{ t(`share.${mode}`) } </p>
                </div>
            </div> }
            
            { renderShareLeft() }
            <div style={{flex: "1", position: "relative", background: "#f7f7f7"}} >
                <iframe src={`/ep/p/${roomCode}_${groupId}`} style={style}/>               
            </div>
        </DocumentStyled>
    );
}

export default translate(CommonNoteLayout);