import React, { useMemo, useState } from "react";
import { ClickButton, Input } from "../ui";
const VodSearchBar = React.memo(({ setSearch }) => {
    const [value, setValue] = useState('');
    const renderIcon = useMemo(() => {
        const onClick = () => {
            setSearch(value);
        };
        return (React.createElement(ClickButton, { className: 'search_icon', icon: 'search', color: "#000", size: 28, onClick: onClick }));
    }, [value]);
    const onKeyDown = e => {
        if (e.key === 'Space' || e.key === 'Enter' || e.key === 'NumpadEnter') {
            e.preventDefault();
            setSearch(value);
        }
    };
    return (React.createElement(Input, { className: 'search_bar', type: 'text', onKeyPress: onKeyDown, onChange: setValue, bottomLabel: renderIcon }));
});
export default VodSearchBar;
