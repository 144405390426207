import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AudioRoutePicker } from "../../../mobile/audio-mode/components";
import AbstractRoomLeave from "../../../room/AbstractRoomLeave";
import { openDialog, toggleDialog, toggleSheet } from "../../dialog";
import { isMobileBrowser } from "../../environment/utils";
import { RecordButton } from "../../members";
import { OptionDialogContainer } from "../../options";
import { SettingContainerDialog } from "../../settings";
import { ClickButton } from "../../ui";
import ChangeModeButton from "./ChangeModeButton";
import ToggleAudioButton from "./ToggleAudioButton";
import ToggleSideBar from "./ToggleSideBar";
import ToggleVideoButton from "./ToggleVideoButton";
import ToggleVideoChange from "./ToggleVideoChange";
const changeModeStyle = {
    wrapper: {
        flexDirection: "row", margin: 4, padding: 8
    }, text: {
        color: "#000", marginLeft: 12
    }
};
function PersonalSettingsButton({ className, mode, isMobile, enableLable, iconStyles, permissions, onClick }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const renderComponent = useMemo(() => {
        switch (mode) {
            case 'recordList':
                return React.createElement(RecordButton, { iconStyles: iconStyles });
            case 'camera':
                return React.createElement(ToggleVideoButton, { key: mode, className: className, iconStyles: iconStyles, label: enableLable ? t(`setting.${mode}`) : undefined, enableLable: enableLable });
            case 'mic':
                return React.createElement(ToggleAudioButton, { key: mode, className: className, iconStyles: iconStyles, label: enableLable ? t(`setting.${mode}`) : undefined, enableLable: enableLable });
            case 'text-out':
                onClick = () => dispatch(openDialog(AbstractRoomLeave));
                return React.createElement(ClickButton, { key: mode, isText: true, className: className, label: t(`setting.out`), styles: iconStyles, onClick: onClick });
            case 'out':
                onClick = () => dispatch(openDialog(AbstractRoomLeave));
                return React.createElement(ClickButton, { key: mode, className: className, icon: mode, label: enableLable ? t(`setting.out`) : undefined, size: 20, styles: iconStyles, onClick: onClick });
            case "chat":
                return React.createElement(ToggleSideBar, { key: mode, mode: mode, className: className, iconStyles: iconStyles, isText: false, label: enableLable ? t(`setting.${mode}`) : undefined, enableLable: enableLable });
            // 참석자 on/off
            case 'member':
                return React.createElement(ToggleSideBar, { key: mode, mode: mode, className: className, iconStyles: iconStyles, isText: false, label: enableLable ? t(`setting.${mode}`) : undefined, enableLable: enableLable });
            case 'option':
                const showDialog = (navigator.product === 'ReactNative') ? toggleSheet : toggleDialog;
                // @ts-ignore
                onClick = () => dispatch(showDialog(OptionDialogContainer, { permissions, isMobile }));
                return (React.createElement(ClickButton, { key: mode, className: className, icon: mode, label: enableLable ? t(`setting.${mode}`) : undefined, size: 20, styles: iconStyles, onClick: onClick }));
            case 'setting':
                onClick = () => dispatch(toggleDialog(SettingContainerDialog, { permissions }));
                return (React.createElement(ClickButton, { key: mode, className: className, icon: mode, label: enableLable ? t(`setting.${mode}`) : undefined, size: 20, styles: iconStyles, onClick: onClick }));
            case 'modeChange':
                if (permissions.indexOf("layout") === -1 && permissions.indexOf("share") === -1)
                    return null;
                onClick = () => {
                    const showDialog = (navigator.product === 'ReactNative') ? toggleSheet : toggleDialog;
                    // @ts-ignore
                    dispatch(showDialog(ChangeModeButton, { permissions }));
                };
                return React.createElement(ClickButton, { className: className, icon: mode, size: 20, label: enableLable ? t(`setting.${mode}`) : undefined, styles: iconStyles, onClick: onClick });
            case 'videoChange':
                if (isMobileBrowser()) {
                    return React.createElement(ToggleVideoChange, { key: mode, className: className, iconStyles: iconStyles, enableLable: enableLable, mode: mode, label: enableLable ? t(`setting.${mode}`) : undefined });
                }
            case 'micChange':
                if (isMobileBrowser()) {
                    onClick = () => {
                        const showDialog = (navigator.product === 'ReactNative') ? toggleSheet : toggleDialog;
                        // @ts-ignore
                        dispatch(showDialog(AudioRoutePicker));
                    };
                    return React.createElement(ClickButton, { key: mode, className: className, icon: mode, size: enableLable ? 20 : 24, label: enableLable ? t(`setting.${mode}`) : null, styles: iconStyles, onClick: onClick });
                }
        }
    }, [mode, isMobile, enableLable, permissions, onClick]);
    return renderComponent;
}
export default PersonalSettingsButton;
