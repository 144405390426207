import { SET_AUDIO_AVAILABLE, SET_AUDIO_MUTED, SET_AUDIO_UNMUTE_PERMISSIONS, SET_SCREENSHARE_MUTED, SET_VIDEO_AVAILABLE, SET_VIDEO_MUTED, SET_VIDEO_UNMUTE_PERMISSIONS, STORE_VIDEO_TRANSFORM, TOGGLE_CAMERA_FACING_MODE } from './actionTypes';
import { MEDIA_TYPE, SCREENSHARE_MUTISM_AUTHORITY, VIDEO_MUTISM_AUTHORITY } from './constants';
/**
 * 로컬 오디오의 가용성을 조정하는 작업입니다.
 *
 * @param {boolean} available - 로컬 오디오가 사용 가능한 것으로 표시되는 경우 true 로컬 오디오를 사용할 수 없는 경우 false입니다.
 * @returns {{
 *     type: SET_AUDIO_AVAILABLE,
 *     available: boolean
 * }}
 */
export function setAudioAvailable(available) {
    return {
        type: SET_AUDIO_AVAILABLE,
        available
    };
}
/**
 * 로컬 오디오의 음소거 상태를 설정하는 작업입니다.
 *
 * @param {boolean} muted - 로컬 오디오가 음소거되면 True이고, 로컬 오디오가 음소거 해제되면 false입니다.
 * @param {boolean} ensureTrack - 누락된 경우 새 트랙이 생성되도록 하려면 True입니다.
 * @returns {{
 *     type: SET_AUDIO_MUTED,
 *     ensureTrack: boolean,
 *     muted: boolean,
 * }}
 */
export function setAudioMuted(muted, ensureTrack = false) {
    return {
        type: SET_AUDIO_MUTED,
        ensureTrack,
        muted
    };
}
/**
 * 오디오 음소거 아이콘을 비활성화/활성화하는 작업입니다.
 *
 * @param {boolean} blocked - 오디오 음소거 아이콘을 비활성화해야 하는 경우 True입니다.
 * @param {boolean|undefined} skipNotification - 알림 표시를 건너뛰려면 True입니다.
 * @returns {Function}
 */
export function setAudioUnmutePermissions(blocked, skipNotification = false) {
    return {
        type: SET_AUDIO_UNMUTE_PERMISSIONS,
        blocked,
        skipNotification
    };
}
/**
 * 로컬 화면 공유의 음소거 상태를 설정하는 작업입니다.
 *
 * @param {boolean} muted - 로컬 화면 공유가 활성화되면 True이고 그렇지 않으면 False입니다.
 * @param {MEDIA_TYPE} mediaType - 미디어 유형입니다.
 * @param {number} authority - 로컬 화면 공유를 음소거/음소거 해제하는 {@link SCREENSHARE_MUTISM_AUTHORITY}입니다.
 * @param {boolean} ensureTrack - 누락된 경우 새 트랙이 생성되도록 하려면 True입니다.
 * @returns {Function}
 */
export function setScreenshareMuted(muted, mediaType = MEDIA_TYPE.SCREENSHARE, authority = SCREENSHARE_MUTISM_AUTHORITY.USER, ensureTrack = false) {
    return async (dispatch, getState) => {
        const state = getState();
        const oldValue = state['features/base/media'].screenshare.muted;
        // eslint-disable-next-line no-bitwise
        const newValue = muted ? oldValue | authority : oldValue & ~authority;
        return dispatch({
            type: SET_SCREENSHARE_MUTED,
            authority,
            mediaType,
            ensureTrack,
            muted: newValue
        });
    };
}
/**
 * 로컬 비디오의 가용성을 조정하는 작업입니다.
 *
 * @param {boolean} available - 로컬 비디오가 사용 가능한 것으로 표시되는 경우 True이고 로컬 비디오를 사용할 수 없는 경우 False입니다.
 * @returns {{
 *     type: SET_VIDEO_AVAILABLE,
 *     available: boolean
 * }}
 */
export function setVideoAvailable(available) {
    return {
        type: SET_VIDEO_AVAILABLE,
        available
    };
}
/**
 * 로컬 비디오의 음소거 상태를 설정하는 작업입니다.
 *
 * @param {boolean} muted - 로컬 비디오가 음소거되면 True이고 로컬 비디오가 음소거 해제되면 false입니다.
 * @param {MEDIA_TYPE} mediaType - 미디어 유형입니다.
 * @param {number} authority - 로컬 동영상을 음소거/음소거 해제하는 {@link VIDEO_MUTISM_AUTHORITY}입니다.
 * @param {boolean} ensureTrack - 누락된 경우 새 트랙이 생성되도록 하려면 True입니다.
 * @returns {Function}
 */
export function setVideoMuted(muted, mediaType = MEDIA_TYPE.VIDEO, authority = VIDEO_MUTISM_AUTHORITY.USER, ensureTrack = false) {
    return {
        type: SET_VIDEO_MUTED,
        authority,
        mediaType,
        ensureTrack,
        muted
    };
    // return async (dispatch: IStore['dispatch'], getState: IStore['getState']) => {
    //     const state = getState();
    //     const oldValue = state['features/base/media'].video.muted;
    //     // eslint-disable-next-line no-bitwise
    //     const newValue = muted ? oldValue | authority : oldValue & ~authority;
    //     dispatch({
    //         type: SET_VIDEO_MUTED,
    //         authority,
    //         mediaType,
    //         ensureTrack,
    //         muted
    //     });
    // };
}
/**
 * 비디오 음소거 아이콘을 비활성화/활성화하는 작업입니다.
 *
 * @param {boolean} blocked - 비디오 음소거 아이콘을 비활성화해야 하는 경우 True입니다.
 * @param {boolean|undefined} skipNotification - 알림 표시를 건너뛰려면 True입니다.
 * @returns {Function}
 */
export function setVideoUnmutePermissions(blocked, skipNotification = false) {
    return {
        type: SET_VIDEO_UNMUTE_PERMISSIONS,
        blocked,
        skipNotification
    };
}
/**
 * 스트림에 적용된 마지막 동영상 {@link Transform}을 저장하는 작업을 만듭니다.
 *
 * @param {string} streamId - The ID of the stream.
 * @param {Object} transform - The {@code Transform} to store.
 * @returns {{
 *     type: STORE_VIDEO_TRANSFORM,
 *     streamId: string,
 *     transform: Object
 * }}
 */
export function storeVideoTransform(streamId, transform) {
    return {
        type: STORE_VIDEO_TRANSFORM,
        streamId,
        transform
    };
}
/**
 * 카메라 방향 모드를 토글합니다.
 * 예를 들어 가장 일반적으로 휴대전화와 같은 모바일 장치에는 전면/사용자를 향하는 카메라와 후면/환경을 향하는 카메라가 있습니다.
 * setCameraFacingMode와 달리 별도의 읽기 오버헤드 없이 토글을 최적으로 및/또는 기본적으로 구현할 수 있습니다.
 * 현재/유효 카메라 대면 모드를 기록합니다.
 *
 * @returns {{
 *     type: TOGGLE_CAMERA_FACING_MODE
 * }}
 */
export function toggleCameraFacingMode() {
    return {
        type: TOGGLE_CAMERA_FACING_MODE
    };
}
