import styled, { css } from "styled-components";
import { flexBox, layoutBase, paddingBox } from "../../../css/theme";
export const RoomStyled = styled.div `
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    
    .test {
        position: absolute;
    }

    .record_list {
        background: #D00000!important;
        font-weight: 600;
        font-size: 15px;
        border-radius: 20px!important;
        padding: 1px 20px!important;
    }

    .count {
        border-left: 1px solid;
        border-right: 1px solid;
        padding: 0 8px;
        box-sizing: border-box;
    }

    ${props => css `
        ${layoutBase('main')}

        .room_label {
            padding: 8px;
        }

        .room_item {

            > * {
                width: inherit;
                flex: 1;
            }

            .click_button {
                ${flexBox('column', 'center', 'center', '4px')};
                width: max-content;
            }

            &.row {
                .row_icon {
                    flex-direction: row;
                    gap: 12px;
                }
                .left {
                    ${flexBox('row', 'center', 'flex-start', '12px')}
                }
    
                .center {
                    ${flexBox('row', 'center', props.isMobile ? 'space-around' : 'center', '20px')}
                }
    
                .right {
                    ${flexBox('row', 'center', 'flex-end', '12px')}
                }
            }

            &.column {
                .left {
                    ${flexBox('column', 'center', 'flex-start', '12px')}
                }
    
                .center {
                    ${flexBox('column', 'center', 'space-around', '12px')}
                }
    
                .right {                    
                    ${flexBox('column', 'center', 'flex-end', '12px')}
                }
            }
            
        }

        .header {
            width: 100%;
            height: 50px;
            min-height: 50px;
            ${paddingBox('0 15px')}; 
            ${flexBox('row', 'center', 'flex-start', '12px')};   
        }

        .body { 
            margin: 0; 
            display: flex;            
            width: 100%;
            height: 100%;
            overflow: hidden;

            .layout {
                position: relative;
                display: flex;
                height: 100%;
                overflow: hidden;

                ${props => props.isMobile && css `
                    background: black;
                    .sidebar {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                `}
            }

            &.row {
                flex-direction: column;
                
                .layout {
                    flex: 1;
                    width: 100%;
                }

                .footer {
                    ${paddingBox('0 15px')};
                    ${flexBox('row', 'center', 'flex-start', '12px')};
                    width: 100%;
                    height: 50px;
                }
            }

            &.column {
                flex-direction: row-reverse;
                
                .layout {
                    flex: 1;
                    width: calc(100% - 60px);
                }
                .footer {
                    ${paddingBox('15px 0')};
                    ${flexBox('column', 'center', 'flex-start', '12px')};
                    width: 60px;
                    height: 100%;
                }
            }
        }

        .floating {
            background: transparent;
            position: absolute;
            width: 50px;
            top: 70px;
            right: 10px;

            .left {
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                gap: 12px;
            }
        }
    `}
`;
