import { isCheckPermission } from "../../room";
import { getDevicePermission } from "../devices";
import { MEDIA_TYPE } from "../media";
import { PERMISSION } from "../members";
import { showNotification } from "../notification/actions";
import { SET_RECORDING_ENABLED, TEMP_SERVER_RECORD_START, TEMP_SERVER_RECORD_STOP } from "./actionTypes";
import { RECORDING_TYPE } from "./constant";
/**
 * 서버 녹화 시작 알림. (임시)
 * @returns
 */
export function startServerRecord() {
    return (dispatch, getState) => {
        if (isCheckPermission(getState, PERMISSION.SERVER_RECORD)) {
            // 장치 권한이 존재하지 않는 경우
            const device_persmission = getDevicePermission(getState());
            if (!device_persmission[MEDIA_TYPE.AUDIO] || !device_persmission[MEDIA_TYPE.VIDEO]) {
                dispatch(showNotification({
                    appearance: "error",
                    titleKey: "notify.recordError",
                    description: "notify.havePermission"
                }));
            }
            else {
                dispatch(startTempServerRecord());
            }
        }
    };
}
/**
 * 임시 서버 녹화 시작
 */
function startTempServerRecord() {
    return {
        type: TEMP_SERVER_RECORD_START
    };
}
/**
 * 임시 서버 녹화 중지
 */
export function stopTempServerRecord() {
    return {
        type: TEMP_SERVER_RECORD_STOP
    };
}
/**
 * 서버 녹화 시작
 * @returns
 */
export function addRecordResource() {
    return async (dispatch) => {
        const { complete, data } = await APP.API.createRecordResoure();
        if (complete) {
            dispatch({
                type: SET_RECORDING_ENABLED,
                recordType: RECORDING_TYPE.SERVER,
                enabled: true,
                option: data
            });
        }
        else {
            dispatch(stopTempServerRecord());
        }
    };
    // return {
    //     type: SET_RECORDING_ENABLED,
    //     recordType: RECORDING_TYPE.SERVER,
    //     enabled: true,
    // }
}
/**
 * 서버 녹화 종료
 * @returns
 */
export function stopServerRecord() {
    return {
        type: SET_RECORDING_ENABLED,
        recordType: RECORDING_TYPE.SERVER,
        enabled: false,
        option: null
    };
}
