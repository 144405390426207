import React, { useEffect, useRef, useState } from "react";
import { IMAGES, VIRTUAL_BACKGROUND_TYPE } from "../constants";
import { Dialog, toggleDialog } from "../../base/dialog";
import { ClickButton } from "../../base/ui";
import { useDispatch } from "react-redux";
import { saveVirtualImage, toggleBackgroundEffect } from "../action";
import { useSelector } from "react-redux";
import { getLocalVideoTrack, getTrackState } from "../../base/tracks";
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
const VirtualStyled = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-direction: column;
    margin: 12px 0;

    .img_wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin: 12px 0;
        height: 220px;
        overflow: auto;
        padding: 4px;
        box-sizing: border-box;
        justify-content: space-between;
    }

    label { margin: 0; }

    .img_item {
        margin: 0;
        flex-direction: column;
        gap: 12px;
        width: 100px;
        height: 100px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #f2f2f2;

        &:hover{
            border: ${props => `1px solid ${APP.config.style.focusColor}`};
        }
        
        &.${props => props.selectId} {
            border: ${props => `1px solid ${APP.config.style.focusColor}`};
        }
    }
`;
const VirtaulBackGround = React.memo(() => {
    const dispatch = useDispatch();
    const virtualRef = useRef();
    const { t } = useTranslation();
    const [selectId, setSelectId] = useState('noBlur');
    const [selectOption, setSelectOption] = useState();
    const localVideoTrack = useSelector((state) => getLocalVideoTrack(getTrackState(state)));
    const { imageList: virtual_Images, selectImage } = useSelector((state) => state['features/virtual-background']);
    useEffect(() => {
        setSelectId(selectImage);
    }, [selectImage]);
    const onClick = (item) => {
        setSelectId(item.tooltip);
        const img = document.createElement('img');
        img.onload = function () {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            if (!context)
                return;
            canvas.width = img.width;
            canvas.height = img.height;
            context.drawImage(img, 0, 0);
            const base64 = canvas.toDataURL('image/jpeg');
            setSelectOption({
                enabled: true,
                url: base64,
                backgroundType: VIRTUAL_BACKGROUND_TYPE.IMAGE,
                selectedThumbnail: item.tooltip
            });
        };
        img.src = item.src;
    };
    const onImgClick = (item, id) => {
        setSelectId(id);
        setSelectOption({
            enabled: true,
            url: item,
            backgroundType: VIRTUAL_BACKGROUND_TYPE.IMAGE,
            selectedThumbnail: id
        });
    };
    const onNoImgSave = () => {
        setSelectId("noBlur");
        setSelectOption({
            enabled: false,
            url: undefined,
            backgroundType: VIRTUAL_BACKGROUND_TYPE.NONE,
            selectedThumbnail: "noBlur"
        });
    };
    const onBlurSave = (isHalf) => {
        const selectedThumbnail = isHalf ? "halfBlur" : "blur";
        setSelectId(selectedThumbnail);
        setSelectOption({
            enabled: true,
            url: undefined,
            blurValue: isHalf ? 8 : 25,
            backgroundType: VIRTUAL_BACKGROUND_TYPE.BLUR,
            selectedThumbnail
        });
    };
    const onSave = () => {
        if (!selectOption) {
            dispatch(toggleDialog());
            return;
        }
        // @ts-ignore
        dispatch(toggleBackgroundEffect(selectOption, localVideoTrack, selectId));
        dispatch(toggleDialog());
    };
    const onChange = e => {
        const files = e.target.files;
        if (!files)
            return;
        if (files.length >= 1) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = function (e) {
                if (!e.target)
                    return;
                const base64 = e.target.result;
                dispatch(saveVirtualImage(base64));
            };
            reader.readAsDataURL(file);
            if (virtualRef.current)
                virtualRef.current.value = '';
        }
    };
    return (React.createElement(Dialog, { width: 500, titleKey: t("virtaul.virtaulBackGround") },
        React.createElement(VirtualStyled, { selectId: selectId },
            React.createElement("div", null, t("virtaul.virtualGuid")),
            React.createElement("div", { className: "img_wrapper" },
                React.createElement("input", { ref: virtualRef, id: "virtual_file", type: "file", style: { display: "none" }, onChange: onChange }),
                React.createElement("label", { htmlFor: "virtual_file" },
                    React.createElement(ClickButton, { className: "img_item", icon: "plus", size: 25, color: "#545454", label: t("virtaul.saveFile") })),
                React.createElement("div", { className: `img_item noBlur`, onClick: () => onNoImgSave() },
                    " ",
                    t("virtaul.noBlur")),
                React.createElement("div", { className: `img_item halfBlur`, onClick: () => onBlurSave(true) },
                    " ",
                    t("virtaul.halfBlur")),
                React.createElement("div", { className: `img_item blur`, onClick: () => onBlurSave(false) },
                    " ",
                    t("virtaul.blur")),
                IMAGES.map(item => React.createElement("img", { key: item.id, className: `img_item ${item.tooltip}`, src: item.src, width: 100, height: 100, onClick: () => onClick(item) })),
                virtual_Images.map((item, index) => React.createElement("img", { key: index, className: `img_item image_${index}`, src: item, width: 100, height: 100, onClick: () => onImgClick(item, `image_${index}`) }))),
            React.createElement(ClickButton, { isText: true, label: t("dialog.add"), onClick: onSave }))));
});
export default VirtaulBackGround;
