import React from "react";
import { ClickButton } from "../../ui";
import { useTranslation } from "react-i18next";
import { toggleDialog } from "../../dialog";
import AddUrlPopup from "../AddUrlPopup";
import { useDispatch } from "react-redux";
export const AddUrl = React.memo(({ isRoom, isIcon = false, isDialog = false, handlerAddVod }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const onClick = () => {
        if (isRoom) {
            APP.eventManager.publish('toggleRConfigurationDialog', { type: 'openUrlPopup', props: { handlerAddVod } });
        }
        else {
            dispatch(toggleDialog(AddUrlPopup, { isDialog: true, handlerAddVod }));
        }
    };
    return (React.createElement("label", null,
        React.createElement(ClickButton, { size: 22, className: "add_btn", color: APP.config.style.playerColor, isText: !isIcon || isDialog, icon: (isIcon || isDialog) ? 'youtubeAdd' : null, label: (isIcon && !isDialog) ? '' : t(`vod.urlAdd`), onClick: onClick })));
});
