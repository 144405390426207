import { ReducerRegistry, StateListenerRegistry } from "../base/redux";
import { LEAVE_ROOM } from "../room";
import { setLayoutMembers, setMaxPage, setPage } from "./actions";
import { SET_DUAL_MONITOR, SET_EXPAND, SET_LAYOUT_MEMBERS, SET_LAYOUT_MODE, SET_LAYOUT_STYLE, SET_LAYOUT_TYPE, SET_MAX_PAGE, SET_PAGE } from "./actionTypes";
import { LAYOUT_TYPE, videoLayoutMode } from "./constants";
import { getLayoutWidthAndHeight, getMembersByMode } from "./functions";
const DEFAULT_STATE = {
    mode: videoLayoutMode.grid,
    viewVisible: {
        [videoLayoutMode.screen]: false,
        [videoLayoutMode.vod]: false,
        [videoLayoutMode.white]: false,
        [videoLayoutMode.document]: false,
        [videoLayoutMode.note]: false
    },
    isDualMonitor: false,
    layoutWidth: 0,
    layoutHeight: 0,
    layoutType: LAYOUT_TYPE.desktop,
    page: 1,
    maxPage: 1,
    expand: false,
    remote: new Set(),
    pin: new Set(),
    voice: new Set(),
    seminar: new Set()
};
ReducerRegistry.register('features/video-layout', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SET_LAYOUT_MODE:
            return {
                ...state,
                mode: action.mode,
                viewVisible: action.visibleList,
            };
        case SET_LAYOUT_STYLE:
            return {
                ...state,
                layoutWidth: action.width,
                layoutHeight: action.height
            };
        case SET_LAYOUT_TYPE:
            return {
                ...state,
                layoutType: action.layoutType
            };
        case SET_MAX_PAGE:
            return {
                ...state,
                maxPage: action.maxPage
            };
        case SET_PAGE:
            return {
                ...state,
                page: action.page
            };
        case SET_LAYOUT_MEMBERS:
            return {
                ...state,
                remote: action.remote,
                pin: action.pin,
                voice: action.voice,
                seminar: action.seminar,
                screen: action.screen
            };
        case SET_EXPAND:
            return {
                ...state,
                expand: action.expand
            };
        case SET_DUAL_MONITOR:
            return {
                ...state,
                isDualMonitor: action.isDualMonitor
            };
        case LEAVE_ROOM:
            return {
                ...state,
                isDualMonitor: false,
                layoutWidth: 0,
                layoutHeight: 0,
                layoutType: LAYOUT_TYPE.desktop,
                page: 1,
                maxPage: 1,
                expand: false,
                mode: videoLayoutMode.grid,
                viewVisible: {
                    [videoLayoutMode.screen]: false,
                    [videoLayoutMode.vod]: false,
                    [videoLayoutMode.white]: false,
                    [videoLayoutMode.document]: false,
                    [videoLayoutMode.note]: false
                },
            };
    }
    return state;
});
StateListenerRegistry.register((state) => {
    const layoutStyle = getLayoutWidthAndHeight(state);
    return getMembersByMode(state, layoutStyle);
}, (result, store) => {
    if (!result)
        return;
    const { page, maxPage, remote, pin, voice, seminar, screen } = result;
    if (maxPage < page)
        store.dispatch(setPage(maxPage));
    else {
        store.dispatch(setMaxPage(maxPage));
        store.dispatch(setLayoutMembers({ remote, pin, voice, seminar, screen }));
    }
}, {
    deepEquals: true
});
