import moment from "moment";
export function getFormatterDate(time) {
    return moment(time).format('YYYY-MM-DD HH:mm');
}
export function getFormatterNowTime(format) {
    let time = new Date();
    time = moment(time).add(1, 'minutes');
    return time.format(format);
}
export function validateEmail(email) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
        return (true);
    }
    return (false);
}
