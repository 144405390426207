import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IStore } from "../../app/types";
import { ROLE, checkRoleLevel, getLocalMemberRole } from "../../base/members";
import { connect } from "../../base/redux";
import { ClickButton, OnOffSwitch, RadioButton } from "../../base/ui";
import { setShareScreenOption } from "../action";
import { SHARE_TYPE, USER_TYPE } from "../constants";
import { getScreenLayoutType } from "../functions";
import ScreenOption from "./ScreenOption";

function ScreenSetting({
    dispatch, local_role,
    screen_type, 
    frame, permission,
    renderSubmitBtn, handlerUpdatePolicy
}) {
    const { t } = useTranslation();
    
    const [ isRole, setIsRole ] = useState();
    const [ isShow, setIsShow ] = useState(permission);
    const [ layoutType, setLayoutType ] = useState(USER_TYPE);
    const [ screenFrame, setScreenFrame ] = useState(15);

    useEffect(() => {
        setIsShow(permission);
    }, [permission]);

    useEffect(() => {
        setLayoutType(screen_type);
    }, [screen_type]);

    useEffect(() => {
        setScreenFrame(frame);
    }, [frame]);

    useEffect(() => {
        const isRole = checkRoleLevel(local_role, ROLE.MANAGER);
        setIsRole(isRole);
    }, [local_role]);

    /**
     * 전체 레이아웃 동기화 설정
     * @returns 
     */
    const renderLayoutType = () => {
        const label = isShow ? 'ON' : 'OFF';
        if (!isRole) {
            return (
                <ClickButton
                    className="isFocus disable"
                    isText={true}
                    label={label}
                />
            )
        }
        return (
            <OnOffSwitch
                label={label}
                isShow={isShow}
                handlerClick={setIsShow}
            />
        );
    };
   
    
    /**
     * 영상 레이아웃 표시
     */
    const renderLayout = () => {
        return (
            <RadioButton
                type={layoutType}
                selectedOption={layoutType}
                setSelectedOption={setLayoutType}
                list={[
                    { id: USER_TYPE, label: t(`dialog.${USER_TYPE}`) },
                    { id: SHARE_TYPE, label: t(`dialog.${SHARE_TYPE}`) },
                ]}
            />
        );
    };

    /**
     * 화면 공유 프레임 설정
     */
    const renderFrameBtn = () => {
        if (!isRole) {
          return (
                <ClickButton
                    className="isFocus disable"
                    isText={true}
                    label={screenFrame}
                />
          );
        }
      
        return [5, 15, 30].map((item) => 
            <ClickButton
                key={item}
                className={item === screenFrame ? 'isFocus' : ''}
                isText={true}
                label={t(`dialog.${item}screen`)}
                onClick={() => setScreenFrame(item)}
            />
        );

    };
    
    const handlerClick = () => {
        const screenOption = {
            permission: isShow,
            frame: screenFrame,
            type: layoutType,
        };
    
        if (!isShow) {
            dispatch(setShareScreenOption(layoutType));
        }
    
        handlerUpdatePolicy({
            screen_option: screenOption,
        });
    };

    return (
        <>
            <ScreenOption
                renderLayoutType={renderLayoutType}
                renderLayout={renderLayout}
                renderFrameBtn={renderFrameBtn}
            />
            { ((!isShow && !isRole) || isRole) && renderSubmitBtn(handlerClick) }
        </>
    )
}

function _mapStateToProps(state, ownProps) {    
    const local_role = getLocalMemberRole(state);
    const screen_type = getScreenLayoutType(state);
    
    return {
        local_role,
        screen_type, 
        frame: ownProps?.screen_option?.frame, permission: ownProps?.screen_option?.permission
    }
}

export default connect(_mapStateToProps)(ScreenSetting);