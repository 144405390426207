import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ClickButton } from "../../../ui";
import { hideDialog } from "../../actions";
import StatelessDialog from "./StatelessDialog";

// type Props = {
//     className: String,
//     width: Number,
//     style: Object,
//     hideCancelButton: Boolean,
//     disableHeader: Boolean,
//     onCancel: () => void
// }

export default function Dialog(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const _hideDialog = () => {
        if (props.onCancel) props.onCancel();
        else dispatch(hideDialog());
    }

    const renderCancelButton = () => {
        if (props.hideCancelButton) {
            return null;
        }

        return (
            <ClickButton key="close" className="close" 
                label={t("dialog.close")} 
                onClick={ _hideDialog } />
        );
    }
    const data =  { ...props, renderCancelButton, _hideDialog };

    return (
        <StatelessDialog { ...data } />
    )
}