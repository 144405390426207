import { setAudioInputDevice, setVideoInputDevice } from "../../../features/base/devices";
import { setFailedJitsiConnection, setSuccessJitsiConnection, setWillJitsiConnection } from "../../../features/base/jisti";
import JitsiMeetJS from "../../../features/base/lib-jitsi-meet";
import { MEDIA_TYPE } from "../../../features/base/media";
import { setMemberVoice } from "../../../features/base/members";
import { addLocalTrack, getTracksByTrackIdAndMediaType, replaceLocalTrack, trackAdded, trackRemoved } from "../../../features/base/tracks";
import { getRoomOption } from "../../../features/room";
import { getLocalSharingStatus, getScreenShareTrackId } from "../../../features/screen-share";
import { createTaskQueue } from "../../util/helpers";

const _replaceLocalVideoTrackQueue = createTaskQueue();

class JitsiConference {
    constructor() {
        this.conference = null;
    }
    

    join(connection, config, handler, isTemp) {
        // 회의실 uuid 
        const meeting_uuid = getRoomOption(APP.store.getState).code;
        if (!meeting_uuid) return Promise.reject("no meeting");
        // 회의실 세팅 
        const conference = connection.initJitsiConference(meeting_uuid, config);
        
        this.conference = conference;
        return new Promise(async (resolve, reject) => {
            // connection 실패가 발생하는 경우 
            connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_FAILED, _connectionFailedHandler);

            // 회의실 입장 성공
            function _handleConferenceJoined() {
                _unsubscribe();
                // 성공 
                APP.store.dispatch(setSuccessJitsiConnection(conference));

                // 회의실 이벤트 등록 
                function _conferenceEventListener() {
                    // track 추가 
                    conference.on(JitsiMeetJS.events.conference.TRACK_ADDED, track => {
                        if (!track || track.isLocal()) {
                            return;
                        }
                        
                        const screenTrackId = getScreenShareTrackId(APP.store.getState);
                        if (getLocalSharingStatus(APP.store.getState)
                            && track.ownerEndpointId === screenTrackId) return;
                            
                        APP.store.dispatch(trackAdded(track));
                    }); 
                    
                    // track 삭제 
                    conference.on(JitsiMeetJS.events.conference.TRACK_REMOVED, track => {
                        if (!track || track.isLocal()) {
                            return;
                        }
        
                        APP.store.dispatch(trackRemoved(track));
                    });

                    // set domi
                    conference.on(JitsiMeetJS.events.conference.DOMINANT_SPEAKER_CHANGED, id => {
                        APP.store.dispatch(setMemberVoice(id));
                    });
                }

                !isTemp && _conferenceEventListener();    
            }

            // 회의실 입장 실패
            function _handleConferenceFailed(err) {
                _unsubscribe();
                !isTemp && APP.store.dispatch(setFailedJitsiConnection(conference));
                this.conference = null;

                reject(err);
            } 

            function _unsubscribe() {
                conference.off(JitsiMeetJS.events.conference.CONFERENCE_JOINED, _handleConferenceJoined);
                conference.off(JitsiMeetJS.events.conference.CONFERENCE_FAILED, _handleConferenceFailed);
            } 

            // 이벤트 등록
            conference.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, _handleConferenceJoined);
            conference.on(JitsiMeetJS.events.conference.CONFERENCE_FAILED, _handleConferenceFailed);  
            
            this._conferenceWillJoin(handler, isTemp)
                .then(() => {
                    conference.join();

                    resolve(conference);
                });
        });
    }   
    
    leave() {
        this.conference && this.conference.leave();

        this.conference = null;
    }

    _conferenceWillJoin(handler, isTemp) {
        const conference = this.conference;
        
        return new Promise(async (resolve, reject) => {
            const promises = [];  

            // 임시 회의실 입장 ( 회의실 입장 전 초기 세팅 )
            !isTemp && APP.store.dispatch(setWillJitsiConnection(conference));

            const { userSelectedMicDeviceId, userSelectedCameraDeviceId } = APP.store.getState()['features/base/settings'];

            Promise.all([
                handler.handlerCreateTracks({ devices: ['video'], cameraDeviceId: userSelectedCameraDeviceId }),
                handler.handlerCreateTracks({ devices: ['audio'], micDeviceId: userSelectedMicDeviceId }),            
            ]).then(async localTracks => {
                localTracks && localTracks.map(t => {
                    if (!t) return;
                    if (t.type === "video") this.setVideoMuteStatus(t);
                    else if (t.type === "audio") this.setAudioMuteStatus(t);

                    promises.push(
                        conference.addTrack(t).then(() => {
                            // store 저장 
                            !isTemp && APP.store.dispatch(addLocalTrack(t));

                            // APP.store.dispatch(setVideoInputDevice(userSelectedCameraDeviceId, null, true));
                            // APP.store.dispatch(setAudioInputDevice(userSelectedMicDeviceId, null, true));
                        }).catch(err => {
                            // 트랙 생성 오류
                            console.log("err", err);
                        })
                    );                
                });     
                
                resolve(conference);

            }).catch(err => {
                console.log(err);
                reject();
            });           
        });        
    }
      
    replaceTrack(oldTrack, newTrack) {
        return this.conference.replaceTrack(oldTrack, newTrack);  
    }

    useVideoStream(newTrack) {
        const state = APP.store.getState();

        return new Promise((resolve, reject) => {
            _replaceLocalVideoTrackQueue.enqueue(onFinish => {
                const oldTrack = getTracksByTrackIdAndMediaType(state, "local", MEDIA_TYPE.VIDEO)?.track;

                console.log(`useVideoStream: Replacing ${oldTrack} with ${newTrack}`);


                if (oldTrack === newTrack) {
                    resolve();
                    onFinish();

                    return;
                }

                // Add the track to the conference if there is no existing track, replace it otherwise.
                const trackAction = oldTrack 
                    ? replaceLocalTrack(oldTrack, newTrack)
                    : addLocalTrack(newTrack);

                APP.store.dispatch(trackAction)
                    .then(() => {
                        this.setVideoMuteStatus();
                    })
                    .then(resolve)
                    .catch(error => {
                        console.error(`useVideoStream failed: ${error}`);
                        onFinish();
                    })
                    .then(() => {
                        onFinish();
                    });
            });
        });
    }

    setVideoMuteStatus(track) {
        if (track) {
            const { userSelectedCameraMuted, request_camera } = APP.store.getState()['features/base/settings'];
            console.log(userSelectedCameraMuted)
            if (userSelectedCameraMuted || !request_camera) track.mute();
        }
    }

    setAudioMuteStatus(track) {
        if (track) {
            const { userSelectedAudioMuted, request_mic } = APP.store.getState()['features/base/settings'];
            if (userSelectedAudioMuted || !request_mic) track.mute();
        }
    }
}

function _connectionFailedHandler() {
    console.log(" 회의실 연결 실패 ");
}

export default JitsiConference;