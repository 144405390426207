import React from 'react';
import { connect } from '../../../redux';
import AbstractVideoTrack from '../AbstractVideoTrack';
import Video from './Video';
/**
 * Component that renders a video element for a passed in video track and
 * notifies the store when the video has started playing.
 *
 * @augments AbstractVideoTrack
 */
//@ts-ignore
class VideoTrack extends AbstractVideoTrack {
    /**
     * Renders the video element.
     *
     * @override
     * @returns {ReactElement}
     */
    render() {
        const { className, id, muted, videoTrack, style, eventHandlers
        //@ts-ignore
         } = this.props;
        return (React.createElement(Video, { autoPlay: true, className: className, eventHandlers: eventHandlers, id: id, muted: muted, 
            //@ts-ignore
            onVideoPlaying: this._onVideoPlaying, style: style, videoTrack: videoTrack }));
    }
}
/**
 * Default values for {@code VideoTrack} component's properties.
 *
 * @static
 */
VideoTrack.defaultProps = {
    className: '',
    id: ''
};
//@ts-ignore
export default connect()(VideoTrack);
