import styled from "styled-components";

export const LobbyStyled = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .sub {
        margin: 0;
        display: flex;
        gap: 12px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;