import React from "react";
import { Nickname } from "./components";
import { PreviewInputBox, PreviewTab } from "../base/ui";
import { useDispatch } from "react-redux";
import { setDisplayName } from "../base/settings";
import { enterRoom } from "../room";

const page_route = 'nickname';
function NicknameContainer() {
    const dispatch = useDispatch();
    const isAnotherStyle = APP.config.name === 'innovation' ? true : false;

    const changeNickname = (nickname) => {
        dispatch(setDisplayName(nickname));

        if (navigator.product === 'ReactNative') dispatch(enterRoom());
    }

    const renderPreviewTab = () => {
        return (
            <PreviewTab text={ APP.config[page_route].subText } 
                boxWidth={APP.config[page_route].boxWidth} 
                isAnotherStyle={isAnotherStyle}>
                <PreviewInputBox name="nickname" handler={ changeNickname } /> 
            </PreviewTab>
        )
    }

    return (
        <Nickname>
            { renderPreviewTab() }
        </Nickname>
    )
}
export default React.memo(NicknameContainer);