import { throttle } from "lodash";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { isMobileBrowser } from "../../base/environment/utils";
import { getPropertyValue } from "../../base/settings";
import { SideBarContainer } from "../../base/sidebar";
import { ClickButton } from "../../base/ui";
import { default as LayoutContainer } from "../../video-layout/LayoutContainer";
import { RoomStyled } from "./styles";
import Management from "../../../modules/server";
let list = new Set();
const Room = React.memo(({ uiOption, isMobile, renderRoomItem }) => {
    const openSide = useSelector((state) => getPropertyValue(state, 'dualSideOpen'));
    const roomRef = useRef();
    /**
     * 레이아웃 사이즈 세팅
     */
    const setLayout = throttle(() => {
        const element = roomRef?.current;
        if (element) {
            const { width, height } = element.getBoundingClientRect();
            APP.eventManager.publish('UPDATE_ROOM_SIZE', { width, height });
        }
    }, 500);
    /**
     * 초기 설정
     */
    useEffect(() => {
        setLayout();
        window.addEventListener("resize", setLayout);
        return () => {
            window.removeEventListener("resize", setLayout);
        };
    }, []);
    useEffect(() => {
        setLayout();
    }, [isMobileBrowser()]);
    const test = async () => {
        const management = new Management();
        const response = await APP.API.createTempUser();
        if (response.complete) {
            const { auth_name, auth_password } = response.data;
            const result = await APP.API.login(auth_name, auth_password);
            if (result.complete) {
                const { access_token } = result.data;
                management.join(null, null, access_token, true)
                    .then(() => {
                    list.add(management);
                });
            }
        }
    };
    const renderItem = (type) => {
        const { show, direction, left, middle, right } = uiOption[type];
        const renderHeadItem = (item, components) => {
            return (React.createElement("div", { className: "head_item overText", style: { maxWidth: "90px" }, key: item },
                " ",
                components,
                " "));
        };
        const renderHeadOption = () => {
            if (type === 'header' && location.search.includes("test")) {
                return (React.createElement(ClickButton, { className: "test", isText: true, label: "test", color: "#000", size: 20, onClick: test }));
            }
        };
        if (!show)
            return null;
        return (React.createElement("div", { className: `room_item ${type} ${direction}` },
            left && React.createElement("div", { className: "left" }, renderRoomItem(left, null, renderHeadItem)),
            middle && React.createElement("div", { className: "center" }, renderRoomItem(middle, null, renderHeadItem)),
            right && React.createElement("div", { className: "right" }, renderRoomItem(right, null, renderHeadItem)),
            renderHeadOption()));
    };
    return (React.createElement(RoomStyled, { ref: roomRef, isMobile: isMobile, name: APP.config.name },
        renderItem('header'),
        React.createElement("div", { className: `body ${uiOption.footer.direction}` },
            React.createElement("div", { className: "layout" },
                React.createElement(LayoutContainer, null),
                !openSide && React.createElement(SideBarContainer, null)),
            renderItem('footer')),
        renderItem('floating')));
});
export default Room;
