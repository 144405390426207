import { JitsiTrackErrors } from "../lib-jitsi-meet";
import { MEDIA_TYPE, VIDEO_TYPE } from "../media";
import { toState } from "../redux";
export const getTrackState = (stateful) => {
    const state = toState(stateful);
    return state['features/base/tracks'];
};
/**
 * Returns tracks of specified media type.
 *
 * @param {Track[]} tracks - List of all tracks.
 * @param {MEDIA_TYPE} mediaType - Media type.
 * @returns {Track[]}
 */
export function getTracksByMediaType(getState, mediaType) {
    const tracks = getTrackState(getState);
    return tracks.filter(t => t.mediaType === mediaType);
}
export function getTracksByTrackId(stateful, trackId) {
    const tracks = getTrackState(stateful);
    return tracks.filter(t => t.participantId === trackId);
}
/**
 *  장치 아이디로 장치 가져오기
 * @param {Function} stateful
 * @param {String} deviceId
 * @returns
 */
export function getTracksByTrackIdAndMediaType(stateful, trackId, mediaType) {
    const tracks = getTrackState(stateful);
    return tracks.find(t => t.participantId === trackId && t.mediaType === mediaType);
}
/**
 *  상대방 로컬 트랙이 음소거인지 아닌지 여부
 *
 * @param {Track[]} tracks - List of all tracks.
 * @returns {Track[]}
 */
export function isTrackMutedByMediaType(stateful, trackId, mediaType) {
    const tracks = getTrackState(stateful);
    const track = getTracksByTrackIdAndMediaType(stateful, trackId, mediaType);
    return !track || track.muted;
}
/**
 * (유효)가 있거나 없는 로컬 트랙을 포함하는 배열을 반환합니다.
 *
 * @param {ITrack[]} tracks - 로컬 트랙을 포함한 모든 트랙
 * @param {boolean} [includePending] - 아직 보류 중인 경우 로컬 트랙을 반환할지 여부를 나타냅니다. 다음과 같은 경우 로컬 트랙이 보류 중입니다.
 * {@code getUserMedia} is still executing to create it and, consequently, its
 * {@code track} property is {@code undefined}. By default a pending local
 * track is not returned.
 * @returns {Track[]}
 */
export function getLocalTracks(tracks, includePending = false) {
    return tracks.filter(t => t.local && (t.track || includePending));
}
/**
 * Returns local track by media type.
 *
 * @param {ITrack[]} tracks - List of all tracks.
 * @param {MediaType} mediaType - Media type.
 * @param {boolean} [includePending] - Indicates whether a local track is to be
 * returned if it is still pending. A local track is pending if
 * {@code getUserMedia} is still executing to create it and, consequently, its
 * {@code jitsiTrack} property is {@code undefined}. By default a pending local
 * track is not returned.
 * @returns {(Track|undefined)}
 */
export function getLocalTrack(tracks, mediaType, includePending = false) {
    return (getLocalTracks(tracks, includePending)
        .find(t => t.mediaType === mediaType));
}
/**
 * Checks if the first local track in the given tracks set is muted.
 *
 * @param {ITrack[]} tracks - List of all tracks.
 * @param {MediaType} mediaType - The media type of tracks to be checked.
 * @returns {boolean} True if local track is muted or false if the track is
 * unmuted or if there are no local tracks of the given media type in the given
 * set of tracks.
 */
export function isLocalTrackMuted(tracks, mediaType) {
    const track = getLocalTrack(tracks, mediaType);
    return !track || track.muted;
}
/**
 * 현재 비디오 로컬 트랙이 음소거인지 아닌지 여부
 *
 * @param {Track[]} tracks - List of all tracks.
 * @returns {Track[]}
 */
export function isLocalTrackMutedByMediaType(stateful, mediaType) {
    const tracks = getTrackState(stateful);
    const track = getTracksByTrackIdAndMediaType(stateful, "local", mediaType);
    return !track || track.muted;
}
export function getScreenTracksByParticipantId(stateful, trackId) {
    const tracks = getTrackState(stateful);
    return tracks.filter(t => t.participantId === trackId).find(t => t.videoType === VIDEO_TYPE.DESKTOP);
}
/**
 * 특정 {@code JitsiLocalTrack}을 음소거 또는 음소거 해제합니다. 지정된 {@code track}의 음소거 상태가 이미 지정된 {@code 음소거} 값과 일치하는 경우 은(는) 아무 작업도 수행하지 않습니다.
 *
 * @param {track} track - The {@code track} to mute or unmute.
 * @param {boolean} muted - If the specified {@code track} is to be muted, then {@code true}; otherwise, {@code false}.
 * @param {Object} state - The redux state.
 * @returns {Promise}
 */
export function setTrackMuted(track, muted) {
    muted = Boolean(muted); // eslint-disable-line no-param-reassign
    // We still need to proceed here and remove the track from the peerconnection.
    if (track.isMuted() === muted) {
        return Promise.resolve();
    }
    const f = muted ? 'mute' : 'unmute';
    return track[f]().catch(error => {
        if (error.name !== JitsiTrackErrors.TRACK_IS_DISPOSED) {
            console.error(`set track ${f} failed`, error);
            return Promise.reject(error);
        }
    });
}
/**
 * Returns local video track.
 *
 * @param {ITrack[]} tracks - List of all tracks.
 * @returns {(Track|undefined)}
 */
export function getLocalVideoTrack(tracks) {
    return getLocalTrack(tracks, MEDIA_TYPE.VIDEO);
}
