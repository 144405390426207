// @ts-ignore
import { storage } from '../util';
import { CLASS_MATE_LOGIN, LOGOUT, SET_USER_INFO } from './actionTypes';
/**
 * (Class Mate 사용됨. 이외에 사용 X)
 */
export function classMateLogin(data) {
    return {
        type: CLASS_MATE_LOGIN,
        data
    };
}
/**
 * ID, PWD로 로그인
 * @param {String} id
 * @param {String} password
 * @param {String} enc_type
 */
export function loginById(id, password, enc_type = "base64") {
    return async (dispatch, getState) => {
        const response = await APP.API.login(id, password, enc_type);
        // 로그인에 성공한 경우 
        if (response.complete) {
            return await dispatch(loginByToken(response.data));
        }
        // 로그인에 실패한 경우
        return response;
    };
}
/**
 * ACCESS_TOKEN으로 사용자 로그인
 * @param {String} access_token
 * @param {String} refresh_token
 * @returns
 */
export function loginByToken({ access_token, refresh_token }) {
    return async (dispatch, getState) => {
        const response = await APP.API.getUserInformation(access_token, refresh_token);
        // 사용자 정보 가져오기에 성공한 경우 
        if (response.complete) {
            dispatch(setUserInformation({ access_token, refresh_token }, response.data));
            return response;
        }
        // 사용자 정보 가져오기에 실패한 경우
        return response;
    };
}
export function partnerLoginByToken(props) {
    const { token, user_id } = props;
    return async (dispatch) => {
        const response = await APP.API.partnerLogin({ token, user_id });
        if (response.complete) {
            await dispatch(loginByToken(response.data));
        }
        else {
            // APP.UI.alertMessage(response.data);
        }
    };
}
//  clas mate 
/**
 * 로그 아웃
 */
export function logout() {
    storage.remove('token');
    APP.API.setAccessToken(null);
    return {
        type: LOGOUT
    };
}
/**
 * 사용자 정보 저장
 * @param {ILocalState['token']} token
 * @param {ILocalState['user']} user
 * @returns
 */
function setUserInformation(token, user) {
    storage.save('token', token);
    APP.API.setAccessToken(token?.access_token);
    return {
        type: SET_USER_INFO,
        token,
        user
    };
}
