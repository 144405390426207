import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AccountContainer } from "../account";
import { getLocalPermission, isLogin } from "../base/local";
import { ClickButton, PreviewInputBox, PreviewTab } from "../base/ui";
import { ROOM_JOIN_TYPE, createRoom, setRoomOption, tempEnterRoom } from "../room";
import RoomConfigurationContainer from "../room-config/RoomConfigurationContainer";
import { Lobby } from "./components";
import { throttle } from "lodash";
const LobbyContainer = React.memo(({ isErrorPage = false }) => {
    const dispatch = useDispatch();
    const [load, setLoad] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const { isAccountLogin, permissions } = useSelector((state) => {
        const isAccountLogin = isLogin(state);
        const permissions = getLocalPermission(state) || [ROOM_JOIN_TYPE.ENTER_ROOM];
        return { isAccountLogin, permissions };
    }, shallowEqual);
    const deleteRoom = throttle(() => {
        dispatch(setRoomOption({
            option: {
                uuid: undefined,
                code: undefined,
                name: undefined
            },
            password_checking: false,
            media_server_info: undefined,
            start_time: undefined
        }));
    }, 1000, { leading: false, trailing: true });
    useEffect(() => {
        setLoad(true);
        deleteRoom();
        return () => {
            setLoad(false);
            deleteRoom.cancel();
        };
    }, []);
    const onEnterRoom = throttle((code) => {
        dispatch(tempEnterRoom({ code }));
    }, 1000, { leading: true, trailing: false });
    const onCreateRoom = throttle((name) => {
        dispatch(createRoom(name));
    }, 1000, { leading: true, trailing: false });
    const renderPreviewTab = () => {
        return (React.createElement(PreviewTab, { route: "lobby" },
            permissions && permissions.map(p => {
                const component = p === ROOM_JOIN_TYPE.ENTER_ROOM ?
                    React.createElement(PreviewInputBox, { key: p, name: "enter", handler: onEnterRoom }) :
                    React.createElement(PreviewInputBox, { key: p, name: "create", handler: onCreateRoom });
                return component;
            }),
            React.createElement(AccountContainer, { isMobile: isMobile })));
    };
    const content = useMemo(() => {
        return (React.createElement(React.Fragment, null,
            renderPreviewTab(),
            !isMobile && isAccountLogin && React.createElement(RoomConfigurationContainer, { isDialog: false })));
    }, [isMobile, isAccountLogin]);
    return (React.createElement(Lobby, { setIsMobile: setIsMobile }, isErrorPage ? React.createElement(ClickButton, { label: '잘못된 접근입니다. \n실행 중인 브라우저를 닫고 다시 접속을 부탁드리겠습니다.' }) : content));
});
export default LobbyContainer;
