import { css } from "styled-components";

export const flexBox = (direction = 'row', align = 'center', justify = 'center', gap = '0') => `
    display: flex;
    flex-direction: ${direction};
    align-items: ${align};
    justify-content: ${justify};
    gap: ${gap};

    > * {
        margin: 0;
    }
`;

export const layoutBase = () => css`
    color: #fff;
    background-color: ${APP.config.style.label};
`;

export const paddingBox = (padding = 'auto', boxSizing = 'border-box') => `
    padding: ${padding};
    box-sizing: ${boxSizing};
`;

export const focusBox = () => css`
    color: ${APP.config.style.hoverColor};

    svg {
        fill: ${APP.config.style.hoverColor};
        stroke: ${APP.config.style.hoverColor};
    }
`;

export const colorBox = (styles) => css`
    background: ${styles?.background};
    color: ${styles?.color};
    border: ${styles?.border && `1px solid ${styles?.color}`};

    svg {
        fill: ${styles?.iconBackColor};
        stroke: ${styles?.iconStrokeColor};
    }
`;

export const textFoucsBox = () => {

}

export const buttonBox = () => css`
    color: ${APP.config.style.buttonColor};

`
