import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PERMISSION, setMemberPinned } from "../../base/members";
import { ClickButton } from "../../base/ui";
import { isCheckPermission } from "../../room";
function LayoutToggglePin({ user_uuid, pinned }) {
    const dispatch = useDispatch();
    const isPinPermission = useSelector((state) => isCheckPermission(state, PERMISSION.PIN));
    if (!APP.config.ui.film.pin)
        return null;
    /**
     * 사용자 PIN ON/OFF
     */
    const onTogglePin = useCallback(() => {
        dispatch(setMemberPinned(user_uuid, !pinned));
    }, [user_uuid, pinned]);
    return (React.createElement(ClickButton, { className: !isPinPermission && "no-pointer no-hover", icon: "pinned", size: 16, color: `${pinned ? '#29abe2' : '#d5d5d5'}`, onClick: onTogglePin }));
}
export default React.memo(LayoutToggglePin);
