import _ from 'lodash';
import { toggleLoad } from '../../features/base/loading';
import { getLocalID } from '../../features/base/local';
import { ROLE, setBridgeId } from '../../features/base/members/index.ts';
import { leaveRoom, setActiveGroupId, setRoomJoined } from '../../features/room';
import { INIT_VISIBLE, VIDEO_MODE_LIST, setLayoutMode, videoLayoutMode } from '../../features/video-layout';
import JitsiManagement from './jitsi';
import MateManagement from './mate';
import i18next from 'i18next';
import { getLocalSharingStatus } from '../../features/screen-share';

class Management {
    constructor(props) {
        /**
         * jitsi : 현재 jitsi (영상)
         * defaultMate: 메인 방의 mate
         * mateManagement: 그룹의 mate
         * jitsiConference: 현재 jitsi 방 
         */
        this.jitsi = new JitsiManagement();
        this.defaultMate = new MateManagement('');
        this.mateManagement = this.defaultMate;
        this.groupMate = null;
        this.jitsiConference = null;

        this.setMateManagement = this.setMateManagement.bind(this);
    }

    /**
     * setMateManagement: 현재 방의 상태 
     * (
     *      전체 그룹 시작시, 
     *          - 그룹이 할당된 경우: management
     *          - 그룹이 할당 안된 경우: defaultMate 
     *      그룹 종료시, defaultMate
     * )
     */
    setMateManagement(management) {
        this.mateManagement = management ? management : this.defaultMate;        

        // this.mateManagement.resultOnlyJoin();
    }

    groupJoin({ group_id, group_role }) {
        if (group_id) {
            const group_mate = new MateManagement(group_id);
        
            return group_mate.connectAndJoin({ group_id, role_name: group_role })
                .then(response => {
                    APP.store.dispatch(setActiveGroupId(group_id));
                    this.setMateManagement(group_mate);
                    
                    this.groupMate = group_mate;
                    APP.store.dispatch(toggleLoad(false));
                    return response;
                });
        }
    }

    // , cur
    async changeManagement(pre, cur) {
        // 화면 공유 종료 
        if (getLocalSharingStatus(APP.store.getState)) {
            APP.store.dispatch(setLayoutMode(videoLayoutMode.grid, false));

            await APP.management.setScreenOption(null, null);
            APP.management.stopScreenShare(false);

        }
        
        if (pre && pre !== '') {
            await this.mateManagement.disconnect(true);
        }
        
        if (cur.group_id === '') {
            this.setMateManagement(this.defaultMate);
            return this.defaultMate.resultOnlyJoin();
        } else {
            return this.groupJoin(cur);
        } 
    }

    // group_id?; handlerMessage?
    groupMonitoringJoin(group_id, handlerMessage) {
        if (!group_id) {
            return Promise.reject('no group');
        }
        const groupMate = new MateManagement(group_id);
        return groupMate.connectAndJoin(
            { group_id, role_name: ROLE.ADMIN, joinType: 'hidden' },
            handlerMessage
        ).then(response => {
            return groupMate;
        });
    }

    /**
     * 회의실 입장 
     */
    join(password, role_name, access_token, isTemp = false) {
        const { dispatch, getState } = APP.store;
        return new Promise((resolve, reject) => {
            this.jitsi.connectAndJoin(isTemp)
                .then(conference => {
                    const jitsiUserId = conference.myUserId();

                    dispatch(setBridgeId(getLocalID(getState), jitsiUserId));
                    this.jitsiConference = conference;

                    return this.defaultMate.connectAndJoin({ password, role_name, access_token }, null, isTemp)
                        .then( async (startGroup) => {
                            // mate 에 jitsi track 업데이트를 하기 위함
                            this.setBridgeId(jitsiUserId);  

                            if (startGroup && startGroup?.group_id) {
                                dispatch(toggleLoad(true, i18next.t("group.assginGroup", { name: startGroup.group_name })));
                                await this.groupJoin(startGroup);
                            }                             
                            return resolve(); 
                        })
                        .catch(err => {
                            /**
                             * (fix) 여기서의 에러 처리 필요
                             */
                            console.log(err);

                            return reject(err);
                        });
                });
        })
    }

    /**
     * 회의실 퇴장
     */
    async leave(isTemp = false) {
        // 센스티에서 사용됨. (webview 통신)
        const data = { message: "close" };
        window.parent.postMessage(data, '*');

        window.SensPetAndroid && window.SensPetAndroid.closeSensPet();

        // 팝없창 동시 사용됨.
        window.close();
        try {
            if (getLocalSharingStatus(APP.store.getState)) {
                APP.store.dispatch(setLayoutMode(videoLayoutMode.grid, false));

                await APP.management.setScreenOption(null, null);
                APP.management.stopScreenShare(false);

            }
            Promise.all([
                this.defaultMate && this.defaultMate.disconnect(),
                this.mateManagement && this.mateManagement.disconnect(),
                this.jitsi && this.jitsi.disconnect()
            ])
            .then(() => {
                if (isTemp) return;

                APP.store.dispatch(setRoomJoined(false));
                APP.store.dispatch(leaveRoom());
            })
            .catch(err => {
                console.log(err)
                /**
                 * (fix) 여기서의 에러 처리 필요
                 */
                APP.store.dispatch(setRoomJoined(false));
                APP.store.dispatch(leaveRoom());
            });
        } catch (err) {
            console.log(err);
            window.close();
        }
    }

    toggleE2EE(isEnable) {
        this.jitsi.toggleE2EE(isEnable);
    }

    /**
     * (TRACK) jitsi conference user_id를 mate에 업데이트 해준다.
     * @param {string} bridge_id 
     */
    async setBridgeId(bridge_id) {
        const response = await this.defaultMate.setBridgeId(bridge_id);

        if (response.status !== 200) {
            /**
             * (fix) 여기서의 에러 처리 필요
             */
            console.log(" bridge id 세팅 오류 ");
        }
    }

    /**
     * (LAYOUT) 레이아웃 변경
     * @param {string} mode 
     * @returns 
     */
    async changeLayout(mode) {
        // 나의 방 권한이 존재하는 경우만 실행 
        const viewVisible = _.cloneDeep(INIT_VISIBLE);
        if (VIDEO_MODE_LIST.indexOf(mode) !== -1) {
            // 레이아웃 
            const response = await this.mateManagement.setLayoutMode(mode);
            if (response.status !== 200) {
                /**
                 * (fix) 여기서의 에러 처리 필요
                 */
                console.log(" 레이아웃 모드 오류 - " + mode);
                return response;
            }
        } else {
            viewVisible[mode] = true;
        }            
    
        // 화면 가시성 
        return this.mateManagement.setVisibleLayoutMode(viewVisible);
    }

    /**
     * (DOCUMENT) 문서 공유 저장 & 공유
     * @param {Object} data 
     * @returns 
     */
    setShareItem(data) {
        return this.mateManagement.setShareItem(data);
    }
    /**
     * (DOCUMENT) 문서 삭제 
     * @param {Object} data 
     * @returns 
     */
    deleteShareItem(data) {
        return this.mateManagement.deleteShareItem(data);
    }

    /**
     * (DOCUMENT) 현재 문서 Foucs
     * @param {string} uuid 
     * @param {Number} index 
     * @param {string} mode 
     * uuid, index: Number, mode
     * @returns 
     */
    focusShareItem(uuid, index, mode) {
        return this.mateManagement.setFocusShareItem(uuid, index, mode);
    }

    /**
     * (CANVAS) 문서 포인트 전송 
     * @param {string} uuid 
     * @param {Number} index 
     * @param {string} key 
     * @param {Object} property 
     * uuid, index: Number, key, property: Object
     * @returns 
     */
    sendSharePoints(uuid, index, key, property) {
        return this.mateManagement.sendSharePoints(uuid, index, key, property);
    }

    /**
     * (CANVAS) 문서 포인트 삭제 (라인 한줄 ) 
     * @param {string} uuid 
     * @param {Number} index 
     * @param {string|null} key 
     * @param {boolean} isAll 
     * //uuid, index: Number, key|null, isAll
     * @returns 
     */
    deleteShareLine(uuid, index, key, isAll) {
        return this.mateManagement.deleteShareLine(uuid, index, key, isAll);
    }
    /**
     * (CANVAS) 문서 판서 삭제 ( 한페이지 )
     * @param {string} uuid 
     * @param {Number} index 
     * @returns 
     */
    deleteShareCanvasIndex(uuid, index) {
        return this.mateManagement.deleteShareCanvasIndex(uuid, index);
    }

    /**
     * (MEMBER) 발표자 권한 요청
     * @param {string} member_uuid 
     * @returns 
     */
    requestPresentation(member_uuid) {
        return this.mateManagement.requestPresentation(member_uuid);
    }
    /**
     * (MEMBER) 발표자 권한 응답
     * @param {string} user_uuid 
     * @param {boolean} accepted
     * @returns 
     */
    responsePresentation(user_uuid, accepted) {
       return this.mateManagement.responsePresentation(user_uuid, accepted);
    }

    /**
     * (MEMBER) 사용자 권한 변경
     * @param {string} user_uuid 
     * @param {string} role 
     * @param {string} group_id 
     * @returns 
     */
    setMemberRole(user_uuid, role) {
        return this.mateManagement.setMemberRole(user_uuid, role);
    }

    /**
     * (MEMBER) 사용자 PIN 변경
     * @param {string} user_uuid 
     * @param {boolean} pinned 
     * @returns 
     */
    setMemberPinned(user_uuid, pinned) {
        return this.mateManagement.setMemberPinned(user_uuid, pinned);
    }

    /**
     * (MEMBER) 사용자 강제 퇴장
     * @param {string} user_uuid 
     * @returns 
     */
    setMemberKick(user_uuid) {
        return this.mateManagement.setMemberKick(user_uuid);
    }

    /**
     * (MEMBER) 사용자 손들기
     * @param {string} user_uuid 
     * @param {boolean} handler 
     * @returns 
     */
    raiseHand(user_uuid, handler) {
        return this.mateManagement.raiseHand(user_uuid, handler);
    }

    /**
     * (TRACK) 비디오 ON / OFF
     * @param {string} user_uuid 
     * @param {boolean} muted 
     * @returns 
     */
    setVideo(user_uuid, muted) {
        return this.mateManagement.setVideo(user_uuid, muted);
    }

    /**
     * (TRACK) 오디오 ON / OFF
     * @param {string} user_uuid 
     * @param {boolean} muted 
     * @returns 
     */
    setAudio(user_uuid, muted) {
        return this.mateManagement.setAudio(user_uuid, muted);
    }
    
    /**
     * (TRACK) 전체 비디오 ON / OFF
     * @param {boolean} muted 
     * @returns 
     */
    setAllVideo(muted) {
        return this.mateManagement.setAllVideo(muted);
    }
    
    /**
     * (TRACK) 전체 오디오 ON / OFF
     * @param {boolean} muted 
     * @returns 
     */
    setAllAudio(muted) {
        return this.mateManagement.setAllAudio(muted);
    }

    /**
     * (CHAT) 채팅 전송
     * @param {objcet} data 
     */
    sendMessage(data) {
        return this.mateManagement.sendMessage(data);
    }
    /**
     * (CHAT) 파일 전송
     * @param {Objcet} data 
     */
    sendFile(data) {
        return this.mateManagement.sendFile(data);
    }

    /**
     * (SCREEN SHARE) 
     * 화면 녹화 시작
     * @returns
     */
    startScreenShare() {
        return this.jitsi.startScreenShare();
    }
    /**
     * (SCREEN SHARE)
     * 화면 녹화 종료 
     * @returns 
     */
    stopScreenShare() {
        return this.jitsi.stopScreenShare();
    }

    /**
     * (SCREEN SHARE)
     * 현재 화면 공유 중이거나 화면 공유 종료를 알림.
     * @param {string} jitsi_id 
     * @param {string} member_uuid
     * @returns 
     */
    setScreenOption(jitsi_id, member_uuid) {
        return this.mateManagement.setScreenOption(jitsi_id, member_uuid);
    } 

    /**
     * (POLICY)
     * 현재 권한 
     * @param {Object} policy
     * @returns 
     */
    updateRoomPolicy(policy) {
        return this.mateManagement.updateRoomPolicy({ policy });
    }

    /**
     * (POLICY)
     * 그리드 수 변경 
     * @param {Number} count
     */
    setGridCount(count) {
        return this.mateManagement.setGridCount(count);
    }

    /**
     * (MEMBER) 녹화 중을 알림.
     * @param {*} user_uuid 
     * @param {*} recording 
     * @returns 
     */
    setRecording(user_uuid, recording) {
        return this.mateManagement.setRecording(user_uuid, recording);
    }

    /**
     * (TRACK)
     * @param {string} newTrackId 
     * @param {string} type 
     * @param {object} options 
     */
    updateTrack(newTrackId, type, options) {
        this.jitsi.updateTrack(newTrackId, type, options);
    }

    /**
     * (TRACK)
     * @param {*} oldTrack 
     * @param {*} newTrack 
     */
    replaceTrack(oldTrack, newTrack) {
        return this.jitsi.replaceTrack(oldTrack, newTrack)
    }

    /**
     * 로컬 사용자의 연결 상태 이벤트 등록
     * @param {function} handler 
     */
    registerUpdateLocalStats(handler) {
        this.jitsi.registerUpdateLocalStats(handler);
    }
    /**
     * remote 사용자의 연결 상태 이벤트 등록
     * @param {function} handler 
     */
    registerUpdateRemoteStats(handler) {
        this.jitsi.registerUpdateRemoteStats(handler);
    }

    /**
     * 전체 상태 업데이트 
     * @param {boolean} running 
     * @returns 
     */
    updateGroupStatus(running) {
        return this.defaultMate.updateGroupStatus(running);
    }

    /**
     * 그룹에 알림 메시지 전달
     * @param {string} message: 전달할 메시지
     * @param {string} group_id: 그룹 아이디 
     */
    notifyGroupMessage(message, group_id) {
        return this.defaultMate.notifyGroupMessage(message, group_id);
    }

    /**
     * (SURVEY) 설문조사 알림
     * @returns 
     */
    notifySurvey(uuid) {
        return this.mateManagement.notifySurvey(uuid);
    }
    /**
     * (SURVEY) 설문조사 응답
     * @param { string } uuid
     * @returns 
     */
    replySurvey(uuid) {
        return this.mateManagement.replySurvey(uuid);
    }

    updateNickname(nickname) {
        return this.defaultMate.updateNickname(nickname);
    }
}

export default Management;