import { createRoom, tempEnterRoom } from "../../room";
import { APP_WILL_MOUNT } from "../app";
import { ROLE } from "../members";
import { MiddlewareRegistry } from "../redux";
import { setDisplayName, updateSettings } from "../settings";
// @ts-ignore
import { storage } from "../util";
import { CLASS_MATE_LOGIN } from "./actionTypes";
import { loginByToken, partnerLoginByToken } from "./actions";
import { getSearchData, isLogin } from "./functions";
MiddlewareRegistry.register(store => next => async (action) => {
    const { dispatch, getState } = store;
    switch (action.type) {
        case APP_WILL_MOUNT:
            const storage_key = getSearchData('storage_key');
            if (storage_key) {
                const data = storage.get(storage_key);
                if (data) {
                    console.log(data.token);
                    data.token && await store.dispatch(loginByToken(data.token));
                    if (data.ui_option) {
                        store.dispatch(updateSettings({
                            displayName: data.ui_option?.defaultNickname || 'test',
                            enableLobby: data.ui_option?.enableLobbyPage || false,
                            enableNickname: data.ui_option?.enableNicknamePage || false
                        }));
                    }
                }
            }
            else {
                const storage_token = await storage.get('token');
                storage_token && await store.dispatch(loginByToken(storage_token));
            }
            break;
        case CLASS_MATE_LOGIN:
            storage.remove('token');
            const { token, user_id, nickname, roomCode, roomName, isCoach } = action.data;
            await dispatch(partnerLoginByToken({ token, user_id }));
            const isLoggedIn = isLogin(store.getState);
            if (isLoggedIn) {
                dispatch(setDisplayName(nickname));
                const role = isCoach ? ROLE.HOST : ROLE.PARTICIPANT;
                storage.save("role", role);
                console.log({ isCoach });
                if (roomCode && roomCode !== '') {
                    await dispatch(tempEnterRoom({ code: roomCode }));
                }
                else if (roomName && isCoach) {
                    await dispatch(createRoom(roomName));
                }
            }
            break;
    }
    return next(action);
});
