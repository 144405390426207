import React, { useMemo, useState } from "react";
import { Dialog } from "../dialog";
import { ClickButton, Input } from "../ui";
// @ts-ignore
import { useTranslation } from "react-i18next";
import { VodUrlStyled } from "./components/styles";
const AddUrlPopup = React.memo(({ isDialog = false, handlerAddVod }) => {
    const { t } = useTranslation();
    const [url, setUrl] = useState();
    const [name, setName] = useState();
    const onClick = () => {
        handlerAddVod(url, name);
    };
    const renderComponet = useMemo(() => (React.createElement(VodUrlStyled, null,
        React.createElement(Input, { className: 'vod_input', label: t('vod.url'), onChange: setUrl }),
        React.createElement(Input, { className: 'vod_input', label: t('vod.title'), onChange: setName }),
        React.createElement(ClickButton, { label: "\uC800\uC7A5", isText: true, onClick: onClick }))), [setUrl, setName, onClick]);
    if (isDialog) {
        return (React.createElement(Dialog, { className: 'vod_dialog', titleKey: t('vod.urlAdd'), width: 450 }, renderComponet));
    }
    return renderComponet;
});
export default AddUrlPopup;
