import React from "react";

function SetSecurityDialog({
    t, password, locked, isLocked, _onhandlerLocked, 
    renderHandlerLock, renderInput, renderSaveButton
}) {
    return (
        <div className="option roomLock">
            { locked ?
                <div>
                    <p>{t("roomLock.lockGuid")}</p>
                    <p>{t("roomLock.password")} : { password }</p>
                </div>
                :
                <>
                    <p className="guid"> { t("roomLock.noLockGuid") } </p>                            
                    <div className="nonSelect lock_wrapper">
                        <dl className={`password password_${isLocked}`} onClick={_onhandlerLocked}>
                            { renderHandlerLock() }
                        </dl>
                        { renderInput() }
                    </div>            
                </>                
            }
            { renderSaveButton() }
        </div>       
    )
}

export default SetSecurityDialog;