import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { COUNT_SET, DEVICE_SET, HANDLER_SET } from "../../constants";
import { SettingStyled } from "./styles";

function SettingContainer({
    type, renderTap, renderContent
}) {
    const { t } = useTranslation();

    const layoutRef = useRef();
    const [ isMobile, setIsMobile ] = useState(false);

    const handlerMobile = useCallback(() => {
        if (layoutRef.current) {
            const { width } = layoutRef.current.getBoundingClientRect();
            setIsMobile(width <= 450);
        }
    }, []);

    useEffect(() => {
        handlerMobile();
        window.addEventListener("resize", handlerMobile);
      
        return () => {
          window.removeEventListener("resize", handlerMobile);
        }
    }, [handlerMobile]);

     const getTitle = () => {
        switch (type) {
            case DEVICE_SET:
            case HANDLER_SET:
                return <h4>{t("notify.equalSetGuid")}</h4>;
            default:
                return null;
        }
    };

    return (
        <SettingStyled isMobile={isMobile} ref={layoutRef}>
            <div className="tab">
                { renderTap() }
            </div>

            <div className="content">
                { getTitle() }
                { renderContent() }
            </div>
        </SettingStyled>
    )
}

export default React.memo(SettingContainer);