import { BACKGROUND_ENABLED, SAVE_VIRTUAL_IMG, SET_VIRTUAL_BACKGROUND } from './actionTypes';
import { createVirtualBackgroundEffect } from './stream-effects';
/**
 * temp 이미지 base64저장
 */
export function saveVirtualImage(image) {
    return {
        type: SAVE_VIRTUAL_IMG,
        image
    };
}
/**
 * 로컬 참가자에게 가상 배경 비디오를 활성화할지 여부를 알립니다.
 *
 * @param {Object} options - 가상 배경 설정 옵션을 나타냅니다.
 * @param {Object} jitsiTrack - 배경 효과가 적용될 jitsi 트랙을 나타냅니다.
 * @returns {Promise}
 */
export function toggleBackgroundEffect(options, jitsiTrack) {
    return async function (dispatch, getState) {
        await dispatch(backgroundEnabled(options.enabled));
        await dispatch(setVirtualBackground(options));
        const state = getState();
        const virtualBackground = state['features/virtual-background'];
        if (jitsiTrack) {
            try {
                if (options.enabled) {
                    await jitsiTrack.track.setEffect(await createVirtualBackgroundEffect({
                        backgroundEffectEnabled: virtualBackground.backgroundEffectEnabled,
                        backgroundType: virtualBackground.backgroundType,
                        blurValue: virtualBackground.blurValue,
                        selectedThumbnail: virtualBackground.selectedThumbnail,
                        virtualSource: virtualBackground.virtualSource
                    }, dispatch));
                }
                else {
                    await jitsiTrack.track.setEffect(undefined);
                    dispatch(backgroundEnabled(false));
                }
            }
            catch (error) {
                dispatch(backgroundEnabled(false));
                console.error('Error on apply background effect:', error);
            }
        }
    };
}
/**
 * 선택된 가상 배경 이미지 객체를 설정합니다.
 *
 * @param {Object} options - 가상 배경 설정 옵션을 나타냅니다.
 * @returns {{
 *     type: SET_VIRTUAL_BACKGROUND,
 *     virtualSource: string,
 *     blurValue: number,
 *     type: string,
 * }}
 */
export function setVirtualBackground(options) {
    return {
        type: SET_VIRTUAL_BACKGROUND,
        virtualSource: options?.url,
        blurValue: options?.blurValue,
        backgroundType: options?.backgroundType,
        selectedThumbnail: options?.selectedThumbnail
    };
}
/**
 * 배경 효과가 활성화되었음을 로컬 참가자에게 알립니다.
 *
 * @param {boolean} backgroundEffectEnabled - 가상 배경 효과 활성화 여부를 나타냅니다.
 * @returns {{
 *      type: BACKGROUND_ENABLED,
 *      backgroundEffectEnabled: boolean
 * }}
 */
export function backgroundEnabled(backgroundEffectEnabled) {
    return {
        type: BACKGROUND_ENABLED,
        backgroundEffectEnabled
    };
}
