import React, { useEffect, useState } from "react";
import { connect } from "../redux";
import { getNotificationList } from "./functions";
import { Notification } from "./components";

function NotificationContainer({
    notifications
}) {
    const [list, setList] = useState([]);
    
    useEffect(() => {
        setList([...notifications]);
    }, [notifications]);

    return (
        <Notification list={list} />
    )
}

function _mapStateToProps(state) {
    const notifications = getNotificationList(state);
    return {
        notifications
    }
}

export default connect(_mapStateToProps)(NotificationContainer);