import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getCurrentMode, setLayoutMode, videoLayoutMode } from "../../../video-layout";
import { Dialog } from "../../dialog";
import { ClickButton } from "../../ui";
import { useSelector } from "react-redux";
const ChangeModeButton = React.memo(({ permissions, fix_mode }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const mode = useSelector((state) => getCurrentMode(state));
    const layout_list = useMemo(() => {
        let list = [];
        if (fix_mode) {
            list = [fix_mode];
        }
        else {
            if (permissions.indexOf('layout') !== -1) {
                const video_layout = [videoLayoutMode.grid, videoLayoutMode.pin, videoLayoutMode.voice, videoLayoutMode.seminar];
                list = [...list, ...video_layout];
            }
            if (permissions.indexOf('share') !== -1) {
                const share_layout = [videoLayoutMode.document, videoLayoutMode.note, videoLayoutMode.vod];
                list = [...list, ...share_layout];
            }
        }
        return list;
    }, [fix_mode]);
    console.log(layout_list);
    return (React.createElement(Dialog, { titleKey: "setting.modeChange", className: "option" }, layout_list.map((name) => {
        const isFocus = name === mode;
        return React.createElement(ClickButton, { key: name, className: `option_item ${isFocus ? 'isFocus' : ''}`, icon: name, color: "#000", label: t(`layout.${name}`), onClick: () => dispatch(setLayoutMode(name)) });
    })));
});
export default ChangeModeButton;
