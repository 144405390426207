import React, { useEffect, useMemo, useState } from "react";
import VideoTrack from "../../../base/media/components/web/VideoTrack";
const MemberVideo = React.memo(({ videoTrack, nickname }) => {
    const [muted, setMuted] = useState(true);
    useEffect(() => {
        setMuted(!videoTrack || videoTrack.muted);
    }, [videoTrack]);
    const renderMutedVideo = useMemo(() => {
        return (React.createElement("div", { className: "default" },
            React.createElement("div", { className: "center" },
                React.createElement("div", { className: "circleText overText" }, nickname))));
    }, [nickname]);
    // @ts-ignore
    return muted ? renderMutedVideo : React.createElement(VideoTrack, { videoTrack: videoTrack });
});
export default MemberVideo;
