import { ReducerRegistry } from '../redux';
import { LOGOUT, SET_USER_INFO } from './actionTypes';
const DEFAULT_STATE = {
    token: { access_token: null, refresh_token: null },
    user: {
        auth_name: null,
        role: {
            id: undefined,
            name: undefined
        },
        tenant_id: null,
        user_id: null,
        uuid: null
    }
};
;
ReducerRegistry.register('features/base/local', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case LOGOUT:
            return {
                ...DEFAULT_STATE
            };
        case SET_USER_INFO:
            return {
                ...state,
                user: action.user.user,
                token: action.token
            };
    }
    return state;
});
