import React, { useEffect, useRef, useState } from 'react';
import dashjs from 'dashjs';
import { ClickButton } from '../../ui';
import SeekInput from './SeekInput.web';
import { ControllerStyled } from './styles.web';
import VolumeButton from './VolumeButton';
import { getFitSize } from '../../../canvas/functions';
const DashPlayer = ({ url, hasPermission, result, fullCanvas, scale, canvasPos, isWidthFit, playing, seek, handlePause, handlePlaying, setDrawCanvas }) => {
    const videoRef = useRef(null);
    const [player, setPlayer] = useState(null);
    const [duration, setDuration] = useState(0);
    const [initStatus, setInitStatus] = useState(false);
    const [videoStyle, setVideoStyle] = useState({ width: 0, height: 0 });
    const [drawStyle, setDrawStyle] = useState({ width: 0, height: 0 });
    const [volume, setVolume] = useState(0);
    const handlerAdjust = async (player, result) => {
        if (result.status === "running") {
            const timeDiff = await result.player.getServerTimeDiff();
            // console.log(timeDiff)
            const playTime = Date.parse(result.play_time) + timeDiff;
            const position = Date.now() - playTime + result.play_position;
            player.seek(position / 1000 || 0);
            player.play();
        }
        else {
            player.seek(result.play_position / 1000);
            player.pause();
        }
        if (videoRef && videoRef.current) {
            const videoHeight = videoRef.current.videoHeight;
            const videoWidth = videoRef.current.videoWidth;
            setVideoStyle({ width: videoWidth, height: videoHeight });
        }
        setInitStatus(true);
    };
    const handlerUpdateVideo = () => {
        const drawStyle = getFitSize(fullCanvas, videoStyle, isWidthFit);
        setDrawCanvas({ width: drawStyle.width, height: drawStyle.height });
        setDrawStyle({ width: drawStyle.width, height: drawStyle.height });
    };
    useEffect(() => {
        handlerUpdateVideo();
    }, [fullCanvas, videoStyle, isWidthFit]);
    // dashjs 초기 세팅 설정
    useEffect(() => {
        if (!result)
            return;
        const player = dashjs.MediaPlayer().create();
        player.on(dashjs.MediaPlayer.events["STREAM_INITIALIZED"], (ev) => {
            setDuration(ev.streamInfo.duration);
            handlerAdjust(player, result);
        });
        videoRef.current && player.initialize(videoRef.current, undefined, false);
        result.url && player.attachSource(result.url);
        player.setVolume(0);
        setPlayer(player);
        return () => {
            if (player)
                player.reset();
        };
    }, [result]);
    const handlerPlay = () => {
        if (!player)
            return;
        handlePlaying(duration <= player.time() ? 0 : player.time());
    };
    const handlerSeekTime = ratio => {
        const time = duration * ratio;
        if (playing) {
            player && player.pause();
            handlePlaying(time);
        }
        else {
            player && player.seek(time);
        }
    };
    useEffect(() => {
        if (!initStatus || !player)
            return;
    }, [initStatus]);
    useEffect(() => {
        if (!initStatus)
            return;
        if (!player)
            return;
        if (playing)
            player.play();
        else
            player.pause();
    }, [playing]);
    useEffect(() => {
        if (!initStatus)
            return;
        if (!player)
            return;
        if (duration < seek) {
            player.pause();
            return;
        }
        player.seek(seek);
        if (playing)
            player.play();
    }, [seek]);
    useEffect(() => {
        if (!initStatus)
            return;
        if (!player)
            return;
        player.setVolume(volume);
    }, [volume]);
    useEffect(() => {
        if (videoRef && videoRef.current) {
            videoRef.current.style.transform = `translate(${canvasPos.x}px, ${canvasPos.y}px)`;
        }
    }, [canvasPos]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { width: `${fullCanvas.width}px`, height: `${fullCanvas.height}px`, overflow: "hidden", display: "flex" } },
            React.createElement("video", { ref: videoRef, controls: false, width: drawStyle.width * scale, height: drawStyle.height * scale, style: { margin: "auto" } },
                React.createElement("source", { src: url, type: "application/dash+xml" }))),
        player && initStatus &&
            React.createElement(ControllerStyled, null,
                React.createElement(SeekInput, { player: player, hasPermission: hasPermission, handlerSeekTime: handlerSeekTime }),
                React.createElement("div", { className: "control_box" },
                    React.createElement(ClickButton, { className: `${hasPermission ? 'room_label_icon' : 'no-hover no-pointer'}`, color: playing ? APP.config.style.focusColor : APP.config.style.playerColor, icon: "vodStart", size: 20, onClick: handlerPlay }),
                    React.createElement(ClickButton, { className: `${hasPermission ? 'room_label_icon' : 'no-hover no-pointer'}`, color: !playing ? APP.config.style.focusColor : APP.config.style.playerColor, icon: "vodPause", size: 20, onClick: handlePause }),
                    React.createElement(VolumeButton, { initVolume: volume, handlerSetVolume: setVolume })))));
};
export default DashPlayer;
