import React from "react";
import { useDispatch } from "react-redux";
import { addVodFile, openDocumentList } from "../../share-layout/actions";
import { AddFile, AddUrl } from './components';
// @ts-ignore
import { AddVodStyled } from "./components/styles";
import { addFileVod, addUrlVod } from "./functions";
import { toggleDialog } from "../dialog";
const AddVodItem = React.memo(({ isRoom, isDialog, isIcon = false, handler }) => {
    const dispatch = useDispatch();
    const handlerAddFile = (file) => {
        if (!file)
            handler.failed('api.noFile');
        else {
            if (isRoom)
                addFileVod(file, file.name, undefined, handler);
            else
                dispatch(addVodFile({ file }, file.name, undefined, handler));
        }
    };
    const handlerAddUrl = (external_source_url, name) => {
        if (!external_source_url)
            handler.failed('api.noUrl');
        else if (!name)
            handler.failed('api.noName');
        else {
            handler.setLoad(true);
            if (isRoom)
                addUrlVod(external_source_url, name, undefined, handler);
            else
                dispatch(addVodFile({ external_source_url }, name, undefined, handler));
        }
        if (isDialog) {
            dispatch(openDocumentList());
        }
        else if (!isRoom) {
            dispatch(toggleDialog());
        }
    };
    return (React.createElement(AddVodStyled, { isRoom: isRoom },
        APP.config.option.vodFile && React.createElement(AddFile, { isDialog: isDialog, isIcon: isIcon, handlerAddVod: handlerAddFile }),
        APP.config.option.vodUrl && React.createElement(AddUrl, { isDialog: isDialog, isRoom: isRoom, isIcon: isIcon, handlerAddVod: handlerAddUrl })));
});
export default AddVodItem;
