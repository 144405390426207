export const MEMBER_STATUS = {
    OCCUPIDE: 'occupied',
    VACATED: 'vacated',
    BANNED: 'banned',
    ALL: 'all'
};
export const ROLE = {
    ADMIN: 'admin',
    HOST: 'host',
    MANAGER: 'manager',
    PRESENTER: 'presenter',
    PARTICIPANT: 'participant',
    VIEWER: 'viewer',
    OBSERVER: 'observer',
};
export const indexRole = [ROLE.ADMIN, ROLE.HOST, ROLE.MANAGER, ROLE.PRESENTER, ROLE.PARTICIPANT];
export const memberChangeRole = [ROLE.HOST, ROLE.MANAGER, ROLE.PARTICIPANT];
var Permission;
(function (Permission) {
    Permission["LAYOUT"] = "layout";
    Permission["SHARE"] = "share";
    Permission["NOTE"] = "note";
    Permission["PIN"] = "pin";
    Permission["CHAT"] = "chat";
    Permission["KICK"] = "kick";
    Permission["POLL"] = "poll";
    Permission["DUAL"] = "dual";
    Permission["SECURE"] = "secure";
    Permission["SELF_RECORD"] = "control member self record";
    Permission["SERVER_RECORD"] = "server record";
    Permission["MEMBER_ROLE"] = "control member role";
    Permission["MEMBER_CAMERA"] = "control member camera";
    Permission["MEMBER_MIC"] = "control member mic";
    Permission["GROUP"] = "manage group";
})(Permission || (Permission = {}));
export const PERMISSION = {
    LAYOUT: Permission.LAYOUT,
    SHARE: Permission.SHARE,
    NOTE: Permission.NOTE,
    PIN: Permission.PIN,
    CHAT: Permission.CHAT,
    KICK: Permission.KICK,
    POLL: Permission.POLL,
    DUAL: Permission.DUAL,
    SECURE: Permission.SECURE,
    SELF_RECORD: Permission.SELF_RECORD,
    SERVER_RECORD: Permission.SERVER_RECORD,
    MEMBER_ROLE: Permission.MEMBER_ROLE,
    MEMBER_CAMERA: Permission.MEMBER_CAMERA,
    MEMBER_MIC: Permission.MEMBER_MIC,
    GROUP: Permission.GROUP,
};
export const ROLE_LEVEL = [ROLE.ADMIN, ROLE.HOST, ROLE.MANAGER, ROLE.PRESENTER, ROLE.PARTICIPANT];
export const CHANGE_ROLE_LIST = [ROLE.MANAGER, ROLE.PRESENTER, ROLE.PARTICIPANT];
export const MEMBER_STATUS_LEVEL = [MEMBER_STATUS.OCCUPIDE, MEMBER_STATUS.VACATED, MEMBER_STATUS.BANNED];
