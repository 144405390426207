import React, { Component } from 'react';
import { connect } from '../redux';
import { DialogContainer } from './components';

// type Props = {
//     _component: Function,
//     _componentProps: Object,
// };

function AbstractDialogContainer({
    _component, _componentProps
}) {
    if (!_component) {
        return null;
    } else {
        const renderDialogContent = () => {
            return (
                _component
                    ? React.createElement(_component, _componentProps)
                    : null
            )
        }

        return (
            <DialogContainer renderDialogContent={renderDialogContent} />
        )
    }   
}

/**
 *
 * @param {Object} state - The redux state.
 * @private
 * @returns {Props}
 */
function abstractMapStateToProps(state) {
    const stateFeaturesBaseDialog = state['features/base/dialog'];

    return {
        _component: stateFeaturesBaseDialog.component,
        _componentProps: stateFeaturesBaseDialog.componentProps
    };
}

export default connect(abstractMapStateToProps)(AbstractDialogContainer);