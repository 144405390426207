import React, { useEffect, useState } from "react";
import { MEDIA_TYPE, setAudioMuted } from "../../media";
import { isLocalTrackMutedByMediaType } from "../../tracks";
import { ClickButton } from "../../ui";
import { useDispatch, useSelector } from "react-redux";
const ToggleAudioButton = React.memo(({ className, label, enableLable, iconStyles }) => {
    const dispatch = useDispatch();
    const audioMuted = useSelector((state) => isLocalTrackMutedByMediaType(state, MEDIA_TYPE.AUDIO));
    const [muted, setMuted] = useState(false);
    const [icon, setIcon] = useState('mic_on');
    const [color, setColor] = useState(APP.config.style.focusColor);
    const _toggleAudioMuted = () => {
        dispatch(setAudioMuted(!audioMuted, false));
        setMuted(!audioMuted);
    };
    useEffect(() => {
        setMuted(audioMuted);
    }, [audioMuted]);
    useEffect(() => {
        setColor(muted ? 'red' : APP.config.style.focusColor);
        setIcon(muted ? 'mic_off' : 'mic_on');
    }, [muted]);
    return (React.createElement(ClickButton, { className: `${className} no-hover`, icon: icon, size: enableLable ? 20 : 24, label: label, color: color, styles: iconStyles, onClick: _toggleAudioMuted }));
});
export default ToggleAudioButton;
