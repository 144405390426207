import VirtaulImg from './../../assets/virtualImg/background-1.jpg';
import VirtaulImg1 from './../../assets/virtualImg/background-2.jpg';
import VirtaulImg2 from './../../assets/virtualImg/background-3.jpg';
import VirtaulImg3 from './../../assets/virtualImg/background-4.jpg';
import VirtaulImg4 from './../../assets/virtualImg/background-5.jpg';
import VirtaulImg5 from './../../assets/virtualImg/background-6.jpg';
import VirtaulImg6 from './../../assets/virtualImg/background-7.jpg';
/**
 * 다양한 가상 배경 유형의 열거입니다.
 *
 * @enum {string}
 */
export const VIRTUAL_BACKGROUND_TYPE = {
    IMAGE: 'image',
    BLUR: 'blur',
    NONE: 'none'
};
// 가상 배경 업로드 제한은 24개입니다. 업로드 수가 25개이면 deleteStoredImage 함수를 트리거하여 첫 번째/가장 오래된 업로드 배경을 삭제합니다.
export const BACKGROUNDS_LIMIT = 25;
export const IMAGES = [
    {
        tooltip: 'image1',
        id: '1',
        src: VirtaulImg,
    },
    {
        tooltip: 'image2',
        id: '2',
        src: VirtaulImg1
    },
    {
        tooltip: 'image3',
        id: '3',
        src: VirtaulImg2
    },
    {
        tooltip: 'image4',
        id: '4',
        src: VirtaulImg3
    },
    {
        tooltip: 'image5',
        id: '5',
        src: VirtaulImg4
    },
    {
        tooltip: 'image6',
        id: '6',
        src: VirtaulImg5
    },
    {
        tooltip: 'image7',
        id: '7',
        src: VirtaulImg6
    }
];
