import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClickButton } from "../base/ui";
import { DevicePermission } from "./components";
import { useSelector } from "react-redux";
import { ROLE, checkRoleLevel, getLocalMemberRole } from "../base/members";

function DevicePermissionContainer({
    initial_camera, initial_mic, handlerUpdatePolicy
}) {
    const { t } = useTranslation();
    const local_role = useSelector(state => getLocalMemberRole(state));
    const [camera, setCamera] = useState(true);
    const [mic, setMic] = useState(true);
    const [checkRole, setCheckRole] = useState(false);

    useEffect(() => {
        setCheckRole(checkRoleLevel(local_role, ROLE.MANAGER));
    }, [local_role]);

    useEffect(() => {
        setMic(initial_mic === "off" ? false : true);
    }, [initial_mic]);

    useEffect(() => {
        setCamera(initial_camera === "off" ? false : true);
    }, [initial_camera]);

    /**
     * (TRACK) 전체 오디오 ON / OFF
     * @param {Boolean} muted 
     * @returns 
     */
    const audioMuted = async (isMuted) => {
        const response = await APP.management.setAllAudio(isMuted);
        if (response.status === 200) {
            handlerUpdatePolicy({ initial_camera, initial_mic: isMuted ? 'on' : 'off' });
        }
    }

    /**
     * (TRACK) 전체 비디오 ON / OFF
     * @param {Boolean} muted 
     * @returns 
     */
    const videoMuted = async (isMuted) => {
        const response = await APP.management.setAllVideo(isMuted);
        if (response.status === 200) {
            handlerUpdatePolicy({ initial_camera: isMuted ? 'on' : 'off' , initial_mic });            
        }
    }

    const mediaButtons = useMemo(() => [{    
        key: "allAudioOn",
        label: t("devicePermissionSetting.allAudioOn"),
        icon: "allAudioOn",
        focusClass: mic ? "isFocus" : "",
        onClick: () => audioMuted(true)  
    },  {
        key: "allAudioOff",
        label: t("devicePermissionSetting.allAudioOff"),
        icon: "allAudioOff",
        focusClass: !mic ? "isFocus" : "",
        onClick: () => audioMuted(false)  
    },  {   
        key: "allVideoOn",    
        label: t("devicePermissionSetting.allVideoOn"),    
        icon: "allVideoOn",    
        focusClass: camera ? "isFocus" : "",    
        onClick: () => videoMuted(true)  
    },  {    
        key: "allVideoOff",    
        label: t("devicePermissionSetting.allVideoOff"),    
        icon: "allVideoOff",    
        focusClass: !camera ? "isFocus" : "",    
        onClick: () => videoMuted(false)  
    }], [mic, camera, t]);

    const renderButtons = (styles) => {
        return mediaButtons.map(item => (
            <ClickButton key={item.key} className={`media-button ${!checkRole && 'no-hover'} ${item.focusClass}`} 
                label={item.label}
                icon={item.icon} onClick={checkRole ? item.onClick : null}
                size={30} numberOfLines={2} styles={styles}
            />  
        ));
    }

    return (
        <DevicePermission guid={checkRole && t("devicePermissionSetting.guid")} renderButtons={renderButtons} />
    );
        
}

export default React.memo(DevicePermissionContainer);