import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RoomLock } from '.';
import { Dialog, toggleDialog } from '../../base/dialog';
import { translate } from '../../base/i18n';
import { toggleLoad } from '../../base/loading';
import { Input, ClickButton } from '../../base/ui';
import { setRoomPassword } from '../action';


function AbstractRoomLock({
    t, enterRoom
}) {
    const dispatch = useDispatch();
    const [password, setPassword] = useState('');

    useEffect(() => {
        dispatch(toggleLoad(false));
    }, []);

    const onEnterRoom = async () => {
        dispatch(toggleLoad(true));

        dispatch(setRoomPassword(password, true));
        await enterRoom(dispatch, password, true);

        dispatch(toggleDialog());
    }

    const renderInput = (styles) => {
        return (
            <Input className="lock_wrapper" type="password" 
                placeholder={ t("roomLock.inputPassword") }
                label={ t("roomLock.inputPasswordGuid") }
                customStyles={styles}
                onChange={setPassword} />
        )
    }

    const renderEnterBtn = () => {
        return (
            <ClickButton className={'text-button'} isText={true} label={t("deviceSelection.enter")} onClick={onEnterRoom} />
        )
    }

    return (
        <Dialog titleKey={t("dialog.security")} width="350">
            <RoomLock t={t} renderInput={renderInput} renderEnterBtn={renderEnterBtn} />
        </Dialog>
    )
}

   export default translate(AbstractRoomLock);