import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import { Dialog, hideDialog } from "../../dialog";
import { translate } from "../../i18n";
import { ClickButton } from "../../ui";
function InviteCode({ t, code }) {
    const dispatch = useDispatch();
    const onCopy = () => {
        // dispatch(showNotification({titleKey: "invite.copyRoom"}, NOTIFICATION_TIMEOUT));
        dispatch(hideDialog());
    };
    return (React.createElement(Dialog, { titleKey: t("invite.title"), width: "300" },
        React.createElement("div", { className: 'invite_wrapper' },
            React.createElement("p", null, t("invite.guid")),
            React.createElement(CopyToClipboard, { text: code, onCopy: onCopy },
                React.createElement(ClickButton, { className: "text-button", label: t("invite.copyInviteCode") })))));
}
export default translate(InviteCode);
