import React, { Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeviceSelectionContainer } from "../../device-selection";
import { toggleOpenGroups } from "../../groups/action";
import { getActiveGroupId } from "../../room";
import { AttendanceContainer } from "../../room-attendance";
import { AbstractSecurityDialog } from "../../room-lock";
import { getDualMonitor, toggleDualMonitor, videoLayoutMode } from "../../video-layout";
import { setAudioInputDevice, setVideoInputDevice } from "../devices";
import { openDialog, toggleDialog } from "../dialog";
import { translate } from "../i18n";
import { PERMISSION, ROLE, getLocalMemberRole } from "../members";
import { getRecordStatus, startServerRecord, stopTempServerRecord } from "../record";
import { RECORDING_TYPE } from "../record/constant";
import { SettingContainerDialog } from "../settings";
import { ClickButton } from "../ui";
import { FullScreenButton, OptionDialog } from "./components";
import { getIsSafari } from "../environment/utils";
import { getEnabledGroup } from "../../groups";
import E2EEContainer from "../../room-lock/E2EEContainer";
import VirtaulBackGround from "../../virtual-background/components/VirtaulBackGround";
import SurveyContainer from "../survey/SurveyContainer";
import SendNotificationPopup from "../notification/SendNotifiactionPopup";

const styles = {
    wrapper: {
        flexDirection: "row",
        padding: 8,
        margin: 4,
        borderColor: "#000"
    }, text: {
        marginLeft: 20
    }
}

function OptionDialogContainer({
    t, permissions, isMobile
}) {
    const dispatch = useDispatch();
    const localRole = useSelector(state => getLocalMemberRole(state));
    const serverRecord = useSelector(state =>  getRecordStatus(state, RECORDING_TYPE.SERVER).enabled);
    const personalRecord = useSelector(state =>  getRecordStatus(state, RECORDING_TYPE.PERSONAL).enabled);
    const activeGroupId = useSelector(state => getActiveGroupId(state));
    const isDualMonitor = useSelector(state => getDualMonitor(state));
    const groupOpen = useSelector(state => getEnabledGroup(state));

    // 분할 모드 count 설정 
    const renderGridCount = (item) => {
        const openGridCountDialog = () => null; //dispatch(toggleDialog(ChangeGridCountDialog));
        // 레이아웃 권한이 있는 사용자만 표출 
        return (
            <ClickButton key={item} className="option_item" icon={videoLayoutMode.grid} 
                label={t(`dialog.${item}`)} color="#000"
                onClick={openGridCountDialog} />
        )
    }
    

    // 보안 (비밀번호 설정)
    const renderPassword = (item) => {
        const openSecurityDialog = () => {
            dispatch(toggleDialog(AbstractSecurityDialog));
        }
        // 레이아웃 권한이 있는 사용자만 표출 
        return (
            <ClickButton key={item} className="option_item" icon={item} 
                label={t(`dialog.${item}`)} color="#000" size={20} 
                onClick={openSecurityDialog} />
        )
    }

    // E2EE 
    const renderE2EE = (item ) => {
        const openE2EEDialog = () => {
            dispatch(toggleDialog(E2EEContainer));
        }
        // 레이아웃 권한이 있는 사용자만 표출 
        return (
            <ClickButton key={item} className="option_item" icon='e2ee' 
                label={t(`dialog.${item}`)} color="#000" size={20} 
                onClick={openE2EEDialog} />
        )
    }
    
    // 개인 녹화 
    const renderPersonalREC = (item) => {
        const toggleRecord = () => {
            // dispatch(togglePersonalRecord(!personalRecord))
        }

        return (
            <ClickButton key={item} className="option_item" icon={item}
                label={t(`dialog.${personalRecord}SelfRecord`)} color="#000" size={20}
                onClick={toggleRecord} />
        )
    }

    // 서버 녹화 
    const renderServerREC = (item) => {
        const toggleRecord = () => {
            if (getIsSafari()) {
                APP.UI.alertMessage("notify.noSafari");
            } else {
                serverRecord ? dispatch(stopTempServerRecord()) : dispatch(startServerRecord());
            }
        }

        return (
            <ClickButton key={item} className="option_item" icon={item} 
                label={t(`dialog.${serverRecord}ServerRecord`)} color="#000" size={20}
                onClick={toggleRecord} />
        )
    }

    // 장치 설정 
    const renderSettingDevice = (item) => {
        const onEnterRoom = (videoId, micId) => {
            dispatch(setVideoInputDevice(videoId));
            dispatch(setAudioInputDevice(micId));

            dispatch(toggleDialog());
        }

        const toggleSettingDevice = () => {
            dispatch(toggleDialog(DeviceSelectionContainer, { isDialog: true, noShowMuted: true, onEnterRoom }))
        }

        return (
            <ClickButton key={item} className="option_item" icon={item} 
                label={t(`dialog.${item}`)} color="#000" size={20}
                onClick={toggleSettingDevice} />
        )
    }

    // 설정 
    const renderSetting = item => {
        const onClick = () => {
            dispatch(toggleDialog(SettingContainerDialog, { permissions }));
        }
        
        return (
            <ClickButton key={item} className="option_item" icon='devicePermission' 
                label={t(`dialog.setting`)} color="#000" size={20}
                onClick={onClick} />
        )
    }

    // 듀얼 모니터 
    const renderDualMonitor = (item) => {
        const onClick = () => {
            dispatch(toggleDualMonitor(isDualMonitor));
        }

        const label = useMemo(() => isDualMonitor ? 'close_dual' : 'open_dual', [isDualMonitor]);
        return (
            <ClickButton key={item} className="option_item" icon={item} 
                label={t(`dialog.${label}`)} color="#000" size={20}
                onClick={onClick} />
        )
    }

    // 출석 체크  
    const renderAttendance = (item) => {
        const openAttendance = () => {
            dispatch(openDialog(AttendanceContainer));
        }
        return (
            <ClickButton key={item}  className="option_item" icon={item} 
                label={t(`dialog.${item}`)} color="#000"
                onClick={openAttendance} />
        )
    }

    // 그룹 
    const renderGroup = (item) => {
        const openGroup = () => {
            dispatch(toggleOpenGroups());
        }

        return (
            <ClickButton key={item}  className="option_item" icon={item} 
                label={t(`dialog.${groupOpen ? 'group_close' : 'group_open'}`)} color="#000"
                onClick={openGroup} />
        )
    }

    // Blur
    const renderBlur = (item) => {
        const openBlur = () => {
            if (getIsSafari()) {
                APP.UI.alertMessage("notify.noSafari");
            } else {
                dispatch(toggleDialog(VirtaulBackGround));
            }
        }

        return (
            <ClickButton key={item}  className="option_item" icon={item} 
                label={t('dialog.blur')} color="#000" onClick={openBlur} />
        )
    }

    // 설문조사
    const renderSurvey = (item) => {
        const toggleSurvey = () => {
            dispatch(toggleDialog(SurveyContainer, { permissions }));
        }

        return (
            <ClickButton key={item}  className="option_item" icon={item} 
                label={t('dialog.survey')} color="#000" onClick={toggleSurvey} />
        )
    }

    // 공지 
    const renderNotification = item => {
        const toggleNotification = () => {
            dispatch(toggleDialog(SendNotificationPopup));
        }

        return (
            <ClickButton key={item}  className="option_item" icon={item} 
                label={t('dialog.notification')} color="#000" onClick={toggleNotification} />
        )
    }

    const renderOption  = (item) => {
        switch (item) {
            case "notification":
                return renderNotification(item);

            case "survey":
                return renderSurvey(item);

            case "blur": 
                return renderBlur(item);

            case "e2ee":
                if (permissions.indexOf(PERMISSION.SECURE) === -1) return null;
                return renderE2EE(item);

            case "security":
                if (permissions.indexOf(PERMISSION.SECURE) === -1) return null;
                return renderPassword(item);

            case "selfRecord":
                if (permissions.indexOf(PERMISSION.SELF_RECORD) === -1) return null;
                return renderPersonalREC(item);

            case "serverRecord":
                if (permissions.indexOf(PERMISSION.SERVER_RECORD) === -1) return null;
                return renderServerREC(item);

            case "settingDevice":
                return renderSettingDevice(item);

            case "setting":
                return renderSetting(item);
                
            case "dualMonitor":
                if (localRole === ROLE.PARTICIPANT) return null;
                return renderDualMonitor(item);

            case "devicePermission":
                if (permissions.indexOf(PERMISSION.MEMBER_CAMERA) === -1 || permissions.indexOf(PERMISSION.MEMBER_MIC) === -1) return null;
                return renderDevicePermission(item);

            case "fullscreen":
                return (
                    <Fragment key={item}>
                        <FullScreenButton item={item} styles={styles} />
                    </Fragment>
                )

            case "attendance":
                if (localRole === ROLE.PARTICIPANT || localRole === ROLE.PRESENTER) return null;
                return renderAttendance(item);

            case "group":
                if (permissions.indexOf(PERMISSION.GROUP) === -1 ) return null;
                if (activeGroupId !== '') return null;
                return renderGroup(item);
        }
    }

    const renderComponent = () => {
        return (
            APP.config.optionList.map(item => {
                if (!APP.config.option[item] && item !== 'setting') return null;
                return renderOption(item);          
            })
        )
    }   

    return (
        <OptionDialog t={t}>
            { renderComponent() }
        </OptionDialog>
    )
}

export default translate(React.memo(OptionDialogContainer));