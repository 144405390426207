import React, { useMemo } from "react";
import { SaveChatButton } from "../../chat";
import ChatLockFileOnOff from "../../chat/ChatLockFileOnOff";
import { ClickButton } from "../../ui";
import { SidebarClose, SidebarSearch, SidebarSearchInput, SidebarTitle } from "./SideBarOption";
function SideBarHeader({ groupId, isMonitoring, name, onSwitchSearch, showBody, hideContent, renderInviteBtn, setOnSwitchSearch, handlerClose, handlerSearchText, }) {
    const renderSub = useMemo(() => {
        if (name === 'chat') {
            return (React.createElement(React.Fragment, null,
                APP.config.option.chatFileLock && React.createElement(ChatLockFileOnOff, null),
                React.createElement(SaveChatButton, null)));
        }
        return (React.createElement(React.Fragment, null,
            groupId === '' && React.createElement(ClickButton, { icon: "nickEdit", size: 16, color: "#000", onClick: () => APP.eventManager.publish('CHANGE_NICKNAME') }),
            renderInviteBtn && renderInviteBtn(),
            React.createElement(SidebarSearch, { onSwitchSearch: onSwitchSearch, setOnSwitchSearch: setOnSwitchSearch })));
    }, [name, groupId, onSwitchSearch]);
    const renderOption = useMemo(() => {
        if (isMonitoring)
            return null;
        return (React.createElement("div", { className: "right_sheader" },
            renderSub,
            React.createElement(SidebarClose, { handlerClose: handlerClose, iconStyles: { display: "inline-flex" } })));
    }, [isMonitoring, renderSub]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "sheader" },
            React.createElement(SidebarTitle, { name: name, showBody: showBody, hideContent: hideContent }),
            renderOption),
        onSwitchSearch && React.createElement(SidebarSearchInput, { handlerSearchText: handlerSearchText })));
}
export default SideBarHeader;
