/**
 * SET_TIMEOUT 상수는 간격을 설정하는 데 사용되며 다음에서 설정됩니다.
 * {
 *      id: SET_TIMEOUT,
 *      timeMs: 33
 * }.
 */
export const SET_TIMEOUT = 1;
/**
 * CLEAR_TIMEOUT 상수는 간격을 지우는 데 사용되며 request.data 속성의 id 속성에 설정됩니다.
 *
 * {
 *      id: CLEAR_TIMEOUT
 * }.
 */
export const CLEAR_TIMEOUT = 2;
/**
 * TIMEOUT_TICK 상수는 응답으로 사용되며 id 속성에 설정됩니다.
 *
 * {
 *      id: TIMEOUT_TICK
 * }.
 */
export const TIMEOUT_TICK = 3;
/**
 * Blob에서 URL을 생성하려면 문자열로 다음 코드가 필요합니다.
 * 그러면 URL이 WebWorker로 전달됩니다. 그 이유는 탭이 비활성 상태일 때 조절되지 않는 setInterval을 사용할 수 있도록 하기 위함입니다.
 */
const code = `
    var timer;

    onmessage = function(request) {
        switch (request.data.id) {
        case ${SET_TIMEOUT}: {
            timer = setTimeout(() => {
                postMessage({ id: ${TIMEOUT_TICK} });
            }, request.data.timeMs);
            break;
        }
        case ${CLEAR_TIMEOUT}: {
            if (timer) {
                clearTimeout(timer);
            }
            break;
        }
        }
    };
`;
// @ts-ignore
export const timerWorkerScript = URL.createObjectURL(new Blob([code], { type: 'application/javascript' }));
