import React, { useMemo, useState } from "react";
import { VodPlayer } from "../../../base/vod";
import { DOCUMENT_MODE, VOD_MODE } from "../../../share-layout/constants";
import CanvasDraw from "./CanvasDraw";
import PDFViewer from "./PDFViewer";
const CanvasDrawLayout = React.memo(({ focusIndex: index, isVod, tool, documentItem, option, drawCanvas, fullCanvas, setDrawCanvas, setFocusTool }) => {
    const [canvasPos, setCanvasPos] = useState({ x: 0, y: 0 });
    const { fullWidth, fullHeight, scale, isWidthFit } = useMemo(() => {
        const scale = option.scale / 100;
        return {
            fullWidth: drawCanvas.width * scale,
            fullHeight: drawCanvas.height * scale,
            scale,
            isWidthFit: option.isWidthFit
        };
    }, [option.scale, option.isWidthFit, drawCanvas]);
    const { config, isDocumentMode, documentPage, uuid } = useMemo(() => {
        return {
            isDocumentMode: (documentItem?.config === DOCUMENT_MODE),
            documentPage: documentItem?.page,
            uuid: documentItem?.uuid,
            config: documentItem?.config
        };
    }, [documentItem?.config, documentItem?.page, documentItem?.uuid]);
    const renderVodPlayer = useMemo(() => {
        if (!documentPage)
            return null;
        if (config === VOD_MODE) {
            return (React.createElement(VodPlayer, { fullCanvas: fullCanvas, urlList: documentItem?.list, canvasPos: canvasPos, streaming_id: uuid, scale: scale, isWidthFit: isWidthFit, streaming_auth: documentItem?.user_uuid, isComplete: documentPage, setDrawCanvas: setDrawCanvas }));
        }
    }, [uuid, scale, config, canvasPos, documentPage, documentItem?.list, documentItem?.user_uuid, fullCanvas, isWidthFit]);
    const renderDocumentPDF = useMemo(() => {
        if (!isDocumentMode)
            return null;
        if (!documentPage)
            return null;
        return (React.createElement(PDFViewer, { index: index, documentPage: documentPage, fullCanvas: fullCanvas, canvasPos: canvasPos, scale: scale, isWidthFit: isWidthFit, setDrawCanvas: setDrawCanvas }));
    }, [index, documentPage, fullCanvas, canvasPos, scale, isWidthFit]);
    const renderDraw = useMemo(() => {
        if (!uuid)
            return null;
        // if (isVod) return null;
        return (React.createElement(CanvasDraw, { isVod: isVod, uuid: uuid, index: index, isDocumentMode: isDocumentMode, drawCanvas: drawCanvas, fullCanvas: fullCanvas, scale: scale, canvasPos: canvasPos, tool: tool, option: option, setCanvasPos: setCanvasPos, setFocusTool: setFocusTool }));
    }, [isVod, isDocumentMode, drawCanvas, fullCanvas, scale, canvasPos, tool, option]);
    if (!documentItem)
        return null;
    return (React.createElement("div", { style: {
            width: fullWidth,
            height: isVod ? fullHeight - 55 : fullHeight,
            margin: isVod ? 0 : "auto",
            overflow: 'hidden'
        } },
        renderVodPlayer,
        renderDocumentPDF,
        renderDraw));
});
export default CanvasDrawLayout;
// function CanvasDrawLayout({
//     local, mode, isVod, document_user_id, isWhiteMode, documentPage, documentMode,
//     scale, tool, focusTool, canvasOption, 
//     uuid, index, shared, 
//     drawCanvas, fullCanvas, canvasPos,
//     setCanvasPos, setFocusTool, setDrawCanvas,
//     handlerUpdateCanvasTool,
//     handlerChangeFoucsPage
// }) {
//     const { t } = useTranslation();
//     if (!local) return null;
//     if (!shared && local.user_uuid !== document_user_id) {
//         if (!uuid) {
//             return (
//                 <div className="noDocument">
//                     <span className="icoImg documet_img" > </span>
//                     <span>{t("document.resAddDocument", { mode: t(`layout.${mode}`) })}</span>
//                 </div>
//             )
//         } else {
//             return (
//                 <div className="noDocument">
//                     <span className="icoImg documet_img" > </span>
//                     <span>{t("document.no_document", { mode: t(`layout.${mode}`) })}</span>
//                 </div>
//             )
//         }        
//     } else {
//         return (
//             <div style={{ 
//                 width: drawCanvas.width * scale, 
//                 height: drawCanvas.height * scale, 
//                 margin: isVod ? 0 :"auto",
//                 background: documentMode ? "#fff" : null }}>  
//                 { !documentMode && <PDFViewer 
//                     documentPage={documentPage}
//                     documentMode={documentMode}
//                     drawCanvas={drawCanvas}
//                     fullCanvas={fullCanvas}
//                     canvasPos={canvasPos}
//                     scale={scale}
//                     setDrawCanvas={setDrawCanvas}
//                     isWidthFit={canvasOption?.isWidthFit}
//                     uuid={uuid} index={index}
//                 /> }
//                 <CanvasDraw
//                     mode={mode}
//                     isWhiteMode={isWhiteMode}
//                     local={local}
//                     tool={tool} scale={scale}
//                     drawCanvas={drawCanvas}
//                     fullCanvas={fullCanvas}
//                     uuid={uuid} index={index}
//                     canvasOption={canvasOption}
//                     canvasPos={canvasPos} setCanvasPos={setCanvasPos}
//                     focusTool={focusTool} setFocusTool={setFocusTool}
//                     handlerUpdateCanvasTool={handlerUpdateCanvasTool}
//                     handlerChangeFoucsPage={handlerChangeFoucsPage}
//                 />
//             </div>
//        )
//     } 
// }
// export default React.memo(CanvasDrawLayout);
