export const RESERVATION_PAGE_TYPE = {
    HELD_ROOM: 'heldRoom',
    RESERVE_ROOM: 'reserveRoom',
    REGIST: 'regist',
    MODIFY: 'modify'
};
export const ROOM_STATUS = {
    BOOKED: 'booked',
    HELED: 'held'
};
export const SHOW_DEFAULT_COUNT = 10;
