import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SetSecurityDialog } from ".";
import { Dialog } from "../../base/dialog";
import { translate } from "../../base/i18n";
import { ClickButton, Input } from "../../base/ui";
import { setRoomPassword } from "../action";

function AbstractSecurityDialog({
    t
}) {
    // 현재 방 상태 
    const { password, locked } = useSelector(state => state['features/room-lock']);
    
    const [isLocked, setLocked] = useState(false);
    const [newPassword, setNewPassword] = useState(password);
    const dispatch = useDispatch();

    useEffect(() => {
        /** 상태 업데이트!! */
        const isLocked = password ? true : false;
        setLocked(isLocked);
        setNewPassword(password);
    }, [password]);


    const _onChangeRoomPwd = () => {
        let pwd = '';

        if (locked) {
            setLocked(false);
        } else {
            pwd = isLocked ? newPassword : '';
        }

        dispatch(setRoomPassword(pwd));
    }

    const _onPasswordChanged = value => {
        setNewPassword(value);
    }

    const _onhandlerLocked = () => {
        setLocked(!isLocked);
    };

    const renderHandlerLock = (styles, isClick) => {
        return (
            <ClickButton className={`no-hover security security_${isLocked}`} 
                icon={'fill_circle'} size={22}
                color={"#fff"}
                styles={styles}
                onClick={isClick ? _onhandlerLocked : null}
                label={t(`roomLock.${isLocked}Lock`)} />
        )
    }

    const renderInput = () => {
        if (!isLocked) return null;

        return (
            <Input 
                type="text" defaultValue={password} 
                customStyles={{
                    borderWidth: 1, borderColor: "#000", 
                    padding: 3, width: 190,
                    paddingHorizontal: 12,
                    marginVertical: 12, marginHorizontal: "auto", borderRadius: 4
                }}
                onChange={_onPasswordChanged} 
            />
        )
    }

    const renderSaveButton = () => {
        if (!isLocked) return null;
        return (
            <ClickButton className='text-button locked_btn' 
                isText={true}
                onClick={_onChangeRoomPwd} 
                label={ locked ? t("roomLock.release") : t("roomLock.add") }/>
        )
    }

    return (
        <Dialog titleKey={t("dialog.security")} width="350">
            <SetSecurityDialog 
                t={t}
                locked={locked} password={password} isLocked={isLocked}
                _onhandlerLocked={_onhandlerLocked}
                renderHandlerLock={renderHandlerLock}
                renderInput={renderInput}
                renderSaveButton={renderSaveButton}
            />
        </Dialog>
    )
}
export default translate(AbstractSecurityDialog);