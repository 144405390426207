import i18next from 'i18next';
import React, { useEffect } from 'react';
import { I18nextProvider } from "react-i18next";
import { Provider } from 'react-redux';
import { compose, createStore } from 'redux';
import Thunk from 'redux-thunk';
import { BaseContainer } from '../base/app';
import { AbstractDialogContainer } from '../base/dialog';
import { Loading } from "../base/loading";
import { MiddlewareRegistry, ReducerRegistry, StateListenerRegistry } from '../base/redux';
function _createStore() {
    const reducer = ReducerRegistry.combineReducers({});
    const middleware = MiddlewareRegistry.applyMiddleware(Thunk);
    const composeEnhancers = compose;
    const store = createStore(reducer, composeEnhancers(middleware));
    StateListenerRegistry.subscribe(store);
    if (typeof APP !== 'undefined') {
        APP.store = store;
    }
    return store;
}
let store = null;
const AppContainer = React.memo(() => {
    store = _createStore();
    useEffect(() => {
        const meta = document.createElement('meta');
        meta.name = 'google';
        meta.content = 'notranslate';
        document.getElementsByTagName('head')[0].appendChild(meta);
        let name = 'Meetmate';
        switch (APP.config.name) {
            case "ispark":
                name = "USG 코딩아카데미";
                break;
        }
        document.title = name;
    }, []);
    return (React.createElement(I18nextProvider, { i18n: i18next },
        React.createElement(Provider, { store: store },
            React.createElement(BaseContainer, null),
            React.createElement(AbstractDialogContainer, null),
            React.createElement(Loading, null))));
});
export default AppContainer;
