import React, { useMemo } from "react";
import { Dialog } from "../../base/dialog";
const RoomConfigurationDialog = React.memo(({ item, hideDialog, renderBottomBtn }) => {
    const renderComponet = useMemo(() => {
        if (!item.component)
            return null;
        return React.createElement(item.component, item.props);
    }, [item.component]);
    return (React.createElement("div", { className: "dialog vod" },
        React.createElement(Dialog, { className: 'vod_dialog', titleKey: item.title, style: item.style, width: item.width, onCancel: hideDialog },
            renderComponet,
            renderBottomBtn)));
});
export default RoomConfigurationDialog;
