import * as pdfjsLib from "pdfjs-dist";
import React, { useEffect, useRef, useState } from "react";
import { createTaskQueue } from "../../../../modules/util/helpers";
import { getFitSize } from "../../functions";
pdfjsLib.GlobalWorkerOptions.workerSrc = `/libs/pdf.worker.min.js`;
const _replaceDrawLayoutQueue = createTaskQueue();
const PDFViewer = React.memo(({ index, documentPage, isWidthFit, scale, fullCanvas, canvasPos, setDrawCanvas }) => {
    const pdfElement = useRef(null);
    const [load, setLoad] = useState(true);
    const [pdf, setPdf] = useState();
    const [lastDrawImg, seLastImg] = useState({ style: { width: 0, height: 0 }, canvas: undefined });
    const loadDocument = () => {
        return new Promise(async (resolve, reject) => {
            const drawList = APP.canvasList.get(documentPage);
            if (drawList && drawList.pdf) {
                resolve(drawList.pdf);
            }
            else {
                try {
                    const loadingTask = await pdfjsLib.getDocument({
                        url: `/docconv/get/${documentPage}`,
                        cMapUrl: '/cmaps/',
                        cMapPacked: true
                    });
                    const newPdf = await loadingTask.promise;
                    APP.canvasList.set(documentPage, { pdf: newPdf });
                    resolve(newPdf);
                }
                catch (err) {
                    reject(err);
                }
            }
        });
    };
    useEffect(() => {
        loadDocument()
            .then(pdf => {
            setPdf(pdf);
        })
            .catch(err => {
            console.log(err);
        });
        return () => {
            setPdf(undefined);
            _replaceDrawLayoutQueue.clear();
        };
    }, [documentPage]);
    useEffect(() => {
        if (!pdf)
            return;
        _replaceDrawLayoutQueue.enqueue(async (onFinish) => {
            setLoad(true);
            await drawImage(pdf, documentPage, index);
            setLoad(false);
            onFinish();
        });
    }, [pdf, index]);
    useEffect(() => {
        if (!pdf)
            return;
        if (!load) {
            lastDrawImg.canvas && drawPdfCanvas(lastDrawImg.style, lastDrawImg.canvas);
        }
        //
    }, [fullCanvas, scale, canvasPos, isWidthFit]);
    const drawImage = (pdf, page, selectNum) => {
        return new Promise((resolve, reject) => {
            const drawList = APP.canvasList.get(page);
            if (drawList && drawList[selectNum]) {
                const item = drawList[selectNum];
                drawPdfCanvas(item.style, item.canvas);
                resolve(true);
            }
            else {
                try {
                    return pdf.getPage(selectNum + 1)
                        .then(image => {
                        const imageStyle = { width: image.view[2], height: image.view[3] };
                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d', { alpaha: false, willReadFrequently: true });
                        const view = (imageStyle.width * imageStyle.height > 16777216) ? 2 : 3;
                        canvas.width = imageStyle.width * view;
                        canvas.height = imageStyle.height * view;
                        const viewport = image.getViewport({ scale: view });
                        const renderTask = image.render({ canvasContext: context, viewport });
                        return renderTask.promise.then(() => {
                            APP.canvasList.set(documentPage, { ...drawList, [selectNum]: { style: imageStyle, canvas } });
                            drawPdfCanvas(imageStyle, canvas);
                            resolve(true);
                        });
                    });
                }
                catch (err) {
                    reject(err);
                }
            }
        });
    };
    /**
     * 캔버스 파일에 그리기
     * @param {object} imageStyle
     * @param {*} canvas
     * @returns
     */
    const drawPdfCanvas = (imageStyle, canvas) => {
        if (pdfElement.current) {
            const context = pdfElement.current.getContext('2d');
            if (context) {
                const dpr = window.devicePixelRatio;
                const drawStyle = getFitSize(fullCanvas, imageStyle, isWidthFit);
                pdfElement.current.width = drawStyle.width * scale;
                pdfElement.current.height = drawStyle.height * scale;
                context.fillStyle = "#fff";
                context.fillRect(canvasPos.x, canvasPos.y, drawStyle.width * scale, drawStyle.height * scale);
                context.drawImage(canvas, canvasPos.x, canvasPos.y, drawStyle.width * scale, drawStyle.height * scale);
                context.scale(dpr, dpr);
                context.setTransform(1, 0, 0, 1, 0, 0);
                setDrawCanvas({ width: drawStyle.width, height: drawStyle.height });
                seLastImg({ style: imageStyle, canvas });
            }
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("canvas", { id: "pdf", className: "pdf", ref: pdfElement }),
        load && React.createElement("div", { className: "load_cover nonSelect", style: { background: "rgba(0,0,0, 0.5)" } },
            React.createElement("span", { className: "center", style: { background: 'transparent', fontSize: '30px' } }, "Loading..."))));
});
export default PDFViewer;
