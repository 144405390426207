import React from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "../../dialog";
function OneDepthChangeLayoutButton({ isModal, children }) {
    const { t } = useTranslation();
    if (isModal) {
        return (React.createElement(Dialog, { titleKey: t("dialog.changeGridCount"), width: "350" }, children));
    }
    return children;
}
export default OneDepthChangeLayoutButton;
