import styled from "styled-components";
export const CanvasIconStyled = styled.div `
    display: flex;
    flex-direction: column;
    gap: 4px;
    cursor: pointer;
    width: 43px;
    position: relative;
    justify-content: center;
`;
export const CanvasLayoutStyled = styled.div `
    background-color: ${props => props.isVod ? "transparent" : "#f7f7f7"};
    overflow: hidden;
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    flex-direction: column;

    .white { 
        background: #fff;
    }

    .pdf {
        position: absolute;
    }

    .canvas_input {
        position: absolute;

        textarea {
            padding: 5px;
            box-sizing: border-box;
            overflow: auto;
            resize: none;
            transform-origin: left top;
            border: none;
            z-index: 2;
        }

      
    }

    .noDocument {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #808080;
        font-size: 16px;
        flex-direction: column;
        gap: 24px;
        
        .documet_img {
            width: 32px;
            height: 35px;
            background-position: -641px -143px;
            transform: scale(1.5);
        }
    }


    iframe {
        margin: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: none;
    }
`;
export const CanvasControlBar = styled.div `
    position: absolute;
    color: #000;
    background: #e6e6e6;

    .toggle_btn {
        padding: 4px!important;
        box-sizing: border-box;
    }

    ${props => props.open ? `
        max-height: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        padding: 8px;
        box-sizing: border-box; 
       
        top: 6px;
        z-index: 1;
        width: fit-content;
        border-radius: 12px;
        left: 5px;
        ` : `
            left: 0;
            top: 0;
            border-radius: 0;
            width: 37px;
            height: 37px;
        `}
    

    > * { margin: 0; }

    .select_box select {
        border: 1px solid #f2f2f2;
        padding: 4px;
        box-sizing: border-box;
        border-radius: 5px;
    }

    .canvas_toggle_bar {
        transform: rotate(-90deg);
        display: flex;
    }

    .paging {
        padding: 4px;
        box-sizing: border-box;
        background: #d2d2d2d2;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
    }
`;
