import React, { useEffect, useMemo, useRef, useState } from "react";
import Player from "../../../../modules/server/mate/player";
import DashPlayer from "./DashPlayer";
import { getVodURLBase } from "../functions";
let player;
const VodPlayer = React.memo(({ fullCanvas, urlList, scale, canvasPos, isWidthFit, isComplete, streaming_id = '', streaming_auth = '', setDrawCanvas }) => {
    const containerRef = useRef(null);
    const [playing, setPlaying] = useState(false);
    const [seek, setSeek] = useState(0);
    const [url, setUrl] = useState();
    const [result, setResult] = useState();
    const hasPermission = useMemo(() => streaming_auth !== '' ? true : false, [streaming_auth]);
    useEffect(() => {
        return () => {
            if (player) {
                player.leave();
            }
        };
    }, []);
    useEffect(() => {
        if (isComplete !== 'complete')
            return;
        setResult(undefined);
        const handler = {
            setUrl: (mediaId, time) => {
                mediaId && setUrl(getVodURLBase(urlList) + `/api/media/v1/medias/${mediaId}/manifest.mpd`);
            },
            setPlaying: (position) => {
                setSeek(position);
                setPlaying(true);
            },
            setPause: (at) => {
                setPlaying(false);
            }
        };
        try {
            player = new Player(urlList, handler);
            const container = containerRef.current;
            if (container) {
                try {
                    player.connectionAndJoin(streaming_id, container)
                        .then(result => {
                        setResult({ ...result, player });
                        if (result.status === "running") {
                            setPlaying(true);
                        }
                        else {
                            setPlaying(false);
                        }
                    });
                }
                catch (err) {
                    console.log(err);
                }
            }
        }
        catch (err) {
            console.log(err);
        }
        return () => {
            if (player) {
                player.leave();
            }
        };
    }, [isComplete]);
    /**
     * 영상을 재생한 경우
     * @param playTime
     * @returns
     */
    const handlePlaying = (playTime = null) => {
        if (!hasPermission)
            return;
        if (playTime === null)
            return;
        return new Promise(async (resolve, reject) => {
            setPlaying(true);
            if (!player)
                reject('no player');
            else {
                const response = await player.requestPlay(streaming_auth, playTime);
                if (response.status === 200)
                    resolve(true);
                else
                    reject('error');
            }
        });
    };
    /**
     * 영상 중단.
     * @returns
     */
    const handlePause = () => {
        if (!hasPermission)
            return;
        if (!player)
            return;
        player.requestPause(streaming_auth);
        setPlaying(false);
    };
    return (React.createElement("div", { ref: containerRef, style: { width: `${fullCanvas.width}px`, height: `${fullCanvas.height + 55}px`,
            position: "absolute", top: 0 } },
        React.createElement(DashPlayer, { url: url, hasPermission: hasPermission, playing: playing, seek: seek, canvasPos: canvasPos, isWidthFit: isWidthFit, result: result, fullCanvas: fullCanvas, scale: scale, handlePause: handlePause, handlePlaying: handlePlaying, setDrawCanvas: setDrawCanvas })));
});
export default VodPlayer;
