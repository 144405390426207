import React, { useEffect, useMemo, useState } from "react";
import { bytesToSize } from "../chat/functions";
import { Dialog } from "../dialog";
import { BottomContainer, ClickButton } from "../ui";
import VodSearchBar from "./VodSearchBar";
import { VodItem, VodList } from "./components";
import { deleteVod, getVodList } from "./functions";
const SHOW_DEFAULT_COUNT = 50;
const VodListContainer = React.memo(({ select_vod_list, show_list = ['no', 'title', 'size', 'etc'], disableHeader = true, isDialog = false, isFilter = false }) => {
    const vodListRef = React.createRef();
    const [list, setList] = useState([]);
    const [search, setSearch] = useState();
    const [totalCount, setTotalCount] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const maxPage = useMemo(() => Math.round(list.length / SHOW_DEFAULT_COUNT), [list]);
    const testList = useMemo(() => isFilter ? list.filter(i => i.status === 'created' && i.media.is_playable_on_chrome) : list.filter(i => i.status === 'created'), [isFilter, list]);
    useEffect(() => {
        if (list.length < totalCount && vodListRef.current) {
            const hasScroll = vodListRef.current.scrollHeight > vodListRef.current.clientHeight;
            if (!hasScroll) {
                setPageIndex((prevPage) => prevPage + 1);
            }
        }
    }, [testList.length]);
    useEffect(() => {
        initList();
    }, [search]);
    /**
     * VOD 초기 설정
     * @param data
     */
    const handlerSuccess = (data) => {
        const { total_count, items } = data;
        setTotalCount(total_count);
        setList(items || []);
    };
    /**
     * VOD 페이징 시 데이터 병합
     * @param data
     */
    const updateItems = (data) => {
        const { items } = data;
        if (!items)
            return;
        setList((prevList) => {
            const idMap = new Map();
            prevList.map((prevItem) => {
                idMap.set(prevItem.id, prevItem);
            });
            items && items.map((newItem) => {
                if (idMap.has(newItem.id)) {
                    const objA = idMap.get(newItem.id);
                    idMap.set(newItem.id, { ...objA, ...newItem });
                }
                else {
                    idMap.set(newItem.id, newItem);
                }
            });
            return Array.from([...idMap.values()].sort((a, b) => new Date(b.creation_time).getTime() - new Date(a.creation_time).getTime())) || [];
        });
    };
    /**
     * VOD 등록 시 하나의 vod 데이터 업데이트
     * @param data
     */
    const updateItem = (data) => {
        setTotalCount((prevTotal) => prevTotal + 1);
        getVodList({ name: search, id: data.album_id, owner_id: 'self', status: ['created', 'deleted'], offset: pageIndex * SHOW_DEFAULT_COUNT, limit: SHOW_DEFAULT_COUNT }, updateItems, handlerError);
    };
    /**
     * 아이템 삭제
     * @param album_id
     */
    const deleteItem = (album_id) => {
        setTotalCount((prevTotal) => prevTotal - 1);
        setList((prevList) => {
            const updatedList = prevList.map((item) => {
                if (item.id === album_id) {
                    return { ...item, status: 'deleted' };
                }
                return item;
            });
            return updatedList;
        });
    };
    /**
     * VOD 등록 에러 표시
     * @param err
     * @returns
     */
    const handlerError = (err) => APP.UI.alertMessage(err);
    /**
     * 초기 값 가져오기
     */
    const initList = () => {
        getVodList({ name: search, owner_id: 'self', status: ['created', 'deleted'], offset: pageIndex * SHOW_DEFAULT_COUNT, limit: SHOW_DEFAULT_COUNT }, handlerSuccess, handlerError);
    };
    useEffect(() => {
        APP.eventManager.subscribe('updateVodList', updateItem);
        APP.eventManager.subscribe('deleteVodList', deleteItem);
        return () => {
            APP.eventManager.unsubscribe('updateVodList', updateItem);
            APP.eventManager.subscribe('deleteVodList', deleteItem);
        };
    }, []);
    useEffect(() => {
        (pageIndex !== 0) && getVodList({ name: search, owner_id: 'self', status: ['created', 'deleted'], offset: pageIndex * SHOW_DEFAULT_COUNT, limit: SHOW_DEFAULT_COUNT }, updateItems, handlerError);
    }, [pageIndex]);
    const updateData = () => {
        setPageIndex((prevPage) => {
            if (maxPage >= (prevPage + 1))
                return prevPage + 1;
            return prevPage;
        });
    };
    const renderSearchBar = useMemo(() => {
        return (React.createElement(VodSearchBar, { setSearch: setSearch }));
    }, []);
    const renderVodList = useMemo(() => {
        return (React.createElement(VodList, { ref: vodListRef, show_list: show_list, disableHeader: disableHeader },
            testList && testList.map((item, index) => {
                const initCheck = select_vod_list && select_vod_list.find(i => i.streaming_id === item.media_id) ? true : false || false;
                return (React.createElement(VodItemContainer, { key: item.media_id, show_list: show_list, initCheck: initCheck, no: index + 1, item: item }));
            }),
            list.length < totalCount && React.createElement(BottomContainer, { updateData: updateData })));
    }, [updateData, show_list, disableHeader, select_vod_list, testList, totalCount, list.length]);
    if (!show_list)
        return null;
    if (isDialog) {
        return (React.createElement(Dialog, { className: 'vod_dialog' },
            renderSearchBar,
            renderVodList));
    }
    return (React.createElement(React.Fragment, null,
        renderSearchBar,
        renderVodList));
});
export default VodListContainer;
const VodItemContainer = React.memo(({ item, no, show_list, initCheck }) => {
    const renderBtn = (icon) => {
        const onClick = () => {
            switch (icon) {
                case 'vodDown':
                    if (navigator.product === 'ReactNative') {
                    }
                    else {
                        fetch(item.media.url)
                            .then(response => response.blob())
                            .then(blob => {
                            const link = document.createElement("a");
                            link.href = URL.createObjectURL(blob);
                            link.download = item.name;
                            link.click();
                        })
                            .catch(console.error);
                    }
                    return;
                case 'vodShow':
                    APP.eventManager.publish('toggleRConfigurationDialog', { type: 'openVodPlayer', props: { playerUrl: item.media.url } });
                    return;
                case 'delete':
                    const handler = {
                        success: () => {
                            // APP.UI.alertMessage('성공적으로 삭제되었습니다.');
                            APP.eventManager.publish('deleteVodList', item.id);
                        }, failed: (err) => {
                            APP.UI.alertMessage(err);
                        }
                    };
                    deleteVod(item.id, undefined, handler);
                    return;
            }
        };
        return (React.createElement(ClickButton, { className: 'etc_icon', icon: icon, color: "#000", size: 20, onClick: onClick }));
    };
    const size = useMemo(() => bytesToSize(item.media.size), [item.media.size]);
    return (React.createElement(VodItem, { key: item.media_id, initCheck: initCheck, show_list: show_list, album_id: item.id, streaming_id: item.media_id, is_playable_on_chrome: item.media.is_playable_on_chrome, no: no, name: item.name, size: size, renderBtn: renderBtn }));
});
