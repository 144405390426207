import { createGlobalStyle, css } from "styled-components";
import { colorBox } from "../../../../../css/theme";
export const InitStyled = createGlobalStyle `
    .buttons {
        display: inline-flex;
        gap: 12px;
    }

    .overText {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .nonSelect {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .room_label_icon {
        &:not(.no-color) {
            ${() => colorBox(APP.config.style.iconButton)};
        }

        &.isFocus, &-on, &:hover:not(.no-hover) {
            ${() => colorBox(APP.config.style.iconHoverButton)};
        }
    }

    .option_item {
        ${() => colorBox(APP.config.style.optionButton)};
        &:hover:not(.no-hover), &.isFocus {
            ${() => colorBox(APP.config.style.iconHoverButton)};
        }
    }

    .switch {
        position: relative;
        display: inline-block;
        margin-right: 10px;
        flex: none;

        input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked + .slider {
                background-color: ${() => APP.config.style.focusColor};
            }

            &:focus + .slider {
                box-shadow: 0 0 1px #2196F3;
            }

            
        }
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;
        color: #fff;

        &:before {
            position: absolute;
            content: "";

            background-color: white;
            transition: .4s;
            border-radius: 50%;
        }
        
        .round {
            border-radius: 34px;

            &:before {
                border-radius: 50%;
            }
        }

        .text {
            text-align: center;
            width: 100%;
            height: 100%;
            line-height: 20px;
        }
    }

    &.canvas { 
        ${() => APP.config.name === 'class' ? css `
            svg {
                width: 44px;
                height: 36px;
            }
        ` : css `
            width: 43px;
            height: 43px;
            display: flex;
            justify-content: center;
            align-items: center;
        `}

        &:not(.no-color) {
            ${() => colorBox(APP.config.style.canvasButton)};
            
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px auto;
        }       

        &:hover:not(.no-hover), &-on {
            ${() => colorBox(APP.config.style.canvasHoverButton)};
            border-radius: 4px;
        }
    }

    .click_button {
        &:not(.room_label_icon)
        :not(.canvas)
        :not(.enter-icon)
        :not(.no-color)
        :not(.sub_accout)
        :not(.text-button) {
            ${() => colorBox(APP.config.style.iconButton)};   
            
            &.isFocus, &-on, &:hover:not(.no-hover) {
                ${() => colorBox(APP.config.style.iconHoverButton)};
    
                svg {
                    -webkit-transition: all 0.2s; /* Safari */
                    transition: all 0.2s;
                }
            }
        }        

        &:not(.no-hover) {
            cursor: pointer;
        }       

        &.enter-icon {
            svg {
                fill: #27DBE0;
                stroke: #fff;
            }

            &:hover, &-on {
                svg {
                    fill: #29abe3;
                    stroke: #fff;
                }
            }
        }

        &.media-button {
            ${() => colorBox(APP.config.style.optionButton)};
            display: inline-flex;
            gap: 5px;
            align-items: center;
            margin: 0;

            &:hover:not(&.no-hover), &.isFocus {
                ${() => colorBox(APP.config.style.optionHoverButton)};
            }
        }

        &.text-button {
            ${() => colorBox(APP.config.style.textButton)};
            text-align: center;
            display: inline-flex;
            padding: 8px 20px;
            border-radius: 4px;
            justify-content: center;
            align-items: center;
            -webkit-transition: all 0.3s; /* Safari */
            transition: all 0.3s;

            &.canvas {
                margin: auto 10px auto auto;
            }

            &.page_button {
                margin: 0;
            }

            &:hover:not(&.disable, &.no-hover),  &.isFocus {
                ${() => colorBox(APP.config.style.textHoverButton)};
            }
        }
    }
`;
