import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { setConfig } from "./config/test";
import "./css/all.scss";
import "./css/reset.scss";
import { AppContainer } from "./features/app";
import "./features/app/middlewares.web";
import "./features/app/reducers.web";
import { API } from "./modules/API";
import { default as UI } from "./modules/UI/UIHelper.web";
import { default as Management } from "./modules/server/index.js";
import EventManager from "./modules/server/EventManager";
// eslint-disable-next-line @typescript-eslint/no-var-requires, no-undef
const { config } = require('/libs/' + setConfig() + 'Config');

const management =  new Management();
// 이벤트 매니저 인스턴스 생성
const eventManager = new EventManager();


// eslint-disable-next-line no-undef
const window = global;
window.APP = {
    API,
    config,
    UI,
    management,
    loadTime: 0,
    canvasList: new Map(),
    eventManager
};

// eslint-disable-next-line no-undef
const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);

root.render(<AppContainer />);
