import { toState } from "../redux";
/**
 * local reducer 가져오기,
 * @param {IStore['getState']} stateful
 * @returns
 */
const localState = (stateful) => {
    const state = toState(stateful);
    return state['features/base/local'];
};
/**
 * 로그인 여부 가져오기
 * @param {IStore['getState']} stateful
 * @returns
 */
export function isLogin(stateful) {
    const auth_name = getLocalAuthName(stateful);
    return Boolean(auth_name);
}
/**
 * 현재 방 개설 권한 여부 가져오기
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getLocalPermission(stateful) {
    const user = localState(stateful)?.user;
    return user?.role?.permissions;
}
/**
 * 현재 로그인된 사용자 아이디
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getLocalAuthName(stateful) {
    const user = localState(stateful)?.user;
    return user?.auth_name;
}
/**
 * 현재 로그인 된 사용자 user_id
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getLocalID(stateful) {
    const user = localState(stateful)?.user;
    return !user?.uuid || user?.uuid === '' ? user?.user_id : user.uuid;
}
/**
 * 현재 로그인 된 사용자 토큰
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getAccessToken(stateful) {
    const token = localState(stateful)?.token;
    return token?.access_token;
}
export function getSearchData(key) {
    // 현재 페이지의 URL을 가져옵니다.
    const currentURL = window.location.search;
    if (currentURL.includes(key)) {
        return currentURL.split(`${key}=`)[1].split('&')[0];
    }
    else {
        return null;
    }
}
