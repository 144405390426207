import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dialog, hideDialog } from "../base/dialog";
import { loginById } from "../base/local";
import { ClickButton, Input } from "../base/ui";
import { Login } from "./components";

function LoginContainer({
    isDialog
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [ id, setId ] = useState();
    const [ pwd, setPwd ] = useState();
    const [ error, setError ] = useState(); 

    /**
     * (ACTION) 로그인 
     */
    const onLogin = async () => {
        const response = await dispatch(loginById(id, pwd));
        if (response.complete) {
            dispatch(hideDialog());
        } else {
            setError(response.data);
        }
    } 
    
    /**
     * enter 클릭시 로그인 제출 
     * @param {Object} e 
     */
    const onKeyPress = e => {
        if (e.key === 'Space' || e.key === 'Enter' || e.key === 'NumpadEnter') {
            e.preventDefault();

            onLogin();
        }
    }

    /**
     * get Input ID components
     * @returns 
     */
    const renderId = (iconStyles) => {
        return (
            <Input type="text" 
                className="account-item"
                placeholder={ t("account.checkId") }
                label={ t("account.id") }
                customStyles={iconStyles}
                onKeyPress={onKeyPress}
                onChange={setId} />
        )
    };

    /**
     * get Input PWD components
     * @returns 
     */
    const renderPwd = (iconStyles) => {
        return (
            <Input type="password" 
                className="account-item"
                placeholder={ t("account.checkPwd") }
                label={ t("account.password") }
                secureTextEntry={true}
                customStyles={iconStyles}
                onKeyPress={onKeyPress}
                onChange={setPwd} />
        )
    }

    /**
     * get 로그인 버튼 components
     * @returns 
     */
    const renderLoginButton = () => {
        return (
            <ClickButton
                isText={true}
                label={ t("account.goLogin") } 
                onClick={onLogin} />
        )
    }

    const getLoginComponents = () => {
        return (
            <Login t={t} error={error}
                renderId={renderId} renderPwd={renderPwd} 
                renderLoginButton={renderLoginButton}
            />
        )
    }

    const content = getLoginComponents();
    if (isDialog) {
        return (
            <Dialog titleKey={t("account.goLogin")} width={350} >
                { content }
            </Dialog>
        )
    } else {
        return content;
    }
}

export default React.memo(LoginContainer);