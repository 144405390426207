import React from 'react';
import ClickButton from './ClickButton.web';
import Input from './Input.web';
import { useTranslation } from 'react-i18next';
const PreviewInputBox = React.memo(({ name, handler }) => {
    const { t } = useTranslation();
    const [state, setState] = React.useState({ value: '' });
    const _handlerRoom = () => {
        const { value } = state;
        if (!value || value.trim() === '') {
            alert(t('error.requestInput'));
            return;
        }
        handler(value);
    };
    const _onChange = (value) => {
        setState({
            value
        });
    };
    const _onKeyPress = e => {
        if (e.key === 'Space' || e.key === 'Enter' || e.key === 'NumpadEnter') {
            e.preventDefault();
            _handlerRoom();
        }
    };
    return (React.createElement("div", { className: 'item', key: name },
        React.createElement("h2", null,
            " ",
            t(`lobby.${name}`),
            " "),
        React.createElement("div", { style: { position: "relative" } },
            React.createElement(Input, { id: name, type: "text", placeholder: t(`lobby.${name}Placeholder`), onKeyPress: _onKeyPress, onChange: _onChange }),
            React.createElement(ClickButton, { className: 'enter-icon', icon: "enter", size: 30, onClick: _handlerRoom }))));
});
export default PreviewInputBox;
