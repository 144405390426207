/**
 * 파일 저장
 * @param data
 * @param isRemote
 * @returns
 */
export const ADD_FILE = 'ADD_FILE';
/**
 * 메시지 저장
 * @param data
 * @param isRemote
 * @returns
 */
export const ADD_MESSAGE = 'ADD_MESSAGE';
/**
 * 채팅 전송할 사용자 설정
 * @param members
 * @returns
 */
export const SET_CHOICE_MEMBER = 'SET_CHOICE_MEMBER';
/**
 * 채팅 read
 */
export const SET_IS_CHAT_READ = 'SET_IS_CHAT_READ';
