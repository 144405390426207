import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toggleLoad } from "../loading";
import { ClickButton, Input } from "../ui";
import { ReservationRoomForm } from "./components";
import { RESERVATION_PAGE_TYPE } from "./constants";
import { validateEmail } from "./functions";
import { reservationRoom, updateReservationRoom } from "./components/ResevationAction";
const ReservationFormContainer = React.memo(({ modifyItem, page, setPage }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const emailRef = useRef();
    const [now, setNow] = useState();
    const [title, setTitle] = useState('');
    const [password, setPassword] = useState({ isOn: true, pwd: undefined });
    const [startDate, setStartDate] = useState();
    const [duration, setDuration] = useState(60);
    const [entryTime, setEntryTime] = useState(5);
    const [email, setEmail] = useState('');
    const [list, setList] = useState([]);
    const [vodList, setVodList] = useState([]);
    /**
     * VOD 리스트 업데이트
     * @param data
     */
    const handlerVodList = (data) => {
        const { album_id, streaming_id, name, isChecked } = data;
        if (isChecked) {
            setVodList((prevVod) => {
                prevVod.push({ album_id, streaming_id, name });
                return [...prevVod];
            });
        }
        else {
            setVodList((prevVod) => {
                return prevVod.filter(p => p.streaming_id !== streaming_id);
            });
        }
    };
    /**
     * 초기값 설정
     */
    useEffect(() => {
        APP.eventManager.subscribe('updateVodCheckList', handlerVodList);
        if (!modifyItem) {
            // 저장인 경우 
            const now = moment().add(1, 'minutes');
            setNow(now);
            setStartDate(now);
        }
        else {
            // 수정인 경우
            setTitle(modifyItem.name);
            setPassword({ isOn: !modifyItem.password_checking, pwd: modifyItem.password });
            setDuration(modifyItem.progress_duration / 60000);
            setEntryTime(modifyItem.pre_entering_duration / 60000);
            modifyItem.shared_vod_list.lobby && setVodList(modifyItem.shared_vod_list.lobby);
            if (modifyItem.invitations) {
                let email_list = [];
                modifyItem.invitations.map(item => email_list.push({ email: item.email, isValidate: true, exist: true }));
                setList(email_list);
            }
            const start = moment(modifyItem.start_time);
            setStartDate(start);
        }
        return () => {
            APP.eventManager.unsubscribe('updateVodCheckList', handlerVodList);
        };
    }, []);
    /**
     * 로딩 페이지
     */
    const toggleLoading = (isLoading) => {
        dispatch(toggleLoad(isLoading));
    };
    /**
     * 예약 페이지로 이동
     */
    const handlerSetReservePage = () => {
        setPage(RESERVATION_PAGE_TYPE.RESERVE_ROOM);
        toggleLoading(false);
    };
    /**
     * (action) 예약 회의 등록
     *  회의실 예약 or 회의실 상태 업데이트
     */
    const handlerRegistRoom = () => {
        if (!title || title.trim() === '') {
            APP.UI.alertMessage(t("reservation.requiredRoom"));
            return;
        }
        if (!startDate) {
            APP.UI.alertMessage(t("reservation.errorTimeOver"));
            return;
        }
        let start_time = now;
        if (startDate.isBefore(moment())) {
            APP.UI.alertMessage(t("reservation.errorTimeOver"));
            return;
        }
        if (!password.isOn) {
            if (!password.pwd || password.pwd.trim() === '') {
                APP.UI.alertMessage(t("reservation.requiredPwd"));
                return;
            }
        }
        const data = {
            name: title,
            password: password.isOn ? null : password.pwd,
            entry_option: null,
            progress_duration: duration * 60000,
            start_time: startDate.toISOString() || start_time,
            pre_entering_duration: entryTime * 60000
        };
        const command = {
            reservation_data: data,
            email_list: list,
            vod_list: vodList,
            reservation_id: modifyItem ? modifyItem.uuid : undefined
        };
        if (modifyItem) {
            // 회의실 상태 업데이트
            updateReservationRoom(command, handlerSetReservePage, toggleLoading);
        }
        else {
            // 회의실 예약
            reservationRoom(command, handlerSetReservePage, toggleLoading);
        }
    };
    /**
     *
     * @param styles
     * @returns
     */
    const renderBottomBtn = (styles) => {
        return (React.createElement(React.Fragment, null,
            React.createElement(ClickButton, { className: 'r_foot text-button', isText: true, label: t('reservation.previous'), styles: styles, onClick: handlerSetReservePage }),
            React.createElement(ClickButton, { className: 'r_foot text-button', isText: true, label: t('dialog.regist'), styles: styles, onClick: handlerRegistRoom })));
    };
    const renderItem = (name, component, styles) => {
        switch (name) {
            case 'title':
                return React.createElement(Input, { className: "i_title", type: "text", customStyles: styles, value: title, onChange: setTitle });
            case 'duration':
                return React.createElement(Input, { className: "i_duration", type: "text", customStyles: styles, value: duration, onChange: setDuration });
            case 'entryTime':
                return React.createElement(Input, { className: "i_entry", type: "text", customStyles: styles, value: entryTime, onChange: setEntryTime });
            case 'password':
                const unCheckedPwd = () => {
                    setPassword({ ...password, isOn: false });
                };
                const checkedPwd = () => setPassword({ ...password, isOn: true });
                const onPassword = (value) => setPassword({ isOn: value && value.trim() !== '' ? false : true, pwd: value });
                const renderInputPwd = (style) => {
                    if (password.isOn === false) {
                        return (React.createElement(Input, { className: "i_pwd", type: "text", value: password.pwd, customStyles: style, onChange: onPassword }));
                    }
                };
                return component(!password.isOn, { checkedPwd, unCheckedPwd }, renderInputPwd);
            case 'startDate':
                const onChangeDate = date => {
                    setStartDate(moment(date));
                };
                const time = startDate || now;
                return time && time.format ? component(time.format('YYYY-MM-DD HH:mm'), onChangeDate) : null;
            case 'roomType':
                return null;
            // if (!modifyItem || !modifyItem.type) return null;
            // return component(modifyItem.type);
            case 'inviteMail':
                /**
                 * Enter 입력시 이메일 push
                 */
                const _onKeyPress = e => {
                    if (e.key === 'Space' || e.key === 'Enter' || e.key === 'NumpadEnter') {
                        e.preventDefault();
                        appendInviteList();
                    }
                };
                /**
                 * 이메일 추가
                 */
                const appendInviteList = () => {
                    if (!email || email.trim() === "")
                        return;
                    setList((list) => {
                        if (list.find(item => item.email === email))
                            return list;
                        list.push({ email, isValidate: validateEmail(email), exist: false });
                        return list;
                    });
                    setEmail("");
                    if (emailRef.current)
                        emailRef.current.value = "";
                };
                /**
                 * 이메일 삭제
                 * @param {String} email
                 * @param {Boolean} exist
                 * @returns
                 */
                const removeInviteList = (email, exist) => {
                    if (exist)
                        return;
                    setList((list) => {
                        const index = list.findIndex(item => item.email === email);
                        if (index !== -1) {
                            list.splice(index, 1);
                        }
                        return [...list];
                    });
                };
                const renderEmailInput = (styles) => {
                    return (React.createElement(React.Fragment, null,
                        React.createElement(Input, { ref: emailRef, className: "i_mail", type: "email", value: email, customStyles: styles, onKeyPress: _onKeyPress, onChange: setEmail }),
                        React.createElement(ClickButton, { isText: true, label: t('reservation.invite'), onClick: appendInviteList })));
                };
                return component(list, { removeInviteList }, renderEmailInput);
            case 'registVod':
                const renderVodList = () => {
                    const openVodList = () => {
                        APP.eventManager.publish('toggleRConfigurationDialog', {
                            type: 'openVodList', props: { select_vod_list: vodList }
                        });
                    };
                    return (React.createElement(ClickButton, { isText: true, label: t('reservation.vod_list'), onClick: openVodList }));
                };
                const cancelVodList = (album_id, streaming_id, name) => {
                    handlerVodList({ album_id, streaming_id, name, isChecked: false });
                };
                return component(renderVodList, vodList, cancelVodList);
        }
    };
    return (React.createElement(ReservationRoomForm, { renderItem: renderItem, renderBottomBtn: renderBottomBtn }));
});
export default ReservationFormContainer;
