import styled from "styled-components";

export const SettingStyled = styled.div`
    display: flex;
    width: 100%;
    margin-top: 12px;
    flex-direction: ${props => props.isMobile ? 'column' : 'row'};

    input[type="number"] {
        border: 1px solid #d5d5d5;
        border-radius: 8px;
        padding: 8px;
        box-sizing: border-box;
        text-align: center;
        width: 80%;
        margin-right: 12px;
    }

    .guid {
        white-space: pre-wrap;
        word-break: keep-all;
        text-align: center;
        line-height: 25px;
        margin: 4px 0 20px;
    }

    .grid_count_input {
        margin: 0;
    }

    > * {
        margin: 0;
    }
    
    .tab {
        display: flex;
        flex-direction: column;
        border-right: 2px solid;

        .click_button {
            margin: 0;
            background: #fff;
            color: #555;
            border-bottom: 1px solid;
            border-radius: 0;
        }
    }

    .content {
        flex: 1;
        min-height: 100%;
        padding: 8px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;

        .switch {
            height: 20px;
            width: 160px;
            margin: 0;

            input:checked + .slider:before {
                transform: translateX(140px);
            }

            .slider:before {
                width: 20px;
                height: 20px;
                border: 1px solid #d5d5d5;
                box-sizing: border-box;
            }

            .text {
                text-align: center;
            }
        }

        > *:not(.switch):not(.guid):not(h4){
            margin: 0;
        }
    }  

    .count_container {
        width: 100%;
    }

    .screen_table {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;

        &.top {
            border-top: 2px solid #d5d5d5;
        }

        .screen_item {
            display: flex;
            margin:  0;
            padding: 8px;
            box-sizing: border-box;
            justify-content: flex-start;
            align-items: center;

            .screen_title {
                font-weight: bold;
            }
            
            > * {
                width: 50%;
                justify-content: flex-start;
            }
        }

        .screen_radio {
            display: flex;
            gap: 4px;

            label {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;
                cursor: pointer;
            }
        }

        .screen_frame {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > * {
                margin: 0;
            }

            .text-button {
                padding: 4px 8px;
                height: fit-content;
                margin: 0;

                &.disable {
                    background: #d5d5d5;
                    color: #000;
                    font-weight: bold;
                }
            }
        }
    }

    .radio {
        display: flex;
        gap: 12px;
        justify-content: center;

        label {
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
        }
    }

    .label_option  {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .military_time {
        border-top: 1px solid #d5d5d5;
        margin-top: 12px!important;
        padding: 8px;
        width: 100%;
        box-sizing: border-box;
    }

    @media (max-width: 720px) {
        .tab {
            border: 1px solid;
        }
        
    }
`;

export const LabelStyled = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    .label_container {
        display: flex;
        margin: 0;
        
        .item {
            margin: 0;
            flex: 1;
        }

        input {
            display: none;
        }

        label > .click_button {
            display: flex;
            flex-direction: column;
        }
    }
`;


