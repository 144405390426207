import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MEDIA_TYPE, VIDEO_MUTISM_AUTHORITY, setVideoMuted } from "../../media";
import { isLocalTrackMutedByMediaType } from "../../tracks";
import { ClickButton } from "../../ui";
const ToggleVideoButton = React.memo(({ className, label, enableLable, iconStyles }) => {
    const dispatch = useDispatch();
    const videoMuted = useSelector((state) => isLocalTrackMutedByMediaType(state, MEDIA_TYPE.VIDEO));
    const [icon, setIcon] = useState('camera_on');
    const [color, setColor] = useState(APP.config.style.focusColor);
    const _toggleVideoMuted = () => {
        dispatch(setVideoMuted(!videoMuted, MEDIA_TYPE.VIDEO, VIDEO_MUTISM_AUTHORITY.USER, false));
    };
    useEffect(() => {
        setColor(videoMuted ? 'red' : APP.config.style.focusColor);
        setIcon(videoMuted ? 'camera_off' : 'camera_on');
    }, [videoMuted]);
    return (React.createElement(ClickButton, { className: `${className} no-hover`, icon: icon, size: enableLable ? 20 : 24, label: label, color: color, styles: iconStyles, onClick: _toggleVideoMuted }));
});
export default ToggleVideoButton;
