import { throttle } from "lodash";
import { IStore } from "../../app/types";
import { toState } from "../redux";

let joinedMember = [];
let leftedMembers = [];
let unReadChat = [];
let handlerMembers = [];

export function insertMemberConnected(member) {
    joinedMember.push(member);
}

export function leftedMemberConnected(member) {
    leftedMembers.push(member);
}

export function insertChat(member) {
    unReadChat.push(member);
}

export function insertHandlerMembers(member) {
    handlerMembers.push(member);
}

/**
 * 참석자 입장 대기열 
 */
export const _throttledNotifyMemberConnected = throttle((dispatch, showNotification) => {
    const memberCount = joinedMember.length;

    const props = {
        titleKey: 'notify.connetMessage',
        titleArguments: undefined,
        description: undefined,
        save: true
    }; 
    
    if (memberCount >= 3) {
        const standardValue = memberCount > 0 ? joinedMember[0] : null;

        props.descriptionArguments = standardValue 
            ? {name: standardValue?.displayName, count: memberCount} 
            : undefined;

        props.description = !standardValue.existed 
            ? 'notify.prevConnectedThreePlusMembers'
            : 'notify.connectedThreePlusMembers';

    } else if (memberCount === 2) {
        const standardValue = memberCount > 1 ? joinedMember : null;

        props.descriptionArguments = standardValue 
            ? {first: standardValue[0]?.displayName, second: standardValue[1]?.displayName} 
            : undefined;
        props.description = !standardValue?.existed 
            ? 'notify.prevConnectedTwoMembers'
            : 'notify.connectedTwoMembers';
    } else if (memberCount === 1) {
        const standardValue = memberCount > 0 ? joinedMember[0] : null;

        props.descriptionArguments = standardValue ? {name: standardValue?.displayName} : undefined;
        props.description = !standardValue?.existed 
            ? 'notify.prevConnectedOneMember'
            : 'notify.connectedOneMember';
    }

    dispatch(showNotification(props));

    joinedMember = [];
},  1500, { leading: true, trailing: true });

/**
 * 참석자 퇴장 대기열 
 */
export const _throttledNotifyMemberDisconnected = throttle((dispatch, showNotification) => {
    const memberCount = leftedMembers.length;

    const props = {
        titleKey: 'notify.disconnetMessage',
        titleArguments: undefined,
        description: undefined,
        save: true
    }; 
    
    if (memberCount >= 3) {
        const standardValue = memberCount > 0 ? leftedMembers[0] : null;

        props.descriptionArguments = standardValue 
            ? {name: standardValue, count: memberCount} 
            : undefined;

        props.description = 'notify.disconnectedThreePlusMembers';

    } else if (memberCount === 2) {
        const standardValue = memberCount > 1 ? leftedMembers : null;

        props.descriptionArguments = standardValue 
            ? {first: standardValue[0], second: standardValue[1]} 
            : undefined;
        props.description ='notify.disconnectedTwoMembers';
    } else if (memberCount === 1) {
        const standardValue = memberCount > 0 ? leftedMembers[0] : null;
        
        props.descriptionArguments = standardValue ? {name: standardValue} : undefined;
        props.description ='notify.disconnectedOneMember';
    }

    dispatch(showNotification(props));

    leftedMembers = [];
},  1500, { leading: true, trailing: true });

/**
 * 채팅 대기열 
 */
export const _throttledNotifyUnReadMessage = throttle((dispatch, showNotification) => {
    const unReadChatCount = unReadChat.length;

    const props = {
        titleKey: 'notify.chatMessage',
        appearance: 'chat_info',
        titleArguments: undefined,
        description: undefined,
        save: false
    }; 

    if (unReadChatCount === 1) {
        props.description = 'notify.unReadOneChatCount';
        props.descriptionArguments = { message: unReadChat[0].substr(0, 20) };
    } else {
        props.descriptionArguments = { count: unReadChatCount };
        props.description = 'notify.unReadChatCount';
    }

    dispatch(showNotification(props));

    unReadChat = [];
},  1500, { leading: true, trailing: true });

/**
 * 손들기 대기열
 */
export const _throttledNotifyHandler = throttle((dispatch, showNotification) => {
    const memberCount = handlerMembers.length;

     const props = {
        titleKey: 'notify.raiseHandMesssage',
        titleArguments: undefined,
        descriptionArguments: undefined,
        description: undefined,
        save: true
    }; 
    
    if (memberCount >= 3) {
        const standardValue = memberCount > 0 ? handlerMembers[0] : null;

        props.descriptionArguments = standardValue 
            ? {name: standardValue, count: memberCount} 
            : undefined;

        props.description = 'notify.handlerMoreMember';

    } else if (memberCount === 2) {
        const standardValue = memberCount > 1 ? handlerMembers : null;

        props.descriptionArguments = standardValue 
            ? {first: standardValue[0], second: standardValue[1]} 
            : undefined;
        props.description = 'notify.handlerTwoMember';
    } else if (memberCount === 1) {
        const standardValue = memberCount > 0 ? handlerMembers[0] : null;

        props.descriptionArguments = standardValue ? { name: standardValue } : undefined;
        props.description = 'notify.handlerOneMember';
    } else {
        return;
    }

    dispatch(showNotification(props));

    handlerMembers = [];
}, 1500, { leading: true, trailing: true });


/**
 * 알림상태 가져오기 
 *  * @param {IStore['getState']} stateful 
 */
export function getNotificationState(stateful) {
    const state = toState(stateful);

    return state['features/base/notification'];
}

/**
 * 현재 기본 알림 시간 가져오기 
 * @param {IStore['getState']} stateful 
 */
export function getNotificationTime(stateful) {
    return getNotificationState(stateful).time;
}

/**
 * 현재 화면에 보여져야할 리스트 가져오기
 * @param {IStore['getState']} stateful 
 */
export function getNotificationList(stateful) {
    return getNotificationState(stateful)?.show;
}

/**
 * 현재 화면에 보여져야할 리스트 가져오기
 * @param {IStore['getState']} stateful 
 */
export function getNotificationLimit(stateful) {
    return getNotificationState(stateful)?.limit;
}