import React from "react";
import { OnOffSwitch } from "../base/ui";
// @ts-ignore
import { Dialog } from "../base/dialog";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toggleE2EE } from "./action";
const E2EEContainer = React.memo(() => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isOn = useSelector((state) => state['features/room-lock'].e2eeEnable);
    // const [ isOn, setIsOn ] = useState<boolean>(false);
    return (React.createElement(Dialog, { titleKey: t("dialog.e2ee"), width: "350" },
        React.createElement(OnOffSwitch, { className: "e2ee_label label_option", guid: t("dialog.e2eeGuid"), label: isOn ? t("dialog.trueE2EE") : t("dialog.falseE2EE"), isShow: isOn, handlerClick: () => dispatch(toggleE2EE(!isOn)) })));
});
export default E2EEContainer;
