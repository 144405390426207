import React from "react";
import { SHOW_DEFAULT_COUNT } from "../../constants";
import { getFormatterDate } from "../../functions";
export default function ReservationList({ t, list, pageIndex, renderPaging, renderEtc }) {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "table reservation_list center" },
            React.createElement("div", { className: "table_th" }, ['no', 'title', 'time', 'code', 'etc'].map(item => {
                return (React.createElement("div", { key: item, className: item }, t(`reservation.${item}`)));
            })),
            list && list.length > 0 ? list.map((item, index) => {
                return (React.createElement("div", { className: "table_td", key: item.uuid },
                    React.createElement("div", { className: "no" }, pageIndex * SHOW_DEFAULT_COUNT + index + 1),
                    React.createElement("div", { className: "title" }, item.name),
                    React.createElement("div", { className: "time" }, getFormatterDate(item.start_time)),
                    React.createElement("div", { className: "code" }, item.code),
                    React.createElement("div", { className: "etc" }, renderEtc(item))));
            }) : React.createElement("div", { className: "table_full_td" }, t("reservation.noRoom"))),
        React.createElement("div", { className: "paging" }, renderPaging())));
}
