import React, { useMemo, useState } from "react";
import ReservationFormContainer from "./ReservationFormContainer";
import ReservationListContainer from "./ReservationListContainer";
import { RESERVATION_PAGE_TYPE } from "./constants";
const ReservationContainer = React.memo(({ page, setPage }) => {
    const [modifyItem, setModifyItem] = useState();
    const handlerModifyPage = (type, item) => {
        setModifyItem(item);
        setPage(type);
    };
    const renderBody = useMemo(() => {
        let item = undefined;
        switch (page) {
            case RESERVATION_PAGE_TYPE.HELD_ROOM:
            case RESERVATION_PAGE_TYPE.RESERVE_ROOM:
                return React.createElement(ReservationListContainer, { page: page, setPage: setPage, handlerModifyPage: handlerModifyPage });
            case RESERVATION_PAGE_TYPE.MODIFY:
                item = modifyItem;
            case RESERVATION_PAGE_TYPE.REGIST:
                console.log(item);
                return React.createElement(ReservationFormContainer, { page: page, setPage: setPage, modifyItem: item });
        }
    }, [page]);
    return renderBody;
});
export default ReservationContainer;
