import { ReducerRegistry } from "../base/redux";
import { SET_SHARE_SCREEN_ID, SET_SHARE_SCREEN_OPTION } from "./actionTypes";
import { USER_TYPE } from "./constants";
const DEFAULT_STATE = {
    start_share: false,
    share: null,
    isSharingVideo: false,
    share_member: '',
    screen_type: USER_TYPE
};
ReducerRegistry.register('features/screen-share', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SET_SHARE_SCREEN_ID:
            return {
                ...state,
                share: action.jitsi_id,
                share_member: action.member_uuid,
                start_share: action.jitsi_id ? true : false,
                isSharingVideo: action.isLocal ? true : false
            };
        case SET_SHARE_SCREEN_OPTION:
            return {
                ...state,
                screen_type: action.ui === null ? state.screen_type : action.ui
            };
    }
    return state;
});
