import React from "react";
import { LobbyStyled } from "../../../lobby/components/web/styles";

function Nickname({
    children
}) {
    const config = APP.config['nickname'];

    return (
        <LobbyStyled style={{ background: config.background }}>
            <div className="logo" style={{ ...config.logo,  margin: "2% 0 auto 3%" }}></div>
            <div className="preview-container">
                { children }
            </div>
        </LobbyStyled>
    );
}

export default React.memo(Nickname);