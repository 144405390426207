import React, { useMemo } from "react";
import { RESERVATION_PAGE_TYPE } from "../base/reservation/constants";
import { ReservationContainer } from "../base/reservation";
import { VOD_PAGE_TYPE } from "../base/vod/constant";
import { AddVodItem, VodContainer } from "../base/vod";
const RoomConfigurationBodyContainer = React.memo(({ page, setPage, setLoad }) => {
    const addVodHandler = {
        success: (data) => {
            APP.eventManager.publish('updateVodList', data);
            setLoad(false);
            APP.eventManager.publish('toggleRConfigurationDialog', { type: undefined });
        },
        failed: (err) => {
            APP.UI.alertMessage(err);
            setLoad(false);
        },
        setLoad: (load) => {
            setLoad(load);
        }
    };
    const renderBody = useMemo(() => {
        switch (page) {
            case RESERVATION_PAGE_TYPE.HELD_ROOM:
            case RESERVATION_PAGE_TYPE.RESERVE_ROOM:
            case RESERVATION_PAGE_TYPE.MODIFY:
            case RESERVATION_PAGE_TYPE.REGIST:
                return React.createElement(ReservationContainer, { page: page, setPage: setPage });
            case VOD_PAGE_TYPE.VOD_LIST:
                return (React.createElement(React.Fragment, null,
                    React.createElement(AddVodItem, { isRoom: true, handler: addVodHandler }),
                    React.createElement(VodContainer, { page: page, setPage: setPage })));
        }
    }, [page, addVodHandler]);
    return renderBody;
});
export default RoomConfigurationBodyContainer;
