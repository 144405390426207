import { timeout } from '../functions';
// import { showWarningNotification } from '../../notifications/actions';
// import { NOTIFICATION_TIMEOUT_TYPE } from '../../notifications/constants';
import JitsiStreamBackgroundEffect from './JitsiStreamBackgroundEffect';
// @ts-expect-error
import createTFLiteModule from './vendor/tflite/tflite';
// @ts-expect-error
import createTFLiteSIMDModule from './vendor/tflite/tflite-simd';
const models = {
    modelLandscape: 'libs/selfie_segmentation_landscape.tflite'
};
/* eslint-enable lines-around-comment */
let modelBuffer;
let tflite;
let wasmCheck;
let isWasmDisabled = false;
const segmentationDimensions = {
    modelLandscape: {
        height: 144,
        width: 256
    }
};
/**
 * JitsiStreamBackgroundEffect의 새 인스턴스를 만듭니다. 이렇게 하면 개인 세분화를 추출하는 데 사용되는 Meet 배경 모델이 로드됩니다.
 *
 * @param {Object} virtualBackground - The virtual object that contains the background image source and
 * the isVirtualBackground flag that indicates if virtual image is activated.
 * @param {Function} dispatch - The Redux dispatch function.
 * @returns {Promise<JitsiStreamBackgroundEffect>}
 */
export async function createVirtualBackgroundEffect(virtualBackground, dispatch) {
    if (!MediaStreamTrack.prototype.getSettings && !MediaStreamTrack.prototype.getConstraints) {
        throw new Error('JitsiStreamBackgroundEffect not supported!');
    }
    if (isWasmDisabled) {
        // dispatch?.(showWarningNotification({
        //     titleKey: 'virtualBackground.backgroundEffectError'
        // }, NOTIFICATION_TIMEOUT_TYPE.LONG));
        return;
    }
    // WebAssembly 기능이 브라우저에서 지원되거나 활성화되었는지 확인합니다.
    // 사용자가 앱을 열 때 브라우저 충돌을 방지하기 위해 wasm-check 패키지의 조건부 가져오기가 수행됩니다.
    if (!tflite) {
        try {
            wasmCheck = require('wasm-check');
            const tfliteTimeout = 10000;
            if (wasmCheck?.feature?.simd) {
                tflite = await timeout(tfliteTimeout, createTFLiteSIMDModule());
            }
            else {
                tflite = await timeout(tfliteTimeout, createTFLiteModule());
            }
        }
        catch (err) {
            if (err?.message === '408') {
                console.error('Failed to download tflite model!');
                // dispatch?.(showWarningNotification({
                //     titleKey: 'virtualBackground.backgroundEffectError'
                // }, NOTIFICATION_TIMEOUT_TYPE.LONG));
            }
            else {
                isWasmDisabled = true;
                console.error('Looks like WebAssembly is disabled or not supported on this browser', err);
                // dispatch?.(showWarningNotification({
                //     titleKey: 'virtualBackground.webAssemblyWarning',
                //     descriptionKey: 'virtualBackground.webAssemblyWarningDescription'
                // }, NOTIFICATION_TIMEOUT_TYPE.LONG));
            }
            return;
        }
    }
    if (!modelBuffer) {
        const modelResponse = await fetch(models.modelLandscape);
        if (!modelResponse.ok) {
            throw new Error('Failed to download tflite model!');
        }
        modelBuffer = await modelResponse.arrayBuffer();
        tflite.HEAPU8.set(new Uint8Array(modelBuffer), tflite._getModelBufferMemoryOffset());
        tflite._loadModel(modelBuffer.byteLength);
    }
    const options = {
        ...segmentationDimensions.modelLandscape,
        virtualBackground
    };
    return new JitsiStreamBackgroundEffect(tflite, options);
}
