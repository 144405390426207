import { VOD_MODE } from "../../share-layout/constants";
/**
 * VOD 리스트 가져오기
 */
export const getVodList = (data, handlerUpdateList, handlerError) => {
    APP.API.getVodList(data)
        .then((response) => {
        if (response.complete) {
            handlerUpdateList(response.data);
        }
        else {
            handlerError(response.data);
        }
    });
};
/**
 * VOD 등록하기
 */
export const addFileVod = (file, name, owner_id = 'self', handler) => {
    APP.API.registVod({ file }, name, owner_id)
        .then((response) => {
        if (response.complete) {
            const data = response.data;
            handler.success({ album_id: data.id, name });
        }
        else {
            handler.failed(response.data);
        }
    });
};
/**
 * VOD 등록하기
 */
export const addUrlVod = (external_source_url, name, owner_id = 'self', handler) => {
    APP.API.registVod({ external_source_url }, name, owner_id)
        .then((response) => {
        if (response.complete) {
            const data = response.data;
            handler.success({ album_id: data.id, name });
        }
        else {
            handler.failed(response.data);
        }
    });
};
/**
 * VOD 삭제
 */
export const deleteVod = (album_id, owner_id = 'self', handler) => {
    APP.API.deleteVod(album_id, owner_id)
        .then((response) => {
        if (response.complete) {
            handler.success(response.data);
        }
        else {
            handler.failed(response.data);
        }
    });
};
/**
 * VOD리시트
 * @param vod_list
 * @returns
 */
export async function registVod(vod_list) {
    const list = [];
    // 스트리밍 생성
    await Promise.all(vod_list.map(async (item) => {
        const registResponse = await APP.API.registVodStreaming(item.album_id, 'self');
        if (registResponse.complete) {
            list.push({ ...item, streaming_id: registResponse.data.id, streaming_url: registResponse.data.player_url_format });
        }
    }));
    return list;
}
/**
 * (VOD) 스트리밍 auth 발급
 * @param {*} vod_list
 * @returns
 */
export async function convertVodList(video_list, document_list) {
    const list = [];
    await Promise.all(video_list.map(item => {
        const isAlreadyDocumnet = document_list ? document_list.find(d => d.uuid === item.uuid) : false;
        const parts = item.url.split("/");
        if (isAlreadyDocumnet) {
            list.push(isAlreadyDocumnet);
        }
        else {
            list.push({
                ...item,
                canvasList: undefined,
                list: [parts[0], parts[2]],
                page: undefined,
                file: { name: item.name },
                isWhiteBoard: true,
                config: VOD_MODE,
                complete: true,
                shared: false
            });
        }
    }));
    return list;
}
export const getVodURLBase = (urlList) => {
    if (urlList[0].startsWith("http:")) {
        return `http://${urlList[1]}`;
    }
    else if (urlList[0].startsWith("https:")) {
        return `https://${urlList[1]}`;
    }
    throw new Error("알 수 없는 프로토콜");
};
