import { toState } from "../base/redux";
const getRoomState = (stateful) => {
    const state = toState(stateful);
    return state['features/room'];
};
/**
 * 사용자 방에서의 권한
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getPermissions(stateful) {
    return getRoomState(stateful).permissions;
}
/**
 * 방 권한 체크
 * @param {IStore['getState']} stateful
 * @param {string} permission
 * @returns
 */
export function isCheckPermission(stateful, permission) {
    const permissions = getPermissions(stateful);
    return permissions.includes(permission);
}
/**
 * 현재 방 옵션 가져오기 ,
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getRoomOption(stateful) {
    return getRoomState(stateful).option;
}
export function getRoomName(stateful) {
    const activeGroupId = getActiveGroupId(stateful);
    if (activeGroupId !== '') {
        return activeGroupId;
    }
    return getRoomOption(stateful)?.name;
}
/**
 * 방 비밀번호 확인
 * @param stateful
 * @returns
 */
export function checkRoomPassword(stateful) {
    const { uuid } = getRoomOption(stateful);
    return APP.API.searchRoom({ uuid })
        .then((response) => {
        if (response.complete) {
            return response.data.room.password_checking;
        }
        return false;
    });
}
/**
 * 방 미디어 정보 가져오기
 * @param stateful
 * @returns
 */
export function getMediaServerInfo(stateful) {
    return getRoomState(stateful).media_server_info;
}
/**
 * 현재 방에 들어와 있는지에 대한 여부
 * @param {*} stateful
 * @returns
 */
export function isRoomJoined(stateful) {
    return getRoomState(stateful).joined;
}
/**
 * 현재 방에 입장한 그룹 아이디
 * @param {*} stateful
 * @returns
 */
export function getActiveGroupId(stateful) {
    return getRoomState(stateful).group_id;
}
