import React, { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import MemberItemContainer from "./MemberItemContainer";
import { MemberList } from "./components";
import { getLocalIDMember, getLocalMember, getMemberIdsList } from "./functions";
const MemberListContainer = React.memo(({ isMonitoring, groupId, search }) => {
    const filterData = useMemo(() => groupId && groupId !== '' ? { excludeLocal: true, groupIds: [groupId], filterGroup: true } : { excludeLocal: true }, [groupId]);
    const local = useSelector((state) => isMonitoring ? null : getLocalMember(state));
    const localId = useSelector((state) => isMonitoring ? null : getLocalIDMember(state));
    const members = useSelector((state) => getMemberIdsList(state, filterData, true), shallowEqual);
    const localRole = useMemo(() => {
        return (groupId === '') ? local?.role : local?.group_role;
    }, [local?.role]);
    const list = useMemo(() => {
        const member_ids = [localId, ...members];
        return member_ids.filter(i => i !== null) || [];
    }, [localId, members]);
    return (React.createElement(MemberList, null, list.map((user_id) => user_id && React.createElement(MemberItemContainer, { key: user_id, search: search, local_group_id: groupId, localRole: localRole, user_id: user_id, isMonitoring: isMonitoring }))));
});
export default MemberListContainer;
