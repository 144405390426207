import i18next from "i18next";
import { getLocalID } from "../local";
import { SHOW_NOTIFICATION } from "../notification/actionTypes";
import { MiddlewareRegistry } from "../redux";
import { addShareMessage, setIsChatRead } from "./action";
import { ADD_MESSAGE } from "./actionTypes";
import { checkChatPermission } from "./functions";
import { getActiveGroupId } from "../../room";
import { isChatOpend } from "../sidebar";
import { OPEN_CHAT } from "../sidebar/actionTypes";
MiddlewareRegistry.register(store => next => async (action) => {
    switch (action.type) {
        case SHOW_NOTIFICATION:
            if (action.props?.save) {
                store.dispatch(addShareMessage({
                    uuid: action.uid + '_sysytem',
                    isMe: true,
                    type: 'system',
                    from: 'system',
                    fromNickname: 'system',
                    message: i18next.t(action.props.description, action.props.descriptionArguments),
                    timestamp: Number(action.uid),
                    group_id: getActiveGroupId(store.getState) || ''
                }, true));
            }
            break;
        case OPEN_CHAT:
            store.dispatch(setIsChatRead(true));
            break;
        case ADD_MESSAGE:
            if (!isChatOpend(store.getState))
                store.dispatch(setIsChatRead(false));
            if (!action.isRemote) {
                if (checkChatPermission(store.getState, 'chat')) {
                    const response = await APP.management.sendMessage(action.data);
                    if (response.status !== 200) {
                        /**
                         * (fix me)
                         */
                        console.log("addShareFile - error");
                        return;
                    }
                    action.data.group_id = getActiveGroupId(store.getState);
                }
                else
                    return;
            }
            else {
                const fromID = getLocalID(store.getState);
                action.data.isMe = action.data.from === fromID;
            }
            break;
    }
    return next(action);
});
