import { tempEnterRoom } from "../../room";
import { SET_DUAL_MONITOR } from "../../video-layout/actionTypes";
import { APP_WILL_MOUNT } from "../app";
import { MiddlewareRegistry } from "../redux";
import { toggleChat, toggleMember } from "../sidebar";
import { updateSettings } from "./actions";
import "./middleware.any";

MiddlewareRegistry.register(store => next => action => {
    switch (action.type) {
        case APP_WILL_MOUNT:
            if (location.pathname.includes("room")) {
                const location_array = location.pathname.split('/');

                if (location_array && location_array.length > 2) {
                    const room_uuid = location_array[2];
                    store.dispatch(tempEnterRoom({ uuid: room_uuid}));
                }
            }

            break;

        case SET_DUAL_MONITOR:
            if (action.isDualMonitor) {
                store.dispatch(toggleChat(true));
                store.dispatch(toggleMember(true));
                store.dispatch(updateSettings({ dualSideOpen: true }));
            } else {
                store.dispatch(updateSettings({ dualSideOpen: false }));
            }
            break;
   
    }

    return next(action);
});