import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export default function StatelessDialog(props) {
    const { t } = useTranslation();
    const containerRef = useRef();
    const dialogRef = useRef();

    useEffect(() => {
        const stopPropagation = e => {
            if (dialogRef.current) {
                if (!dialogRef.current.contains(e.target)) {
                    props._hideDialog();
                }
            }
        };

        containerRef.current.addEventListener("click", stopPropagation);
    }, [dialogRef.current, containerRef.current]);

    /**
     * Dialog Header
     * @returns 
     */
    const renderHeader = () => {
        const { titleKey, disableHeader } = props;  
        if (disableHeader) return null;

        const buttons = [
            props.renderCancelButton()
        ].filter(Boolean);

        return (
            <div key="header" className='header'>
                <h2>{ t(titleKey) }</h2>
                { buttons }
            </div>
        )
    }

    const renderBody = () => {
        return (
            <div key="body" className="dialog-body">
                { props.children }
            </div>
        )
    }

    const renderContent = [
        renderHeader(),
        renderBody()
    ].filter(Boolean);
    
    return (
        <div ref={containerRef} className='dialog-container'>
            <div ref={dialogRef} className={`dialog-content ${props.className}`} 
                style={{ width: `${props.width}px`, ...props.style }}>
                { renderContent }
            </div>
        </div>
    );
}