import { JitsiConnectionQualityEvents } from "../../../features/base/lib-jitsi-meet";
import { MEDIA_TYPE } from "../../../features/base/media";
import { startListeningForStats } from "../../../features/connection-indicator";
import { getMediaServerInfo } from "../../../features/room";
import JitsiConference from "./conference";
import { default as Connection } from "./connection";
import { default as ScreenShare } from "./screenSharing";
import JitsiTracks, { default as Tracks } from "./tracks";

const config = {
    connect: {
        serviceUrl: `wss://mate3.dev.meetmate.co.kr`,
        hosts: {
            domain: "mate.net",
            muc: "muc.mate.net",
        },
    }, 
    conference: {
        p2p: false, 
        enableUnifiedOnChrome: true,
        enableForcedReload: true,
        enableNoisyMicDetection: true,
        openBridgeChannel: 'websocket',
        enableLayerSuspension: true,
        videoQuality: {
            maxBitratesVideo: {
                low: 100000,
                standard: 500000,
                high: 2000000
            }
        }
    }
};

class JitsiManagement {
    constructor() {
        this._localTracksInitialized = false;
        this.Connection = new Connection();
        this.Conference = new JitsiConference();
        this.Tracks = new JitsiTracks();
    }

    // 회의실 connect 
    connectAndJoin(isTemp) {
        if (this.Connection.connection) {
            return Promise.resolve(this.Conference.conference);
        }

        const connectConfig = Object.assign({}, config.connect);
        const mediaInfo = getMediaServerInfo(APP.store.getState);
        const host = process.env.MATE_HOST || location.host;

        connectConfig.serviceUrl = `wss://${host.replace("https://", "")}/xmpp-websocket?${Date.parse(new Date())}`;
        
        console.log(connectConfig)
        return new Promise((resolve, reject) => {
            ScreenShare.init(this.createScreenShareTrack.bind(this));
            // 회의실 입장을 위한 jitsi connect
            this.Connection.connect(connectConfig)
                .then(connection => {
                    const joinConfig = Object.assign({}, config.conference);

                    // 회의실 입장
                    const handler = { 
                        handlerCreateTracks: (options) => this.Tracks.createTracks(options)
                    };
                    
                    return this.Conference.join(connection, joinConfig, handler, isTemp)
                        .then(conference => {
                            !isTemp && APP.store.dispatch(startListeningForStats(conference));
                            resolve(conference);
                        })
                        .catch(err => reject(err));
                });
        });
    }

    toggleE2EE(isEnable) {
        if (this.Conference && this.Conference.conference) this.Conference.conference.toggleE2EE(isEnable);
    }
    
    // 회의실 disconnect
    disconnect() {
        return new Promise((resolve, reject) => {
            this.Conference.leave();
            this.Connection.disconnect();
            resolve();
        });
    }

    /**
     * 녹화 시작 
     * @returns 
     */
    startScreenShare() {
        return ScreenShare.toggleScreenSharing(true);
    }

    /**
     * 녹화 종료 
     * @returns 
     */
    stopScreenShare() {
        return ScreenShare.toggleScreenSharing(false);
    }

    createScreenShareTrack(options) {
        return this.Tracks.createScreenShareTrack(options);
    }

    getScreenSharing() {
        return ScreenShare.isScreenSharing();
    }

    async updateTrack(newTrackOption, type, options) {
        const newTrack = await this.Tracks.createTracks({ devices: [type], ...newTrackOption });
        if (type === MEDIA_TYPE.VIDEO) this.Conference.useVideoStream(newTrack);
    }

    replaceTrack(oldTrack, newTrack) {
        return this.Conference.replaceTrack(oldTrack, newTrack)
    }

    async useVideoStream(videoTrack) {
        await this.Conference.useVideoStream(videoTrack);
    }

    /**
     * AddEventListener
     */
    /**
     * 로컬 사용자의 연결 상태 이벤트 등록
     * @param {function} handler 
     */
    registerUpdateLocalStats(handler) {
        const conference = this.Conference.conference;

        conference.on(JitsiConnectionQualityEvents.LOCAL_STATS_UPDATED, handler);
    }

    /**
     * remote 사용자의 연결 상태 이벤트 등록
     * @param {function} handler 
     */
    registerUpdateRemoteStats(handler) {
        const conference = this.Conference.conference;

        conference.on(JitsiConnectionQualityEvents.REMOTE_STATS_UPDATED, handler);
    }
}


export default JitsiManagement;