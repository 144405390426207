import React from "react";
import { useTranslation } from "react-i18next";
const RoomConfiguration = React.memo(({ children, page }) => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: "reservation_wrapper" },
        React.createElement("div", { className: "r_header main" },
            React.createElement("h2", { className: "r_left" }, t(`reservation.${page}`))),
        React.createElement("div", { className: "r_body" }, children)));
});
export default RoomConfiguration;
