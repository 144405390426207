import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IStore } from "../../app/types";
import { SettingNotification } from "./components";
import { Input } from "../ui";
import { useTranslation } from "react-i18next";
import { updateNotificationOption } from "./actions";
import { max } from "lodash";
import { toggleDialog } from "../dialog";

function SettingNotificationContainer({
    dispatch, limit, time, renderSubmitBtn
}) {
    const { t } = useTranslation();

    const [maxCount, setMaxCount] = useState(limit);
    const [durationTime, setDurationTime] = useState(time);

    useEffect(() => {
        setMaxCount(limit);
    }, [limit]);

    useEffect(() => {
        setDurationTime(time / 1000);
    }, [time]);

    const handlerClick = () => {
        if (maxCount < 0) {
            APP.UI.alertMessage("dialog.notAllowCount");
            return;
        } 
        if (durationTime < 0) {
            APP.UI.alertMessage("dialog.notAllowTime");
            return;
        }

        dispatch(updateNotificationOption(maxCount, durationTime * 1000));
        dispatch(toggleDialog());
    }
    
    /**
     * 알림 메시지 수
     * @returns 
     */
    const renderLimitCount = () => {
        return (
            <Input className="grid_count_input" type="number" 
                bottomLabel={t("dialog.per")}
                value={maxCount} onChange={setMaxCount} />
        )
    };

    /**
     * 알림 메시지 유지 시간
     * @returns 
     */
    const renderTime = () => {
        return (
            <Input className="grid_count_input" type="number" 
                bottomLabel={t("dialog.sec")}
                value={durationTime} onChange={setDurationTime} />
        )
    }

    return (
        <>
            <SettingNotification t={t} guid={t("dialog.notificationGuid")}
                renderLimitCount={renderLimitCount}
                renderTime={renderTime}
            />
            { renderSubmitBtn(handlerClick) }
        </>
        
    )
}

function _mapStateToProps(state) {
    const { limit, time } = state['features/base/notification'];

    return {
        limit, time
    }
}

export default connect(_mapStateToProps)(SettingNotificationContainer);