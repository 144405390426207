import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AbstractDevicePermission, DevicePermissionContainer } from "../../device-permission";
import { ScreenSetting } from "../../screen-share";
import { Dialog, hideDialog } from "../dialog";
import { ClickButton, OnOffSwitch, RadioButton } from "../ui";
import { updateGridOption, updateRoomPolicy, updateSettings } from "./actions";
import { SettingContainer } from "./components";
import { CHAT_FILE_LOCK, COUNT_SET, DEVICE_SET, HANDLER_SET, LABEL_SET, NOTIFICATION_SET, SCREEN_LAYOUT_SET, SETTING_LIST } from "./constants";
import { getPermissions } from "../../room/functions";
import { LayoutCountContainer } from "../layout-count";
import { RaiseHandContainer } from "../../raise-hand";
import { SettingNotificationContainer } from "../notification";
import ChatLockFile from "../chat/ChatLockFile";

function SettingContainerDialog() {
    // useTranslation을 사용하여 다국어 처리
    const { t } = useTranslation();

    // Redux의 state에 따라서 작동
    const dispatch = useDispatch();

    const { grid_count, policy, enableIconText, militaryTime } = useSelector((state) => ({
        grid_count: state["features/base/settings"].grid_count,
        policy: state["features/base/settings"].policy,
        enableIconText: state["features/base/settings"].enableIconText,
        militaryTime: state["features/base/settings"].militaryTime,
    }), shallowEqual);

    const permissions = useSelector((state) => getPermissions(state));

    
    // 현재 선택한 메뉴 타입을 useState를 이용하여 state로 관리
    const [type, setType] = useState(DEVICE_SET);
    // 라벨 상태를 useState를 이용하여 state로 관리
    const [labelStatus, setLabelStatus] = useState(true);

    // enableIconText 값이 바뀔 때마다 labelStatus state를 업데이트
    useEffect(() => {
        setLabelStatus(enableIconText);
    }, [enableIconText], shallowEqual);


    // 메뉴 타입 선택 리스트 컴포넌트를 반환하는 함수 
    const renderTap = () => {
        return SETTING_LIST.map((item) => {
            const isOptionTrue = APP.config.option[item];

            if (!isOptionTrue) return null;

            const onClick = () => setType(item);

            return (
                <ClickButton
                    key={item}
                    className={item === type ? "isFocus" : ""}
                    isText={true}
                    label={t(`dialog.${item}`)}
                    onClick={onClick}
                />
            );
        });
    };

    // 메뉴 이름 업데이트 함수
    const updateEnableClick = (data) => {
        dispatch(updateSettings(data));
    }

    const updateGridClick = (sync, count) => {
        dispatch(updateGridOption(sync, count, false));
    }

    // 방 환경 설정 함수 
    const handlerUpdatePolicy = option => {        
        dispatch(updateRoomPolicy(option, false));

        dispatch(hideDialog());
    }
    
    // 버튼 클릭 시 실행되는 함수 
    const renderSubmitBtn = (handlerClick) => {
        return (
            <ClickButton isText={true} label={t('dialog.add')} onClick={handlerClick} />
        );
    }

    // 메뉴 타입에 따라 렌더링되는 컴포넌트를 반환하는 함수
    const renderContent = () => {
        switch (type) {
            case DEVICE_SET:
                return (
                    <DevicePermissionContainer
                        initial_camera={policy.initial_camera}
                        initial_mic={policy.initial_mic}
                        handlerUpdatePolicy={handlerUpdatePolicy}
                    />
                );
        
            case HANDLER_SET:
                return <RaiseHandContainer 
                    handlerUpdatePolicy={handlerUpdatePolicy}
                    renderSubmitBtn={renderSubmitBtn}
                />

            case NOTIFICATION_SET: 
                return <SettingNotificationContainer 
                    renderSubmitBtn={renderSubmitBtn}
                />

            case COUNT_SET:
                return (
                    <LayoutCountContainer 
                        grid_sync={policy.grid_count_option}
                        show_grid_count={grid_count}
                        renderSubmitBtn={renderSubmitBtn}
                        updateGridClick={updateGridClick} />
                )

            case CHAT_FILE_LOCK:
                if (!APP.config.option.chatFileLock) return null;
                return (
                    <ChatLockFile
                        isChatLock={policy.file_option === "allow" ? false : true}
                        handlerUpdatePolicy={handlerUpdatePolicy} />
                )

        
            case LABEL_SET:
                return (
                    <>
                        <OnOffSwitch
                            className="label_option"
                            guid={t("dialog.labelGuid")}
                            label={enableIconText ? t("dialog.show") : t("dialog.hide")}
                            isShow={enableIconText}
                            handlerClick={() => updateEnableClick({ enableIconText: !enableIconText })}
                        />
                        <RadioButton
                            className="military_time"
                            guid={t("dialog.militaryTimeGuid")}
                            selectedOption={militaryTime}
                            setSelectedOption={(value) => {
                                updateEnableClick({ militaryTime: value === "false" ? false : true })
                            }}
                            list={[
                                { id: true, label: t(`dialog.trueMilitaryTime`) },
                                { id: false, label: t(`dialog.falseMilitaryTime`) },
                            ]}
                        />
                    </>
                );
        
            case SCREEN_LAYOUT_SET:
                return (
                    <ScreenSetting
                        screen_option={policy.screen_option}
                        renderSubmitBtn={renderSubmitBtn}
                        handlerUpdatePolicy={handlerUpdatePolicy}
                    />
                );
        
            default:
                return type;
        }
    };

    return (
        <Dialog className="setting" titleKey={t("dialog.setting")} width="600">
            <SettingContainer type={type} renderTap={renderTap} renderContent={renderContent} />
        </Dialog>
    )
}

export default React.memo(SettingContainerDialog);