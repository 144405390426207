import { LEAVE_ROOM } from "../../room/actionTypes";
import ReducerRegistry from "../redux/ReducerRegistry";
import { SETTINGS_UPDATED, SET_DISPLAYNAME, SET_FULL_SCREEN, UPDATE_ROOM_POLICY } from "./actionTypes";
const DEFAULT_STATE = {
    route: undefined,
    enableLobby: true,
    enableNickname: true,
    displayName: undefined,
    fullscreen: false,
    speakerVolume: 0.5,
    userSelectedAudioOutputDeviceId: undefined,
    userSelectedCameraDeviceId: undefined,
    userSelectedMicDeviceId: undefined,
    userSelectedCameraMuted: false,
    userSelectedAudioMuted: false,
    enableIconText: true,
    dualSideOpen: false,
    request_camera: true,
    request_mic: true,
    grid_count: 20,
    policy: {
        initial_camera: "on",
        initial_mic: "on",
        screen_option: {
            frame: 15,
            permission: false,
            type: 'user'
        },
        hands_up: 'fade_out',
        hands_up_time: 0,
        grid_count_option: 'none',
        file_option: 'allow'
    },
    militaryTime: true
};
;
const STORE_NAME = 'features/base/settings';
ReducerRegistry.register(STORE_NAME, (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SETTINGS_UPDATED:
            return {
                ...state,
                ...action.settings
            };
        case SET_DISPLAYNAME:
            return {
                ...state,
                displayName: action.displayName
            };
        case SET_FULL_SCREEN:
            return {
                ...state,
                fullscreen: action.full
            };
        case UPDATE_ROOM_POLICY:
            return {
                ...state,
                policy: { ...action.policy }
            };
        case LEAVE_ROOM:
            return {
                ...DEFAULT_STATE
            };
    }
    return state;
});
