import { SET_ACTIVE_GROUP_ID } from "../../room";
import { PromptAlertAttendances } from "../../room-attendance";
import { SHOW_START_ATTENDANCE } from "../../room-attendance/actionTypes";
import { ADD_MESSAGE } from "../chat";
import { hideDialog } from "../dialog";
import { ADD_MEMBER, DELETE_MEMBER, LEFT_MEMBER, MEMBER_STATUS, REQUEST_PRESENTER, RESPONSE_PRESENTER, ROLE, UPDATE_MEMBER, getLocalMemberRole, getMemberByUserID, getUserIDByMemberId } from "../members";
import AbstractPromptRaiseHand from "../raise-hand/components/AbstractPromptRaiseHand";
import { MiddlewareRegistry } from "../redux";
import { isChatOpend } from "../sidebar";
import { HIDE_NOTIFICATION, SAVE_NOTIFICATION_MESSAGE, SHOW_NOTIFICATION } from "./actionTypes";
import { hideNotification, persistentNotification, showNotification } from "./actions";
import { NOTIFICATION_TIMEOUT } from "./constants";
import { _throttledNotifyHandler, _throttledNotifyMemberConnected, _throttledNotifyMemberDisconnected, _throttledNotifyUnReadMessage, getNotificationLimit, getNotificationList, insertChat, insertHandlerMembers, insertMemberConnected, leftedMemberConnected } from "./functions";

let timers = new Map();
let requestPresenterList = new Set();
MiddlewareRegistry.register(store => next => async action => {
    const { getState, dispatch } = store;

    let member;
    switch (action.type) {
        case REQUEST_PRESENTER:
            if (!action.isLocal) {
                const user_uuid = getUserIDByMemberId(getState, action.member_uuid);
                if (!user_uuid) {
                    /**
                     * (Fix me) 에러 처리 필요 
                     */
                    return;
                }

                if (requestPresenterList.has(user_uuid)) return;
                requestPresenterList.add(user_uuid);

                const displayName = getMemberByUserID(getState, user_uuid)?.nickname;

                dispatch(persistentNotification({
                    uid: user_uuid,
                    titleKey: "notify.requestPresentation",
                    component: AbstractPromptRaiseHand,
                    componentProps: { displayName, user_uuid },
                    description: "notify.requestPresenterInfo",
                    descriptionArguments: { displayName },
                    presenter: true,
                    save: true
                }));
            } else {
                dispatch(showNotification({
                    titleKey: "notify.requestPresentation"
                }));
            }
            break;

        case RESPONSE_PRESENTER:
            if (requestPresenterList.has(action.user_uuid)) {
                dispatch(hideNotification(action.user_uuid));
                requestPresenterList.delete(action.user_uuid);
            };
            break;

        case UPDATE_MEMBER:
            if (action?.change_hands) {
                if (action.member.hands_up) {
                    insertHandlerMembers(action.member.nickname);

                    _throttledNotifyHandler(dispatch, showNotification);
                }
            }  
            break;

        case SET_ACTIVE_GROUP_ID:
            [...timers.entries()].map(([uid, timer]) => {
                clearTimeout(timer);
                timers.delete(uid);
            });
            break;
        
        case SHOW_START_ATTENDANCE:
            const localRole = getLocalMemberRole(getState);

            if (localRole === ROLE.PARTICIPANT || localRole === ROLE.PRESENTER) {            
                action.uuid && dispatch(showNotification({
                    timeout: action.check_duration,
                    uid: action.uuid,
                    component: PromptAlertAttendances,
                    componentProps: { uuid: action.uuid, name: action.name },
                    titleKey: 'attendances.request',
                    description: "notify.requestAttendances",
                    descriptionArguments: { name: action.name },
                    save: true
                }, action.check_duration));
            };  
            break;
        
        /**
         * 사용자 입장에 대한 알림
         */
        case ADD_MEMBER:
            member = action.member;

            if (member.status === MEMBER_STATUS.OCCUPIDE) {
                insertMemberConnected({ displayName: member.nickname, existed: member.existed });

                _throttledNotifyMemberConnected(dispatch, showNotification);
            }
            break;
            
        /**
         * 사용자 퇴장 대한 알림
         */
        case DELETE_MEMBER:
        case LEFT_MEMBER:
            member = action.member;

            if (member.status === MEMBER_STATUS.OCCUPIDE) {
                leftedMemberConnected(member.nickname);

                _throttledNotifyMemberDisconnected(dispatch, showNotification);
            }
            break;
        
        case SHOW_NOTIFICATION:
            action.timeout = action.timeout || NOTIFICATION_TIMEOUT.MEDIUM;

            const limit = getNotificationLimit(store.getState);
            const show_list = getNotificationList(store.getState);

            if (limit < show_list.size + 1) {
                const sortedData = [...show_list]
                    .filter(item => item.titleKey !== "attendances.request")
                    .sort((a, b) => a.timestamp - b.timestamp);

                    if (sortedData && sortedData.length > 0) {
                        const fastestUid = sortedData[0].uid;
                        store.dispatch(hideNotification(fastestUid));

                    }
                    console.log(sortedData)

            }


            if (navigator.product !== 'ReactNative') {
                const timer = timers.get(action.uid);
                if (timer) {
                    clearTimeout(timer);
                    timers.delete(action.uid);
            } else {
                if (action.timeout) {
                        const timerID = setTimeout(() => {
                            dispatch(hideNotification(action.uid));
                        }, action.timeout);
                
                        timers.set(action.uid, timerID);
                    }
                }

            }
            break;

        case HIDE_NOTIFICATION:
            requestPresenterList.delete(action.uid);

            break;

        /**
         * 채팅 알림
         */
        case ADD_MESSAGE:
            if (isChatOpend(getState) === false) {
                const data = action.data;
                if (data.type === 'system') return;
                insertChat(data.message);            

                _throttledNotifyUnReadMessage(dispatch, showNotification);
            }
            break;

        /**
         * 공지 사항 등록
         */
        case SAVE_NOTIFICATION_MESSAGE:
            if (!action.isRemote) {
                const response = await APP.management.notifyGroupMessage(action.message, '');
                if (response.status !== 200) {
                    return;
                }

                store.dispatch(hideDialog());
            }
            break;
    }

    return next(action);
});