import { toState } from "../redux";
import ChoiceMemberStatus from "./ChoiceMemberStatus";
import { EMPTY_FILE, FILE_MAX_SIZE, MAX_FILE, NO_EXT_FILE, OK_FILE, POSSIBLE_EXT_LIST } from "./constants";
/**
 * 전송 가능 파일인지 확인
 * @param {Object} file
 * @returns
 */
export function isTransferableFile(file) {
    const { name, size } = file;
    const extArr = name?.split("\.") || null;
    if (!extArr)
        return NO_EXT_FILE;
    const ext = extArr[extArr.length - 1];
    if (POSSIBLE_EXT_LIST.indexOf(ext) === -1) {
        return NO_EXT_FILE;
    }
    const sizeMB = size / 1024 / 1024;
    if (sizeMB <= 0)
        return EMPTY_FILE;
    if (sizeMB > FILE_MAX_SIZE)
        return MAX_FILE;
    return OK_FILE;
}
/**
 * 방 설정 권한 확인 (레이아웃에서만 사용)
 * @param {Function} stateful
 * @param {String} type
 * @returns Boolean
 */
export function checkChatPermission(stateful, type) {
    const state = toState(stateful);
    const permissions = state['features/room'].permissions;
    if (permissions.indexOf(type) !== -1)
        return true;
    return false;
}
/**
 * 채팅 가져오기
 * @param stateful
 * @returns
 */
export function getMessages(stateful, group_id = '') {
    const state = toState(stateful);
    const messages = state['features/base/chat'].messages;
    const active_id = group_id;
    const filteredMessages = [...messages.values()].filter(item => item.group_id === active_id)
        .sort((a, b) => a.timestamp - b.timestamp).map(item => item.uuid);
    return filteredMessages;
}
/**
 * 채팅 가져오기
 * @param stateful
 * @param uuid
 * @returns
 */
export function getMessageByUid(stateful, uuid) {
    const state = toState(stateful);
    const messages = state['features/base/chat'].messages;
    return messages && messages.get(uuid);
}
/**
 * 비밀 채팅 사용자 리스트 가져오기
 * @param stateful
 * @returns
 */
export function getChoiceList(stateful) {
    const mapMember = ChoiceMemberStatus.getChoiceMembers();
    const objArr = Array.from(mapMember.entries()).map(([key, value]) => {
        return { user_uuid: key, nickname: value };
    });
    return objArr;
}
/**
 * 파일 링크 가져오기
 * @param stateful
 * @param uuid
 * @returns
 */
export function getFileLink(stateful, uuid) {
    const state = toState(stateful);
    const fileList = state['features/base/chat'].fileList;
    return fileList.get(uuid);
}
/**
 * 채팅 읽음 여부
 *  @returns
 */
export function isChatRead(stateful) {
    const state = toState(stateful);
    return state['features/base/chat'].isRead;
}
/**
 * 사용자 크기 가져오기
 * @param bytes
 * @returns
 */
export function bytesToSize(bytes) {
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0)
        return '0 B';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    let size = bytes / Math.pow(1024, i);
    if (size.toFixed(2).slice(-2) == '00') {
        size = Math.round(size);
    }
    else {
        size = size.toFixed(2);
    }
    return size + ' ' + sizes[i];
}
