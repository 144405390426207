import JitsiMeetJS from '../lib-jitsi-meet';
import { toState } from '../redux';
import { updateSettings } from '../settings';
const webrtcKindToJitsiKindTranslator = {
    audioinput: 'audioInput',
    audiooutput: 'audioOutput',
    videoinput: 'videoInput'
};
/**
 * A/V 권한이 아직 부여되지 않은 경우 레이블을 사용할 수 없는 경우 사용 사례를 감지합니다.
 *
 * @param {Object} state - The redux state.
 * @returns {boolean} - True if the labels are already initialized and false
 * otherwise.
 */
export function areDeviceLabelsInitialized(stateful) {
    // if (APP.conference._localTracksInitialized) {
    //     return true;
    // }
    for (const type of ['audioInput', 'audioOutput', 'videoInput']) {
        if ((toState(stateful)['features/base/devices'].availableDevices[type] || []).find(d => Boolean(d.label))) {
            return true;
        }
    }
    return false;
}
/**
 * 현재 사용 중인 오디오 출력 장치의 장치 ID를 가져옵니다.
 * 빈 문자열은 기본 장치를 나타냅니다.
 *
 * @returns {string}
 */
export function getAudioOutputDeviceId() {
    return JitsiMeetJS.mediaDevices.getAudioOutputDevice();
}
/**
 * 주어진 유형의 기본 장치의 실제 장치 ID를 찾습니다.
 *
 * @param {Object} state - The redux state.
 * @param {*} kind - The type of the device. One of "audioInput",
 * "audioOutput", and "videoInput". Also supported is all lowercase versions
 * of the preceding types.
 * @returns {string|undefined}
 */
export function getDefaultDeviceId(state, kind) {
    const kindToSearch = webrtcKindToJitsiKindTranslator[kind] || kind;
    const defaultDevice = (state['features/base/devices'].availableDevices[kindToSearch] || [])
        .find(d => d.deviceId === 'default');
    // Find the device with a matching group id.
    const matchingDevice = (state['features/base/devices'].availableDevices[kindToSearch] || [])
        .find(d => d.deviceId !== 'default' && d.groupId === defaultDevice.groupId);
    if (matchingDevice) {
        return matchingDevice.deviceId;
    }
}
/**
 * 주어진 유형의 기본 장치의 실제 장치 ID를 찾습니다.
 *
 * @param {Object} state - The redux state.
 * @param {string} label - The label.
 * @param {string} kind - The type of the device. One of "audioInput",
 * "audioOutput", and "videoInput". Also supported is all lowercase versions
 * of the preceding types.
 * @returns {string|undefined}
 */
export function getDeviceIdByLabel(state, label, kind) {
    const kindToSearch = webrtcKindToJitsiKindTranslator[kind] || kind;
    const device = (state['features/base/devices'].availableDevices[kindToSearch] || []).find(d => d.label === label);
    if (device) {
        return device.deviceId;
    }
}
/**
 * 주어진 유형의 기본 장치의 실제 장치 ID를 찾습니다.
 *
 * @param {Object} state - The redux state.
 * @param {string} id - The device id.
 * @param {string} kind - The type of the device. One of "audioInput",
 * "audioOutput", and "videoInput". Also supported is all lowercase versions
 * of the preceding types.
 * @returns {string|undefined}
 */
export function getDeviceLabelById(state, id, kind) {
    const kindToSearch = webrtcKindToJitsiKindTranslator[kind] || kind;
    const device = (state['features/base/devices'].availableDevices[kindToSearch] || []).find(d => d.deviceId === id);
    if (device) {
        return device.label;
    }
}
/**
 * 미디어 장치 배열을 장치 종류별로 구성된 개체로 변환합니다.
 *
 * @param {Array<MediaDeviceInfo>} devices - Available media devices.
 * @private
 * @returns {Object} - 유형별로 분할된 미디어 장치가 있는 개체입니다. 키는 장치 유형이고 값은 장치 유형과 일치하는 장치가 있는 배열입니다.
 */
export function groupDevicesByKind(devices) {
    return {
        audioInput: devices.filter(device => device.kind === 'audioinput'),
        audioOutput: devices.filter(device => device.kind === 'audiooutput'),
        videoInput: devices.filter(device => device.kind === 'videoinput')
    };
}
/**
 * MediaDeviceInfo 개체 목록에서 오디오 장치를 필터링합니다.
 *
 * @param {Array<MediaDeviceInfo>} devices - Unfiltered media devices.
 * @private
 * @returns {Array<MediaDeviceInfo>} Filtered audio devices.
 */
export function filterAudioDevices(devices) {
    return devices.filter(device => device.kind === 'audioinput');
}
/**
 * We want to strip any device details that are not very user friendly, like usb ids put in brackets at the end.
 *
 * @param {string} label - Device label to format.
 *
 * @returns {string} - Formatted string.
 */
export function formatDeviceLabel(label) {
    let formattedLabel = label;
    // Remove braked description at the end as it contains non user friendly strings i.e.
    // Microsoft® LifeCam HD-3000 (045e:0779:31dg:d1231)
    const ix = formattedLabel.lastIndexOf('(');
    if (ix !== -1) {
        formattedLabel = formattedLabel.substr(0, ix);
    }
    return formattedLabel;
}
/**
 * Returns a list of objects containing all the microphone device ids and labels.
 *
 * @param {Object} state - The state of the application.
 * @returns {Object[]}
 */
export function getAudioInputDeviceData(state) {
    return state['features/base/devices'].availableDevices.audioInput.map(({ deviceId, label }) => {
        return {
            deviceId,
            label
        };
    });
}
/**
 * Returns a list of objectes containing all the output device ids and labels.
 *
 * @param {Object} state - The state of the application.
 * @returns {Object[]}
 */
export function getAudioOutputDeviceData(state) {
    return state['features/base/devices'].availableDevices.audioOutput.map(({ deviceId, label }) => {
        return {
            deviceId,
            label
        };
    });
}
/**
 * Returns a list of all the camera device ids.
 *
 * @param {Object} state - The state of the application.
 * @returns {string[]}
 */
export function getVideoDeviceIds(state) {
    return state['features/base/devices'].availableDevices.videoInput.map(({ deviceId }) => deviceId);
}
/**
 * Returns true if there are devices of a specific type or on native platform.
 *
 * @param {Object} state - The state of the application.
 * @param {string} type - The type of device: VideoOutput | audioOutput | audioInput.
 *
 * @returns {boolean}
 */
export function hasAvailableDevices(state, type) {
    if (state['features/base/devices'] === undefined) {
        return true;
    }
    return state['features/base/devices'].availableDevices[type].length > 0;
}
/**
 * Set device id of the audio output device which is currently in use.
 * Empty string stands for default device.
 *
 * @param {string} newId - New audio output device id.
 * @param {Function} dispatch - The Redux dispatch function.
 * @param {boolean} userSelection - Whether this is a user selection update.
 * @param {?string} newLabel - New audio output device label to store.
 * @returns {Promise}
 */
export function setAudioOutputDeviceId(newId = 'default', dispatch, userSelection = false, newLabel) {
    console.log(`setAudioOutputDevice: ${String(newLabel)}[${newId}]`);
    if (!JitsiMeetJS.mediaDevices.isDeviceChangeAvailable('output')) {
        // console.warn('Adjusting audio output is not supported');
        return Promise.resolve();
    }
    return JitsiMeetJS.mediaDevices.setAudioOutputDevice(newId)
        .then(() => {
        const newSettings = {
            audioOutputDeviceId: newId,
            userSelectedAudioOutputDeviceId: undefined,
            userSelectedAudioOutputDeviceLabel: undefined
        };
        if (userSelection) {
            newSettings.userSelectedAudioOutputDeviceId = newId;
            newSettings.userSelectedAudioOutputDeviceLabel = newLabel;
        }
        else {
            // a flow workaround, I needed to add 'userSelectedAudioOutputDeviceId: undefined'
            delete newSettings.userSelectedAudioOutputDeviceId;
            delete newSettings.userSelectedAudioOutputDeviceLabel;
        }
        return dispatch(updateSettings(newSettings));
    });
}
/**
 *
 * @param {getState} state
 * @returns
 */
export function getDevicePermission(stateful) {
    const state = toState(stateful);
    return state['features/base/devices'].permissions;
}
