import React from "react";

export default function DevicePermission({
    guid, renderButtons
}) {
    return (
        <>
            <div className="guid">
                {guid}
            </div>
            <div style={{ display: "flex", gap: "10px", flexWrap: "wrap", width: "252px", justifyContent: "space-around" }}>
                { renderButtons() }                
            </div>
        </>
    )
}