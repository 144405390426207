import { throttle } from "lodash";
import React, { useEffect, useRef } from "react";
import { LobbyStyled } from "./styles";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ClassLoading from "../../../../assets/images/classmate_loading.gif";
function Lobby({ children, setIsMobile }) {
    const lobbyRef = useRef(null);
    const config = APP.config['lobby'];
    /**
     * 레이아웃 사이즈 세팅
     */
    const setLayout = throttle(() => {
        const element = lobbyRef?.current;
        if (element) {
            const { width } = element.getBoundingClientRect();
            if (width <= 1200)
                setIsMobile(true);
            else
                setIsMobile(false);
        }
    }, 500);
    /**
     * 초기 설정
     */
    useEffect(() => {
        setLayout();
        window.addEventListener("resize", setLayout);
        return () => {
            window.removeEventListener("resize", setLayout);
        };
    }, []);
    return (React.createElement(LobbyStyled, { ref: lobbyRef, style: APP.config.name === 'class' ? null : { background: config.background } }, APP.config.name === 'class'
        ? React.createElement("img", { src: ClassLoading })
        : React.createElement(React.Fragment, null,
            React.createElement("div", { className: "logo", style: { ...config.logo, margin: "2% 0 auto 3%" } }),
            React.createElement("div", { className: "preview-container" }, children))));
}
export default React.memo(Lobby);
