import React from "react";
import { useDispatch } from "react-redux";
import { toggleCameraFacingMode } from "../../media";
import { ClickButton } from "../../ui";
const ToggleAudioButton = React.memo(({ className, mode, label, iconStyles, enableLable }) => {
    const dispatch = useDispatch();
    const onClick = () => {
        dispatch(toggleCameraFacingMode());
    };
    return (React.createElement(ClickButton, { className: className, icon: mode, size: enableLable ? 20 : 24, label: label, styles: iconStyles, onClick: onClick }));
});
export default ToggleAudioButton;
