import React, { useEffect, useMemo, useState } from "react";
import VodListContainer from "../base/vod/VodListContainer";
import { RoomConfigurationDialog } from "./components";
import { VodTempPlayerContainer } from "../base/vod";
import { ClickButton } from "../base/ui";
import AddUrlPopup from "../base/vod/AddUrlPopup";
const RoomConfigurationDialogContainer = React.memo(({}) => {
    const [item, setItem] = useState();
    const hideDialog = () => setItem(undefined);
    const renderBottomBtn = useMemo(() => {
        if (item?.title === 'vod.urlAdd')
            return null;
        return (React.createElement(ClickButton, { isText: true, label: "ok", onClick: hideDialog }));
    }, [item?.title, hideDialog]);
    const toggleDialog = (data) => {
        switch (data.type) {
            case 'openVodList':
                setItem({
                    title: 'vod.list',
                    component: VodListContainer,
                    width: 600,
                    style: { height: '100%' },
                    props: { ...data.props, isFilter: true, show_list: ['check', 'title', 'size'] }
                });
                break;
            case 'openVodPlayer':
                setItem({
                    title: 'vod.player',
                    component: VodTempPlayerContainer,
                    width: 600,
                    style: { height: 'fit-content' },
                    props: { ...data.props }
                });
                break;
            case 'openUrlPopup':
                setItem({
                    title: 'vod.urlAdd',
                    component: AddUrlPopup,
                    width: 450,
                    style: { height: 'fit-content' },
                    props: { ...data.props }
                });
                break;
            default:
                setItem(undefined);
        }
    };
    useEffect(() => {
        APP.eventManager.subscribe('toggleRConfigurationDialog', toggleDialog);
        return () => {
            APP.eventManager.unsubscribe('toggleRConfigurationDialog', toggleDialog);
        };
    }, []);
    return (item && React.createElement(RoomConfigurationDialog, { item: item, hideDialog: hideDialog, renderBottomBtn: renderBottomBtn }));
});
export default RoomConfigurationDialogContainer;
