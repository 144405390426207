import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isMobileBrowser } from "../base/environment/utils";
import { AbstractOneDepthLayoutButton, ChangeLayoutModeButton, MemberCount, PersonalSettingsButton, RoomTimer } from "../base/header";
import { getBridgeIDByUserID, getLocalIDMember } from "../base/members";
import { getPropertyValue } from "../base/settings";
import { ClickButton } from "../base/ui";
import { ConnectionIndicatorContainer } from "../connection-indicator";
import { GroupDualContainer, getEnabledGroup, getGroupByUid } from "../groups";
import { Room } from "./components";
import { getActiveGroupId, getPermissions, getRoomOption } from "./functions";
import { NotificationContainer } from "../base/notification";
import AudioContainer from "./AudioContainer";
import { DualLayoutContainer } from "../video-layout/components";
import { getDualMonitor } from "../video-layout";
const RoomContainer = React.memo(() => {
    const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState(false);
    const roomName = useSelector((state) => {
        let name = '';
        const activieGroup = getActiveGroupId(state);
        if (activieGroup === '')
            name = getRoomOption(state)?.name || '';
        else {
            const group = getGroupByUid(state, activieGroup);
            name = t('group.name', { name: group?.name });
        }
        return name;
    });
    const permissions = useSelector((state) => getPermissions(state));
    const enableLable = useSelector((state) => APP.config.option.enableLable && getPropertyValue(state, 'enableIconText'));
    const localJitsiId = useSelector((state) => getBridgeIDByUserID(state, getLocalIDMember(state)));
    const enableGroup = useSelector((state) => getEnabledGroup(state));
    const isDualMonitor = useSelector((state) => getDualMonitor(state));
    const updateLayoutSize = (size) => {
        if (isMobileBrowser() || APP.config.name === 'class' || size.width <= 720)
            setIsMobile(true);
        else
            setIsMobile(false);
    };
    useEffect(() => {
        APP.eventManager.subscribe('UPDATE_ROOM_SIZE', updateLayoutSize);
        return () => {
            APP.eventManager.unsubscribe("UPDATE_ROOM_SIZE", updateLayoutSize);
        };
    }, []);
    const renderSettingBtn = (mode, iconStyles, onClick) => {
        return React.createElement(PersonalSettingsButton, { key: mode, className: 'room_label_icon', mode: mode, isMobile: false, enableLable: enableLable, permissions: permissions, iconStyles: iconStyles, onClick: onClick });
    };
    const renderRoomItem = (list, iconStyles, renderHeadItem) => {
        return (list.map(item => {
            switch (item) {
                case 'room_name':
                    return renderHeadItem(item, roomName);
                case 'room_count':
                    return renderHeadItem(item, React.createElement(ClickButton, { className: "no-hover row_icon count", label: React.createElement(MemberCount, null), icon: 'personnel', size: 16 }));
                case 'room_time':
                    return renderHeadItem(item, React.createElement(ClickButton, { className: "no-hover row_icon time", label: React.createElement(RoomTimer, null), icon: 'time', size: 18 }));
                case 'mode_change':
                    if (isMobile && APP.config.option.modeChange && !APP.config.option.isDetailMode)
                        return renderSettingBtn('modeChange', iconStyles);
                    else
                        return (React.createElement(ChangeLayoutModeButton, { key: item, className: 'room_label_icon', iconStyles: iconStyles, enableLable: enableLable, permissions: permissions, fix_mode: APP.config.option.isDetailMode }));
                case 'connection':
                    if (!localJitsiId)
                        return null;
                    return (React.createElement(ConnectionIndicatorContainer, { key: item, jitsi_id: localJitsiId }));
                case 'one_depth_mode_change':
                    return React.createElement(AbstractOneDepthLayoutButton, { key: item, className: 'room_label_icon', isModal: false, enableLable: enableLable, permissions: permissions, iconStyles: iconStyles });
                default:
                    return renderSettingBtn(item, iconStyles);
            }
        }));
    };
    const uiOption = useMemo(() => APP.config.ui[isMobile ? 'mobile' : 'desktop'], [isMobile]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Room, { uiOption: uiOption, isMobile: isMobile, renderRoomItem: renderRoomItem }),
        isDualMonitor && React.createElement(DualLayoutContainer, null),
        enableGroup && React.createElement(GroupDualContainer, null),
        React.createElement(NotificationContainer, null),
        React.createElement(AudioContainer, null)));
});
export default RoomContainer;
