import React from "react";

export default function RoomLock({
    t, renderInput, renderEnterBtn
}) {
    return (
        <div className="roomLock option">
            <form>
                { renderInput() }
            </form>

            { renderEnterBtn() }
        </div>
    )
}