import React from "react";
import { useTranslation } from "react-i18next";
const ReservationRoomForm = React.memo(({ renderItem, renderBottomBtn }) => {
    const { t } = useTranslation();
    const renderPassword = (password_checking, handler, renderInputPwd) => {
        return (React.createElement("div", { className: "password" },
            React.createElement("div", null,
                React.createElement("input", { id: "pwd_open", type: "radio", name: "password", onClick: handler.checkedPwd, defaultChecked: !password_checking }),
                React.createElement("label", { htmlFor: "pwd_open" }, t("reservation.open"))),
            React.createElement("div", null,
                React.createElement("input", { id: "pwd_hide", type: "radio", name: "password", onClick: handler.unCheckedPwd, defaultChecked: password_checking }),
                React.createElement("label", { htmlFor: "pwd_hide" }, t("reservation.hide")),
                renderInputPwd())));
    };
    const renderStartDate = (start_time, onChangeDate) => {
        return (React.createElement("div", { className: "startDate", style: { display: "flex", gap: "12px", alignItems: "center", flexWrap: "wrap" } },
            React.createElement("input", { type: "date", min: start_time, onChange: e => {
                    const date = e.target.value;
                    onChangeDate(date + " " + start_time.substr(11, 5));
                }, defaultValue: start_time.substr(0, 10) ?? "" }),
            React.createElement("input", { type: "time", onChange: e => {
                    const time = e.target.value;
                    onChangeDate(start_time.substr(0, 10) + " " + time);
                }, defaultValue: start_time.substr(11, 5) ?? "" })));
    };
    const renderRoomType = (type) => {
        // defaultChecked={!modifyItem?.type === name}  />
        return (React.createElement("div", { className: "password" }, ['conference', 'edu', 'seminar'].map(name => {
            return (React.createElement("div", { key: name },
                React.createElement("input", { id: name, type: "radio", name: "roomType" }),
                React.createElement("label", { htmlFor: name }, t(`reservation.${name}`))));
        })));
    };
    const renderInviteMail = (list, handler, renderEmailInput) => {
        return (React.createElement("div", null,
            React.createElement("div", { className: "mail_input_box" }, renderEmailInput()),
            React.createElement("div", { className: "sender_list" }, list.map(({ email, isValidate, exist }) => {
                return (React.createElement("div", { key: email, className: `invite_${exist ? 'exist' : isValidate}`, onClick: () => handler.removeInviteList(email, exist) }, email));
            }))));
    };
    const renderRegistVod = (renderVodList, vodList, cancelVodList) => {
        return (React.createElement("div", null,
            renderVodList(),
            React.createElement("div", { className: "sender_list" }, vodList.map(item => (React.createElement("div", { key: item.streaming_id, className: 'invite_true', onClick: () => cancelVodList(item.streaming_id, item.name) }, item.name))))));
    };
    const renderTD = (name) => {
        switch (name) {
            case 'title':
            case 'duration':
            case 'entryTime':
                return renderItem(name);
            case 'password':
                return renderItem(name, renderPassword);
            case 'startDate':
                return renderItem(name, renderStartDate);
            case 'inviteMail':
                return renderItem(name, renderInviteMail);
            case 'roomType':
                return null;
            // return renderItem(name, renderRoomType);
            case 'registVod':
                return renderItem(name, renderRegistVod);
        }
    };
    return (React.createElement("div", { className: "r_body" },
        React.createElement("div", { className: "table regist" }, ['title', 'password', 'startDate', 'duration', 'entryTime', 'inviteMail', 'registVod'].map(name => {
            return (React.createElement("div", { key: name, className: "table_tr" },
                React.createElement("div", { className: "table_th" }, t(`reservation.${name}`)),
                React.createElement("div", { className: "table_td" }, renderTD(name))));
        })),
        React.createElement("div", { style: { textAlign: "center" } }, renderBottomBtn())));
});
export default ReservationRoomForm;
