import moment from "moment";
import { tempEnterRoom } from "../../../room/actions";
import { hideDialog } from "../../dialog";
import { RESERVATION_PAGE_TYPE, ROOM_STATUS, SHOW_DEFAULT_COUNT } from "../constants";
import { registVod } from "../../vod/functions";
// 회의실 초대 메일 전송 
const sendInviteMail = (meeting_uuid, list) => {
    if (list.length <= 0)
        return Promise.resolve({ complete: true });
    let sender = new Array();
    list.map(item => {
        if (item.isValidate === true)
            sender.push({ email: item.email, phone_number: "" });
    });
    return APP.API.inviteReservationRoom(meeting_uuid, sender);
};
// 회의실 리스트 가져오기
export const getRoomReservationList = (localID, page_type, currentPage, handler) => {
    let data, offset, limit;
    if (page_type === RESERVATION_PAGE_TYPE.RESERVE_ROOM) {
        // 예약 회의 가져오기
        data = { associated_user_id: localID, member_user_id: localID,
            only_enterable: false, status: ROOM_STATUS.BOOKED, start_time_order_by_asc: false };
    }
    else {
        // 시작된 회의 가져오기 
        data = { associated_user_id: localID, only_enterable: true, start_time_order_by_asc: true };
    }
    offset = currentPage * SHOW_DEFAULT_COUNT;
    limit = SHOW_DEFAULT_COUNT;
    APP.API.searchRoom({ ...data, offset, limit }, true)
        .then(response => {
        if (response.complete) {
            const { items, total_count } = response.data;
            handler.setTotalCount(total_count);
            handler.setList(items === null ? new Array() : items);
        }
        else {
            APP.UI.alertMessage(response.data);
        }
    });
};
// 방 입장 
export const enterRoom = (t, dispatch, item) => {
    const duration = item.pre_entering_duration / 60000;
    const start_time = new Date(item.start_time).toISOString();
    const now_time = moment(new Date());
    const limit_time = now_time.add(duration, 'm').toDate().toISOString();
    if (limit_time <= start_time) {
        APP.UI.alertMessage(t('reservation.noEntryTime'));
        return;
    }
    dispatch(hideDialog());
    dispatch(tempEnterRoom({ uuid: item.uuid }));
};
// 방 예약
export const reservationRoom = async (command, handlerSetReservePage, toggleLoading) => {
    toggleLoading && toggleLoading(true);
    APP.API.reservationRoom(command.reservation_data)
        .then(async (response) => {
        if (response.complete) {
            const meeting_uuid = response.data;
            const vod_list = await registVod(command.vod_list);
            Promise.all([
                sendInviteMail(meeting_uuid, command.email_list),
                APP.API.changeVodList(vod_list, meeting_uuid)
            ]).then(([inviteResponse, changeVodResponse]) => {
                if (inviteResponse.complete && changeVodResponse.complete) {
                    handlerSetReservePage();
                }
                else {
                    let err;
                    if (!inviteResponse.complete)
                        err = inviteResponse.data;
                    else if (!changeVodResponse.complete)
                        err = changeVodResponse.data;
                    err && APP.UI.alertMessage(err);
                }
                toggleLoading && toggleLoading(false);
            });
        }
        else {
            APP.UI.alertMessage(response.data);
            toggleLoading && toggleLoading(false);
        }
    });
};
// 방 상태 업데이트 
export const updateReservationRoom = (command, handlerSetReservePage, toggleLoading) => {
    const meeting_uuid = command.reservation_id;
    APP.API.udpateReservationRoom(command.reservation_data, meeting_uuid)
        .then(response => {
        if (response.complete) {
            Promise.all([
                sendInviteMail(meeting_uuid, command.email_list),
                APP.API.changeVodList(command.vod_list, meeting_uuid)
            ]).then(([inviteResponse, changeVodResponse]) => {
                if (inviteResponse.complete && changeVodResponse.complete) {
                    handlerSetReservePage();
                }
                else {
                    let err;
                    if (!inviteResponse.complete)
                        err = inviteResponse.data;
                    else if (!changeVodResponse.complete)
                        err = changeVodResponse.data;
                    err && APP.UI.alertMessage(err);
                }
                toggleLoading && toggleLoading(false);
            });
        }
        else {
            APP.UI.alertMessage(response.data);
            toggleLoading && toggleLoading(false);
        }
    });
    // toggleLoading && toggleLoading(true);
    // APP.API.udpateReservationRoom(command.reservation_data, command.reservation_id)
    //     .then(response => {
    //         if (response.complete) {
    //             // 회의실 초대
    //             sendInviteMail(command.reservation_id, command.email_list)
    //                 .then(response => {
    //                     if (command.email_list.length <= 0) {
    //                         handlerSetReservePage();
    //                         return;
    //                     }
    //                     if (!response.complete) APP.UI.alertMessage(response.data);
    //                     handlerSetReservePage();
    //                 });
    //         } else {
    //             APP.UI.alertMessage(response.data);
    //             toggleLoading && toggleLoading(false);
    //         }
    //     });
};
// 방 삭제
export const deleteReservationRoom = (t, uuid, handlerUpdateRoom) => {
    if (APP.UI.confirmMessage(t("reservation.confrimSelectDelRoom"))) {
        APP.API.deleteReservationRoom(uuid)
            .then(response => {
            if (!response.complete)
                APP.UI.alertMessage(response.message);
            else {
                handlerUpdateRoom();
            }
        });
    }
};
