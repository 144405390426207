import React, { useMemo } from "react";
import { ClickButton } from "../base/ui";
import { useTranslation } from "react-i18next";
const NoDocument = React.memo(({ focusIndex, isVod }) => {
    const { t } = useTranslation();
    const label = useMemo(() => focusIndex === undefined ? 'no_document' : 'no_share', [focusIndex]);
    const name = useMemo(() => isVod ? 'vod' : 'page', [isVod]);
    return (React.createElement(ClickButton, { className: 'no_document no-hover', icon: isVod ? 'vodShow' : 'document', color: '#000', size: 32, label: t(`share.${label}`, { name: t(`share.${name}`) }) }));
});
export default NoDocument;
