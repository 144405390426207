import React, { useEffect, useRef, useState } from "react";
const BottomComponent = React.memo(({ updateData }) => {
    const bottomObserver = useRef(null);
    const bottomRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const handleIntersection = (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting && !loading) {
            setLoading(true);
            updateData();
            setLoading(false);
        }
    };
    useEffect(() => {
        bottomObserver.current = new IntersectionObserver(handleIntersection, { threshold: 0.5, rootMargin: '200px' });
        if (bottomRef.current) {
            bottomObserver.current.observe(bottomRef.current);
        }
        return () => {
            if (bottomObserver.current) {
                bottomObserver.current.disconnect();
            }
        };
    }, [updateData]);
    return React.createElement("div", { ref: bottomRef, className: "bottomObserver" }, "Loading");
});
export default BottomComponent;
