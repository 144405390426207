import React, { useCallback, useEffect, useState } from "react";
import { createLocalTrack, createLocalTracks } from "../../../base/lib-jitsi-meet/functions.any";
import { toggleLoad } from "../../../base/loading";
import { connect } from "../../../base/redux";
import { updateSettings } from "../../../base/settings";
import { getDeviceSelectionProps, getVdoErrorMsg } from "../../functions";
import AudioInputPreview from "./AudioInputPreview";
import AudioInputTest from "./AudioInputTest";
import AudioOutputPreview from "./AudioOutputPreview";
import DeviceSelectorWeb from "./DeviceSelector";
import VideoInputPreview from "./VideoInputPreview";

let tVideoTrack = null;
let tAudioTrack = null;
let unMounted = true;
function DeviceSelection({
    t, dispatch, isModal, initVolume, enterRoomButton, onSelectDevice,
    availableDevices, hasVideoPermission, hasAudioPermission,
    selectedVideoInputId, selectedAudioInputId, selectedAudioOutputId,

    noShowMuted, disablePreviews 
}) {
    const [previewVideoTrack, setPreviewVideoTrack] = useState(null);
    const [previewAudioTrack, setPreviewAudioTrack] = useState(null);
    const [previewVideoTrackError, setPreviewVideoTrackError] = useState(null);
    const [previewAudioTrackError, setPreviewAudioTrackError] = useState(null);
    const [volume, setVolume] = useState(initVolume);

    useEffect(() => {
        setVolume(initVolume);
    }, [initVolume]);

    useEffect(() => {
        tVideoTrack = previewVideoTrack;
    }, [previewVideoTrack]);

    useEffect(() => {
        tAudioTrack = previewAudioTrack;
    }, [previewAudioTrack]);

    /**
     * 초기 세팅 
     */
    useEffect(() => {
        Promise.all([
            createLocalTracks([ 'audio', 'video' ], 5000)
        ]).then(([result]) => {
            
            // 오디오 트랙 생성에 성공하면 해당 트랙을 상태에 업데이트합니다.
            // const videoTrack = result.find(track => track.getType() === 'video') || null;
            // const audioTrack =result.find(track => track.getType() === 'audio') || null;
            // setPreviewAudioTrack(audioTrack);
            // setPreviewVideoTrack(videoTrack);

            // if (videoTrack) onSelectDevice({ userSelectedCameraDeviceId: videoTrack.deviceId });
            // if (audioTrack) onSelectDevice({ userSelectedCameraDeviceId: audioTrack.deviceId });

            result.map(i => i.dispose && i.dispose())

            return Promise.reject();
        }).catch(err => {
            initDevice();
        })

        return () => {
            unMounted = true;
            tVideoTrack && tVideoTrack.dispose();
            tAudioTrack && tAudioTrack.dispose();

            _disposeAudioInputPreview();
            _disposeVideoInputPreview();
        }
    }, []);
    
    /**
     * 비디오 트랙 변경 했을 때 
     */
    useEffect(() => {
        if (selectedVideoInputId) _createVideoInputTrack(selectedVideoInputId);
    }, [selectedVideoInputId]);

    /**
     * 오디오 트랙 변경 했을 때
     */
    useEffect(() => {
        if (selectedAudioInputId) _createAudioInputTrack(selectedAudioInputId);
    }, [selectedAudioInputId]);

    /**
     * 장치 초기화 
     */
    const initDevice = () => {
        unMounted = false;
        Promise.all([
            _createVideoInputTrack(selectedVideoInputId),
            _createAudioInputTrack(selectedAudioInputId)
        ]).then(() => {
            dispatch(toggleLoad(false));
        });
    }

    /**
     * 현재 오디오 입력 미리보기를 삭제하기 위한 유틸리티 기능입니다.
     *
     * @private
     * @returns {Promise<*>}
     */
    const _disposeAudioInputPreview = () => {
        return previewAudioTrack ? previewAudioTrack.dispose() : Promise.resolve();
    }
    
    /**
     * 현재 비디오 입력 미리보기를 삭제하기 위한 유틸리티 기능입니다.
     *
     * @private
     * @returns {Promise}
     */
    const _disposeVideoInputPreview = () => {
        return previewVideoTrack ? previewVideoTrack.dispose() : Promise.resolve();
    }

    /**
     * 오디오 입력 미리보기를 위한 Track을 생성합니다.
     *
     * @param {string} deviceId - The id of audio input device to preview.
     * @private
     * @returns {void}
     */
    const _createAudioInputTrack = (deviceId) => {
        return _disposeAudioInputPreview()
            .then(() => createLocalTrack('audio', deviceId, 5000))
            .then(localTrack => {
                if (!localTrack || unMounted) {
                    localTrack && localTrack.dispose();

                    return;
                }
                setPreviewAudioTrack(localTrack);
                setPreviewAudioTrackError(null);
            })
            .catch(err => {                
                setPreviewAudioTrack(null);
                setPreviewAudioTrackError(err);
            });
    };

    /**
     * 비디오 입력 미리보기를 위한 Track을 생성합니다
     *
     * @param {string} deviceId - The id of video device to preview.
     * @private
     * @returns {void}
     */
    const _createVideoInputTrack = (deviceId) => {
        let options = null;
        if (navigator.product === 'ReactNative' && deviceId) {            
            if (deviceId === CAMERA_FACING_MODE.ENVIRONMENT) options = { facingMode: CAMERA_FACING_MODE.ENVIRONMENT };
            deviceId = null;
        }

        return _disposeVideoInputPreview()
            .then(() => createLocalTrack('video', deviceId, 5000, options))
            .then(localTrack => {
                console.log({label : localTrack?.track?.label});
                if (!localTrack || unMounted) {
                    localTrack && localTrack.dispose();
                    return;
                }
                
                setPreviewVideoTrack(localTrack);
                setPreviewVideoTrackError(null);
            })
            .catch(err => {
                const status = getVdoErrorMsg(err);

                setPreviewVideoTrack(null);
                setPreviewVideoTrackError(status);
            });
    }

    /**
     * 전달된 구성을 기반으로 DeviceSelector 인스턴스를 만듭니다.
     *
     * @private
     * @param {Object} deviceSelectorProps - The props for the DeviceSelector.
     * @returns {ReactElement}
     */
    const _renderSelector = (deviceSelectorProps) => {
        return (
            <div className='item-property'>
                <DeviceSelectorWeb {...deviceSelectorProps} />
                { deviceSelectorProps.childern && deviceSelectorProps.childern() }
            </div>
        );
    }   

    /**
     * 비디오 출력, 오디오 입력 및 오디오 출력을 위한 DeviceSelector 인스턴스를 만듭니다.
     *
     * @private
     * @returns {Array<ReactElement>} DeviceSelector instances.
     */
    const renderSelectors = (key, _renderSelector) => {        
        let config = null;

        if (key === 'videoInput') {
            config = {
                devices: availableDevices.videoInput,
                hasPermission: hasVideoPermission,
                id: key,
                label: 'deviceSelection.selectCamera',
                onSelect: deviceId => onSelectDevice({ userSelectedCameraDeviceId: deviceId }),
                selectedDeviceId: previewVideoTrack && previewVideoTrack.deviceId,
                error: previewVideoTrackError,
                childern: () => {
                    return <VideoInputPreview track={previewVideoTrack} error={previewVideoTrackError} />
                }
            };
        } else if (key === 'audioInput') {
            config = {
                devices: availableDevices.audioInput,
                hasPermission: hasAudioPermission,
                id: key,
                label: 'deviceSelection.selectMic',
                onSelect: deviceId => onSelectDevice({ userSelectedMicDeviceId: deviceId }),
                selectedDeviceId: previewAudioTrack && previewAudioTrack.deviceId,
                error: previewAudioTrackError,
                childern: () => {
                    return <AudioInputTest t={t} previewAudioTrack={previewAudioTrack} />
                }
            };
        } else if (key === 'audioOutput') {
            config = {
                devices: availableDevices.audioOutput,
                hasPermission: hasAudioPermission || hasVideoPermission,
                id: key,
                label: 'deviceSelection.selectAudioOutput',
                onSelect: deviceId => onSelectDevice({ userSelectedAudioOutputDeviceId: deviceId }),
                selectedDeviceId: selectedAudioOutputId || 'default',
                childern: () => {
                    return <AudioOutputPreview deviceId = { selectedAudioOutputId || 'default' } volume={volume} /> 
                }
            };
        }

        return config ? _renderSelector(config) : null;
    }

    /**
     * 전달된 구성을 기반으로 buttonsProps 인스턴스를 만듭니다.
     *
     * @private
     * @param {Object} buttonsProps - The props for the buttonsProps.
     * @returns {ReactElement}
     */
    const renderMutedButton = (buttonsProps) => {
        return (
            <div className="item-buttons">
                <div className="item-mute">
                    { !noShowMuted && <div>
                        <input type="checkbox" id={buttonsProps.key} onChange={buttonsProps.onChange}/>
                        <label htmlFor={buttonsProps.key}> {t(`deviceSelection.${buttonsProps.key}Muted`)} </label>
                    </div> }

                    { buttonsProps.innerChildren && buttonsProps.innerChildren() }
                </div>

                { buttonsProps.childern && buttonsProps.childern() }
            </div>
        )
    }

    /**
     * 카메라, 비디오 on / off를 위한 buttonsProps 인스턴스를 만듭니다. 
     *
     * @private
     * @returns {Array<ReactElement>} buttonsProps instances.
     */
    const renderMutedButtons = (key) =>{
        let config = null;
        if (key === 'videoInput') {
            config = {
                key,
                isDisabled: false,
                onChange: (e) => {
                    const muted  = e.target.checked;
                    onSelectDevice({ userSelectedCameraMuted: muted });
                }
            };
        } else if (key === 'audioInput') {
            config = {
                key,
                isDisabled: false,
                onChange: (e) => {
                    const muted  = e.target.checked;
                    onSelectDevice({ userSelectedAudioMuted: muted });
                },
                innerChildren: () => {
                    return <AudioInputPreview track = { previewAudioTrack } /> 
                },
                childern: () => {
                    return (
                        <div>
                            { renderErrorMsg(key) }
                            <p style={{ color: "#a5a5a5" }}>{ t('deviceSelection.audioInputGuid') }</p>
                        </div>
                    )                        
                }
            };
        } else if (key === 'audioOutput') {
            config = {
                key,
                isDisabled: disablePreviews,
                onChange: (e) => {
                    if(e.target.checked) setVolume(0);
                    else setVolume(0.5);
                },
                innerChildren: () => {
                    return renderSpeakerTest();
                }
            };
        }

        return config ? renderMutedButton(config) : null;
    }

    const renderSpeakerTest = () => {
            const v = volume * 100;
            return (
            disablePreviews ? renderErrorMsg() : 
                <div className="progressbar">
                    <input type="range" name="range"
                        min="0" max="100"
                        step="1"
                        value={v}
                        onChange={(e) => setVolume(e.target.value / 100)}
                        style={{ background: `linear-gradient(to right, #75B1FF 0%, #75B1FF ${v}%, #d5d4d3 ${v}%, #d5d4d3 100%)`}}
                    />
                </div>
            )
        }

    const renderErrorMsg = (key) => {
        if (!disablePreviews) return null;
        return (
            <p style={{ color: "rgb(237, 30, 30)" }}>
                { t(`deviceSelection.${key}IosError`) }
            </p>
        )
    }
    const renderComponent = () => {
        return (
            <>
                <div className = 'device-selectors'>
                    { ['videoInput', 'audioInput', 'audioOutput'].map(name => {
                        return (
                            <div key={name} className={`device-item ${name}`}>
                                <p className='title-sub'> { t(`deviceSelection.${name}`) } </p>

                                { renderSelectors(name, _renderSelector) }
                                { renderMutedButtons(name)}
                            </div>                         
                        )
                    }) }
                </div> 
                { enterRoomButton(previewVideoTrack?.deviceId, previewAudioTrack?.deviceId) }
            </>                
        )
    }

    if (isModal) {
        return (
            renderComponent()
        )
    } else {
        return (
            <div className='device-container' style={ APP.config["device"].background }>
                <div className='device-wrapper' key={name}>
                    <div className='device-content'>
                        <p className='title'>{ t("deviceSelection.title") }</p>
                        <p style={{ margin: "10px 0 20px 0px", color: "#b3b3b3" }}> { t("deviceSelection.guid") }</p>
                        { renderComponent() }
                    </div>
                </div>
            </div>
        )
    }
}

function _mapStateToProps(state, ownProps) {
    const props = getDeviceSelectionProps(state, ownProps.isDevicePage);

    return {
        ...props
    }
}

export default connect(_mapStateToProps)(DeviceSelection);