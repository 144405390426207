import React, { useCallback } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
const Input = React.forwardRef(({ accessibilityLabel, defaultValue, autoFocus, bottomLabel, className, clearable = false, disabled, id, label, maxLength, maxRows, minRows, name, onChange, onKeyPress, placeholder, textarea = false, type = 'text', value }, ref) => {
    const handleChange = useCallback((e) => onChange?.(e.target.value), []);
    return (React.createElement("div", { className: className },
        label && React.createElement("span", { className: 'label' }, label),
        textarea ? (React.createElement(TextareaAutosize, { "aria-label": accessibilityLabel, autoFocus: autoFocus, disabled: disabled, ...(id ? { id } : {}), maxRows: maxRows, minRows: minRows, name: name, onChange: handleChange, onKeyPress: onKeyPress, placeholder: placeholder, ref: ref, value: value })) : (React.createElement("input", { defaultValue: defaultValue, "aria-label": accessibilityLabel, autoFocus: autoFocus, disabled: disabled, ...(id ? { id } : {}), maxLength: maxLength, name: name, onChange: handleChange, onKeyPress: onKeyPress, onKeyDown: onKeyPress, placeholder: placeholder, ref: ref, type: type, value: value })),
        bottomLabel && (React.createElement("span", { className: 'bottom_label' }, bottomLabel))));
});
export default React.memo(Input);
