import axios from "axios";
import { getLocalID } from "../../../features/base/local";
import { getActiveGroupId } from "../../../features/room";

function objectToQueryString(obj) {
    return Object.keys(obj)
        .map(key => {
            const value = obj[key];
            if (!value) return;
            if (Array.isArray(value)) {
                return value.map(v => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&');
            } else {
                return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
            }
        })
        .join('&');
    }

class ExtrnalAPI {
    // 회원 가입 
    // uSignupUser: (Object: { id: String, password: String, email_challenge_uuid?: String }, uuid?: String, access_token: String) => void;
    async uSignupUser({ data, uuid, access_token }) {
        const response = await axios({
            method: "PUT",
            url:  `/svc/user/users/${uuid}/joining`,
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json; charset=UTF-8',
            },
            data: {
                ...data,
                uuid
            }
        });

        return response;
    }

    /**
     * (ACCOUNT)
     * ID, PWD로 로그인 
     * @param {String} id 
     * @param {String} password 
     * @param {String} enc_type 
     */
    async uLoginUser({ id, password, enc_type }) {
        const response = await axios({
            method: "POST",
            url:  "/svc/user/issue-auth-token/by-password",
            data: {
                auth_name: id,
                auth_password: btoa(password),
                enc_type: "base64"
            },
        });
    
        return response;
    }

    /**
     * (ACCOUNT)
     * ACCESS_TOKEN으로 사용자 로그인 
     * @param {String} access_token 
     * @returns 
     */
    async uGetUser(access_token) {
        const response = await axios({
            method: "POST",
            url:  "/svc/user/verify-auth-token/by-input",
            data: access_token
        });

        return response;
    }

    // 임시 사용자 객체 생성 
    // uCreateTempUser: () => void;
    async uCreateTempUser() {
        const response = await axios({
            method: "POST",
            url:  "/svc/user/users"
        });
    
        return response;
    }
    
    // 중복 로그인 확인 
    // uCheckOccupied: (Object: { meeting_uuid: String, user_id: String }) => void;
    async uCheckOccupied({ meeting_uuid, user_id }) {
        const response = await axios({
            method: "GET",
            url:  `/svc/meeting/members?meeting_uuid=${meeting_uuid}&user_uuid=${user_id}&status=occupied`
        });
    
        return response;
    }
    // 중복 사용자 강제 퇴장
    // uKickOccupied: (Object: { meeting_uuid: String, aToken: String, user_id: String }) => void;
    async uKickOccupied({ meeting_uuid, aToken, user_id }) {
        const response = await axios({
            method: "POST",
            url:  `/api/meeting/v1/meetings/${meeting_uuid}/kick`,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            }, data: {
                user_id
            }
        });

        return response;
    }

    /**
     * (ROOM) 방 강제 종료
     * @returns 
     */
    async rforceEndRoom({ aToken, meeting_uuid }) {
        const response = await axios({
            method: "POST",
            url:  `/api/meeting/v1/meetings/${meeting_uuid}/close`,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        });
    
        return response;
    }

    // 방 조회 
    // rSearchRoom: (Object: { data: { code?: String, uuid?: String }, aToken: String, offset: Number, limit: Number }) => void;
    async rSearchRoom({ data, aToken }) {
        const offset = data.offset || 0;
        const limit = data.limit || 20;
        
        const url = objectToQueryString(Object.assign({}, { ...data }, { offset }, { limit }));

        const response = await axios({
            method: "GET",
            url:  "/svc/meeting/meetings?" + url,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            },
        });
    
        return response;
    }

    // 방 객체 생성
    // rCreateRoom: (String) => void;
    async rCreateRoom(aToken) {
        const response = await axios({
            method: "POST",
            url:  "/svc/meeting/meetings",
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            },
        });
    
        return response;
    }

    // 방 생성 (시작)
    // rHoldRoom: (Object: { aToken: String, meeting_uuid: String, name: String }) => void;
    async rHoldRoom({ aToken, meeting_uuid, name }) {
        const response = await axios({
            method: "POST",
            url:  `/svc/meeting/meetings/${meeting_uuid}/hold`,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            },
            data: {
                "entry_option": null,
                name,
                "password": null,
                "progress_duration": 3600000
            }
        });
    
        return response;
    }
    /**
     * 방 예약
     * rReserveRoom: (Object: {aToken: String, meeting_uuid: String, data: Object})
     */
    async rReserveRoom({ aToken, meeting_uuid, data }) {
        const response = await axios({
            method: "POST",
            url:  `/svc/meeting/meetings/${meeting_uuid}/book`,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            },
            data: {
                ...data,
                // start_time: new Date(Date.parse(data.start_time)).toISOString(),
            }
        });
    
        return response;
    }
    /**
     * 방 업데이트
     * rUpdateRoomProperty :  (Object: {aToken: String, meeting_uuid: String, data: Object}) => void
     */
    async rUpdateRoomProperty({ aToken, meeting_uuid, data }) {
        const response = await axios({
            method: "PUT",
            url:  `/svc/meeting/meetings/${meeting_uuid}/property`,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            },
            data: {
                ...data
            }
        });
    
        return response;
    }

    /**
     * 예약된 방 회의 삭제
     */
    async rDeleteReservationRoom({ aToken, meeting_uuid }) {
        const response = await axios({
            method: "DELETE",
            url:  `/api/meeting/v1/meetings/${meeting_uuid}`,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        });
    
        return response;
    }

    /**
     * 예약된 회의에 초대 
     */
    async rInviteReservationRoom({ aToken, meeting_uuid, data }) {
        const response = await axios({
            method: "PUT",
            url:  `/svc/meeting/meetings/${meeting_uuid}/invitations`,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            },
            data: {
                items: data
            }
        });
    
        return response;
    }
    
    /**
     * 회의실 초대 메일 전송 
     */
    async rSendInviteRoom({ meeting_uuid, aToken }) {
        const response = await axios({
            method: "POST",
            url:  `/svc/meeting/meetings/${meeting_uuid}/invitations/notify`,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            },
            data: {
                method: {
                    email: true,
                    sms: false
                }
            }
        });
    
        return response;
    }

    /**
     * Document File
     * 
     * 1. 문서 변환 
     * dConvertDocument: (Object: { file: Object, meeting_uuid: String }) => void;
     */
    async dConvertDocument({ file, meeting_uuid }) {
        const response = await axios({
            method: "POST",
            url:  `/svc/meeting/meetings/${meeting_uuid}/documents`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: file
        });
    
        return response;
    }

    /**
     * 채팅
     * 
     * cConvertFile: (Object: { file: Object }) => void;
     */
    async cConvertFile({ file }) {
        const response = await axios({
            method: "POST",
            url:  "/fileshare",
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: file
        });
    
        return response;
    }

    /**
     * 서버 녹화 
     */
    async createRecordResoure({ aToken, meeting_uuid, group_id = '' }) {
        const response = await axios({
            method: "POST", 
            url:  `/api/meeting/v1/meetings/${meeting_uuid}/recording/resources`,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            },
            data: {
                group_id
            }
        });
        
        return response;
    }

    async appendRecordStreaming({ aToken, data, recording_id, resource_id }) {
        const formData = new FormData();
        formData.append("file", data);

        const response = await axios({
            method: "POST", 
            url:  `/api/recording/v1/recordings/${recording_id}/resources/${resource_id}/append`,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                // 'Content-Type': 'multipart/form-data',
            },
            data: formData            
        });
        
        return response;
    }


    /**
     * 그룹 
     */
    // 그룹 사용자 이름 조회 
    async uGetMember({ aToken, data }) {
        const offset = data.offset || 0;
        const limit = data.limit || 200;

        const url = objectToQueryString(Object.assign({}, data, {offset}, {limit}));

        const response = await axios({
            method: "GET",
            url:  `/api/meeting/v1/members?` + url,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        });
        
        return response;
    }
    // 그룹 리스트 조회 
    async gGroupList({ aToken, data }) {
        const offset = data.offset || 0;
        const limit = data.limit || 200;
        const url = objectToQueryString(Object.assign({}, data, {offset}, {limit}));

        const response = await axios({
            method: "GET",
            url:  '/api/meeting/v1/meetings/by-group?' + url,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        });
    
        return response;
    }
    // 그룹 생성
    async gCreateGroup({ aToken, meeting_uuid, name }) {
        const response = await axios({
            method: "POST",
            url:  `/api/meeting/v1/meetings/${meeting_uuid}/groups`,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            }, 
            data: {
                name
            }
        });
    
        return response;
    }
    // 그룹 수정
    async gModifyGroup({ aToken, meeting_uuid, group_id, data }) {
        const response = await axios({
            method: "POST",
            url:  `/api/meeting/v1/meetings/${meeting_uuid}/groups/${group_id}/update`,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            }, 
            data
        });
    
        return response;
    }
    // 전체 그룹 수정 
    async gModifyAllGroup({ aToken, meeting_uuid, data }) {
        const response = await axios({
            method: "POST",
            url:  `/api/meeting/v1/meetings/${meeting_uuid}/groups-update`,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            }, 
            data
        });
    
        return response;
    }
    // 그룹 삭제 
    async gDeleteGroup({ aToken, meeting_uuid, group_id }) {
        const response = await axios({
            method: "DELETE",
            url:  `/api/meeting/v1/meetings/${meeting_uuid}/groups/${group_id}`,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        });
    
        return response;
    }

    // 랜덤 그룹 생성
    async gRandomizeGroup({ aToken, meeting_uuid, data }) {
        const response = await axios({
            method: "POST",
            url:  `/api/meeting/v1/meetings/${meeting_uuid}/groups/randomize`,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            }, 
            data
        });
    
        return response;
    }

    /**
     * (GROUP) 그룹 할당
     * @param {object} data
     * @param {string} group_id
     */
    async gAssignGroup({ aToken, meeting_uuid, group_id = '', data }) {
        const response = await axios({
            method: "POST",
            url:  `/api/meeting/v1/meetings/${meeting_uuid}/groups/${group_id}/assign`,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            }, 
            data
        });
    
        return response;
    }

    async pPartnerLogin({ token, user_id }) {
        const response = await axios({
            method: "POST",
            url:  '/api/partner/v1/klassmate/users/verify-and-auth',
            data: {
                token,
                user_id
            }
        });

        return response;
    }

    /**
     * (VOD) 리스트 조회하기 
     */
    async vGetVodList({ aToken, data }) {
        const offset = data.offset || 0;
        const limit = data.limit || 10;
        const url = objectToQueryString(Object.assign({}, data, {orderby: 'creation_time DESC'}, {offset}, {limit}));

        const response = await axios({
            method: "GET",
            url:  '/api/album/v1/albums?' + url,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        });

        return response;
    }

    /**
     * (VOD) 등록하기
     * @param {File} file 
     * @param {string} name 
     * @param {string} owner_id 
     * @returns 
     */
    async vRegistVod({ aToken, data, name, owner_id  }) {
        const response = await axios({
            method: "POST",
            url:  '/api/album/v1/albums',
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'multipart/form-data',
            },
            data: {
                ...data,
                name,
                owner_id
            }
        });

        return response;
    }

    /**
     * (VOD) 삭제하기
     * @param {string} album_id 
     * @param {string} owner_id 
     */
    async vDeleteVod({ aToken, album_id, owner_id }) {
        const response = await axios({
            method: "POST",
            url:  `/api/album/v1/albums/${album_id}/delete`,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            },
            data: {
                owner_id
            }
        });

        return response;
    }

    /**
     * (VOD) 회의실 내 VOD 리스트 틍록
     * @param {object[]} shared_vods 
     * @param {string} group_id 
     * @returns 
     */
    async vChangeVodList({ aToken, meeting_uuid, method, shared_vods }) {
        const group_id = getActiveGroupId(APP.store.getState);

        const response = await axios({
            method: "POST",
            url:  `/api/meeting/v1/meetings/${meeting_uuid}/change-shared-vod-list`,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            },
            data: {
                group_id,
                shared_vods,
                method
            }
        });

        return response;
    }

    /**
     * (VOD) 회의실 내 사용된 url 등록
     * @param {*} param0 
     * @returns 
     */
    async vRegistVodStreaming({ aToken, album_id, owner_id }) {
        const response = await axios({
            method: "POST",
            url:  `/api/album/v1/albums/${album_id}/streamings`,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            },
            data: {
                owner_id
            }
        });

        return response;
    }


    /**
     * 
     * @param {*} param0 
     * @returns 
     */
    async vGenerateStreamingAuth({ meeting_uuid, aToken, member_id, streaming_id }) {
        const group_id = getActiveGroupId(APP.store.getState);
        const user_id = getLocalID(APP.store.getState);
        const response = await axios({
            method: "POST",
            url:  `/api/meeting/v1/meetings/${meeting_uuid}/generate-streaming-auth`,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            },
            data: {
                group_id,
                streaming_id,
                user_id
            }
        });

        return response;
    }

    /**
     * (ATTENDANCE) 출석체크 가져오기
     */
    async aGetAttendancesCheck({ aToken, meeting_uuid }) {
        const offset = 0;
        const limit = 200;
        const url = objectToQueryString(Object.assign({}, { meeting_uuid }, {orderby: 'creation_time DESC'}, {offset}, {limit}));

        const response = await axios({
            method: "GET",
            url:  "/svc/meeting/attendances?" + url,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        });

        return response;
    }

    /**
     * (ATTENDANCE) 출석체크 생성하기
     * @param {*} data 
     * @returns 
     */
    async aCreateAttendances({ meeting_uuid, aToken, name, check_start_time, check_duration }) {
        const response = await axios({
            method: "POST",
            url:  `/svc/meeting/meetings/${meeting_uuid}/attendances`,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            },
            data: {
                name, 
                check_start_time, 
                check_duration
            }
        });

        return response;
    }

    async aCheckAttendances({ attendance_id, aToken }) {
        const response = await axios({
            method: "POST",
            url:  `/svc/meeting/attendances/${attendance_id}/check`,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        });
    
        return response;
    }

    /**
     * (Chat) 채팅 저장
     */
    async cSaveChat({ meeting_uuid }) {
        const response = await axios({
            method: "GET",
            url:  `/api/meeting/v1/meetings/${meeting_uuid}/chat-logs-as-csv`,
            headers: {
                'Authorization': 'Bearer ' + aToken,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        });

        return response;
    }

    /**
     * (SURVEY) 설문 조사 가져오기
     */
    async sGetSurveyList({ meeting_uuid, survey_uuid }) {
        let response = null;
        if (survey_uuid) {
            response = await axios.get(`/survey/${meeting_uuid}/${survey_uuid}/info`);
        } else {
            response = await axios.get(`/survey/${meeting_uuid}`);
        }
            
        return response;
    }

    /**
     * (KLASS MATE - DOCUMENT) 
     * 문서 가져오기
     */
    async kGetKlassDocument({
        meeting_uuid
    }) {
        const offset = 0;
        const limit = 200;
        const url = objectToQueryString(Object.assign({}, { meeting_uuid }, {offset}, {limit}));


        const response = await axios({
            method: "GET",
            url:  `/svc/meeting/documents?` + url,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            }
        });

        return response;
    }
}

export default new ExtrnalAPI();
