import { SET_ROOM_PASSWORD, SET_ROOM_LOCKED, TOGGLE_E2EE } from './actionTypes';
export function setRoomPassword(password, isRemote = false) {
    return async (dispatch) => {
        if (!isRemote) {
            // API를 통한 방 상태 업데이트 (password)
            APP.API.udpateReservationRoom({ password })
                .then(response => {
                if (response.complete) {
                    dispatch({
                        type: SET_ROOM_PASSWORD,
                        password
                    });
                }
                else {
                    APP.UI.alertMessage(response.data);
                }
            });
        }
        else {
            dispatch({
                type: SET_ROOM_PASSWORD,
                password
            });
        }
    };
}
export function setRoomLocked(locked) {
    return {
        type: SET_ROOM_LOCKED,
        locked
    };
}
export function toggleE2EE(enabled) {
    return {
        type: TOGGLE_E2EE,
        enabled
    };
}
