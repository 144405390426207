import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toggleDialog } from "../base/dialog";
import { getLocalAuthName, logout } from "../base/local";
import { ClickButton } from "../base/ui";
import { RoomConfigurationContainer } from "../room-config";
import LoginContainer from "./LoginContainer";
import { SubAccount } from "./components";

function AccountContainer({
    isMobile
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const auth_name = useSelector(state => getLocalAuthName(state));
    const [ list, setList ] = useState([]);

    /**
     * (ACTION)
     * 로그인 
     */
    const openLoginDialog = () => {
        dispatch(toggleDialog(LoginContainer, { isDialog: true }));
    }

    /**
     * (ACTION)
     * 로그아웃
     */
    const handlerLogout = () => {
        dispatch(logout());
    }

    /**
     * (ACTION)
     * 마이페이지 
     */
    const openReservationDialog = () => {
        dispatch(toggleDialog(RoomConfigurationContainer, { isDialog: true }));
    }

    useEffect(() => {
        let list = ['login'];
        if (auth_name) list = [isMobile && 'reservation', 'logout'];

        setList(list);
    }, [auth_name, isMobile], shallowEqual);

    const renderButtons = (item, styles) => {
        switch (item) {
            case 'login': 
                return (
                    <ClickButton key="login" className="sub_accout" styles={styles}
                        label={t('account.goLogin')} onClick={ openLoginDialog } />
                )

            case 'logout':
                return (
                    <ClickButton key="logout" className="sub_accout" styles={styles}
                        label={t('account.logout')} onClick={ handlerLogout } />
                )

            case 'reservation':
                return (
                    <ClickButton key="reservation" className="sub_accout" 
                        styles={styles}
                        label={t('account.mypage')} onClick={ openReservationDialog } />
                )
        }
    }

    const renderItems = (styles) => {
        return (
            list && list.map(item => renderButtons(item, styles))
        )
    }
    
    return (
        <SubAccount auth_name={auth_name} renderItems={renderItems} />
    )
}

export default React.memo(AccountContainer);