import React, { useMemo } from "react";
import VodListContainer from "./VodListContainer";
import { VOD_PAGE_TYPE } from "./constant";
const VodContainer = React.memo(({ page, setPage }) => {
    const renderVodBody = useMemo(() => {
        switch (page) {
            case VOD_PAGE_TYPE.VOD_LIST:
                return React.createElement(VodListContainer, null);
        }
    }, [page]);
    return renderVodBody;
});
export default VodContainer;
