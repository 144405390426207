let filterSupport;
/**
 * 컨텍스트 필터 지원을 확인합니다.
 *
 * @returns {boolean} 필터가 지원되면 True이고 필터가 브라우저에서 지원되지 않으면 False입니다.
 */
export function checkBlurSupport() {
    if (typeof filterSupport === 'undefined') {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        filterSupport = typeof ctx?.filter !== 'undefined';
        canvas.remove();
    }
    return filterSupport;
}
/**
 * 가상 배경이 활성화되어 있는지 확인합니다.
 *
 * @param {IReduxState} state - 앱의 상태입니다.
 * @returns {boolean} 가상 배경이 활성화된 경우 True이고 가상 배경이 비활성화된 경우 False입니다.
 */
export function checkVirtualBackgroundEnabled(state) {
    return state['features/base/config'].disableVirtualBackground !== true;
}
/**
 * Blob을 base64로 변환합니다.
 *
 * @param {Blob} blob - 정보를 추가할 링크입니다.
 * @returns {Promise<string>}
 */
export const blobToData = (blob) => new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result?.toString());
    reader.readAsDataURL(blob);
});
/**
 *Blob을 base64로 변환합니다.
 *
 * @param {string} url - The image url.
 * @returns {Object} - Returns the converted blob to base64.
 */
export const toDataURL = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const resData = await blobToData(blob);
    return resData;
};
/**
 * 이미지 크기를 조정하고 원본 비율 조정합니다.
 *
 * @param {Object} base64image - Base64 image extraction.
 * @param {number} width - Value for resizing the image width.
 * @param {number} height - Value for resizing the image height.
 * @returns {Promise<string>}
 *
 */
export function resizeImage(base64image, width = 1920, height = 1080) {
    // 사파리에서 이용할때 이미지 비동기 로드 특성 처리 해야함.
    return new Promise(resolve => {
        const img = document.createElement('img');
        img.onload = function () {
            // Create an off-screen canvas.
            const canvas = document.createElement('canvas');
            // Set its dimension to target size.
            const context = canvas.getContext('2d');
            canvas.width = width;
            canvas.height = height;
            // Draw source image into the off-screen canvas.
            // TODO: keep aspect ratio and implement object-fit: cover.
            context?.drawImage(img, 0, 0, width, height);
            // Encode image to data-uri with base64 version of compressed image.
            resolve(canvas.toDataURL('image/jpeg', 0.5));
        };
        img.src = base64image;
    });
}
/**
 * 특정 시간 간격으로 약속에 대한 래퍼 만들기.
 *
 * @param {number} milliseconds - The number of milliseconds to wait the specified
 * {@code promise} to settle before automatically rejecting the returned
 * {@code Promise}.
 * @param {Promise} promise - The {@code Promise} for which automatic rejecting
 * after the specified timeout is to be implemented.
 * @returns {Promise}
 */
export function timeout(milliseconds, promise) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            reject(new Error('408'));
            return;
        }, milliseconds);
        promise.then(resolve, reject);
    });
}
