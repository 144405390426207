import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { ClickButton } from "../../ui";
export const AddFile = React.memo(({ handlerAddVod, isIcon = false, isDialog = false, }) => {
    const { t } = useTranslation();
    const inputRef = useRef(null);
    const onChange = e => {
        try {
            const file = e.target.files[0];
            handlerAddVod(file);
            if (inputRef.current)
                inputRef.current.value = '';
        }
        catch (err) {
            console.log(err);
        }
    };
    return (React.createElement("label", { htmlFor: "add_vod" },
        React.createElement("input", { ref: inputRef, id: "add_vod", type: "file", accept: "video/mp4 video/webm", style: { display: "none" }, onChange: onChange }),
        React.createElement(ClickButton, { size: 22, className: "add_btn", color: APP.config.style.playerColor, isText: !isIcon || isDialog, icon: (isIcon || isDialog) ? 'fileAdd' : null, label: (isIcon && !isDialog) ? '' : t(`vod.fileAdd`) })));
});
