import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RESERVATION_PAGE_TYPE } from "../base/reservation/constants";
import { ClickButton } from '../base/ui';
import { RoomConfigurationHead } from "./components";
import { ROOM_PROPERTY_TYPES } from './constant';
const RoomConfigurationHeadContainer = React.memo(({ page, setPage }) => {
    const { t } = useTranslation();
    const renderPageTypeBtn = useMemo((styles) => {
        if ([RESERVATION_PAGE_TYPE.REGIST, RESERVATION_PAGE_TYPE.MODIFY].includes(page))
            return null;
        return ROOM_PROPERTY_TYPES.map(name => (React.createElement(ClickButton, { key: name, className: page === name ? "isFocus" : "", isText: true, styles: styles, label: t(`reservation.${name}`), onClick: () => setPage(name) })));
    }, [t, page]);
    const headChild = useMemo(() => {
        return (React.createElement(ClickButton, { isText: true, label: t(`reservation.regist`), onClick: () => setPage(RESERVATION_PAGE_TYPE.REGIST) }));
    }, [page]);
    return (React.createElement(RoomConfigurationHead, { renderPageTypeBtn: renderPageTypeBtn, headChild: headChild }));
});
export default RoomConfigurationHeadContainer;
