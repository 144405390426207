import React, { useCallback } from 'react';
function RadioButton({ className, guid, selectedOption, setSelectedOption, list }) {
    const handleOptionChange = useCallback((event) => {
        setSelectedOption && setSelectedOption(event.target.value);
    }, [setSelectedOption]);
    return (React.createElement("div", { className: className },
        guid && React.createElement("div", { className: 'guid' },
            " ",
            guid,
            " "),
        React.createElement("div", { className: 'radio' }, list.map(item => (React.createElement("label", { key: item.id },
            React.createElement("input", { type: "radio", value: item.id, checked: selectedOption === item.id, onChange: handleOptionChange }),
            React.createElement("div", null, item.label)))))));
}
export default React.memo(RadioButton);
