import { toggleDialog } from '../base/dialog';
import { toggleLoad } from '../base/loading';
import { getLocalID, getSearchData, loginById } from '../base/local';
//@ts-ignore
import { storage } from '../base/util';
import AbstractRoomLock from '../room-lock/components/AbstractRoomLock';
import { LEAVE_ROOM, SET_ACTIVE_GROUP_ID, SET_ROOM_JOINED, SET_ROOM_OPTION, SET_ROOM_PERMISSIONS } from './actionTypes';
import { checkRoomPassword } from './functions';
/**
 * 방 정보 세팅 ( 옵션, 비밀번호, 미디어서버, 회의실 오픈 시간 )
 * @param {RoomData} data
 * @returns
 */
export function setRoomOption(data) {
    typeof APP === 'object' && APP.API.setMeetingUuid(data.option?.uuid || null);
    return {
        type: SET_ROOM_OPTION,
        option: data.option,
        password_checking: data?.password_checking,
        media_server_info: data?.media_server_info,
        start_time: data?.start_time
    };
}
/**
 * 실질적인 입장이 아닌 방 입장 (만약 로그인이 없는 되어있지않은경우 임시 로그인)
 * @param {RoomOption} data
 * @returns
 */
export function tempEnterRoom(data) {
    return async (dispatch, getState) => {
        if (!getLocalID(getState) && !getSearchData('storage_key')) {
            const response = await APP.API.createTempUser();
            if (response.complete) {
                const data = response.data;
                await dispatch(loginById(data.auth_name, data.auth_password));
            }
            else {
                /**
                 * (Fix me)
                 */
                console.log(" temp login error ");
                return;
            }
        }
        const response = await APP.API.searchRoom(data);
        if (response.complete) {
            if (response.data.start_time) {
                const start_time = Date.parse(response.data.start_time);
                response.data.start_time = start_time;
            }
            // if (response.data.shared_vod_list) {
            //     APP.vodList = response.data.shared_vod_list['lobby'];
            // }
            window.parent.postMessage(response.data.room.option.code, '*');
            dispatch(setRoomOption({ ...response.data.room, start_time: response.data.start_time }));
        }
        else {
            await APP.management.leave();
            APP.UI.alertMessage(response.data);
        }
    };
}
/**
 * 방 생성
 * @param {String} name
 * @returns
 */
export function createRoom(name) {
    return async (dispatch, getState) => {
        const response = await APP.API.createRoom(name);
        if (response.complete) {
            const room_uuid = response.data;
            if (room_uuid)
                dispatch(tempEnterRoom({ uuid: room_uuid }));
        }
        else {
            APP.UI.alertMessage(response.data);
        }
        return response;
    };
}
// 사용자 입장 ( 실직적 방 입장 )
// 회의실 입장 ( 실직적 방 입장 )
export function enterRoom() {
    return async (dispatch, getState) => {
        dispatch(toggleLoad(true));
        const user_id = getLocalID(getState);
        let role_name;
        if (APP.config.name === 'class') {
            role_name = storage.get("role");
        }
        // 중복 사용자 로그인 확인 
        const response = await APP.API.checkOccupiedUser(user_id);
        if (response.complete === false) {
            let data = null;
            // 중복 로그인이 있는 경우 or error 
            if (response.data === 'dup') {
                // 중복 사용자 강퇴 message 
                if (navigator.product === 'ReactNative') {
                    APP.UI.confirmMessage('account.dupLogin', "account.dupLoginGuid", () => {
                        //로비 페이지로 이동 z
                    }, () => {
                        checkRoomStatusAndJoinRoom({ dispatch, getState }, { user_id, role_name }, true);
                    });
                    return;
                }
                else {
                    const answer = await APP.UI.confirmMessage("account.dupLoginGuid");
                    if (answer) {
                        // 중복 사용자 
                        checkRoomStatusAndJoinRoom({ dispatch, getState }, { user_id, role_name }, true);
                        return;
                    }
                    else {
                        data = response.data;
                    }
                }
            }
            else {
                data = response.data;
            }
            if (data) {
                if (data !== 'dup')
                    APP.UI.alertMessage(data);
                dispatch(toggleLoad(false));
                return;
            }
        }
        checkRoomStatusAndJoinRoom({ dispatch, getState }, { user_id, role_name }, false);
    };
}
async function checkRoomStatusAndJoinRoom({ dispatch, getState }, { user_id, role_name }, isDup) {
    let data = undefined;
    if (isDup) {
        // 중복 사용자 강퇴
        const kickResponse = await APP.API.kickOccupiedUser(user_id);
        if (kickResponse.complete === false) {
            data = kickResponse.data;
            APP.UI.alertMessage(data);
            //로비 페이지로 이동 
            return;
        }
    }
    // 방 입장
    // 회의실 비밀번호 여부 확인 
    // 잠겨있는 경우
    if (await checkRoomPassword(getState)) {
        // 비밀 번호 창 
        dispatch(toggleDialog(AbstractRoomLock, { enterRoom: roomJoin }));
    }
    else {
        roomJoin(dispatch, null, role_name);
    }
}
/**
 * 회의실 입장
 * @param dispatch
 * @param password
 * @param role_name
 */
async function roomJoin(dispatch, password = null, role_name) {
    // JITSI 입장 -> MATE 입장 
    APP.management.join(password, role_name)
        .then(() => {
        // 페이지 이동 
        dispatch(setRoomJoined(true));
        dispatch(toggleLoad(false));
    })
        .catch(async (err) => {
        await APP.management.leave();
        // 에러 메세지 표출 - mate 오류 
        APP.UI.alertMessage(err);
    });
}
/**
 * 회의실 입장 여부
 * @param {boolean} joined
 * @returns
 */
export function setRoomJoined(joined) {
    return {
        type: SET_ROOM_JOINED,
        joined
    };
}
/**
 * 회의실 퇴장
 * @returns
 */
export function leaveRoom() {
    return {
        type: LEAVE_ROOM
    };
}
/**
 * 현재 입장되어있는 그룹 아이디
 * @param {string} group
 * @returns
 */
export function setActiveGroupId(group_id) {
    return {
        type: SET_ACTIVE_GROUP_ID,
        group_id
    };
}
/**
 * 현재 룸에 대한 권한,
 * @param {string[]} permissions
 * @returns
 */
export function setRoomPermissions(permissions) {
    return {
        type: SET_ROOM_PERMISSIONS,
        permissions
    };
}
