import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { enterRoom, getRoomOption, isRoomJoined } from "../../room";
import { toggleLoad } from "../loading";
import { getDisplayName } from "../settings";
import { appWillMount, appWillUnmount } from "./actions";
import { BaseApp } from "./components";
import { PAGE_TYPE } from "./constants";
const BaseContainer = () => {
    const dispatch = useDispatch();
    const { roomUid, displayname, isJoined, appLoad } = useSelector((state) => {
        const roomUid = getRoomOption(state)?.uuid;
        const displayname = getDisplayName(state);
        const isJoined = isRoomJoined(state);
        const appLoad = state['features/base/app'].app ? true : false;
        return {
            roomUid, displayname, isJoined, appLoad
        };
    }, shallowEqual);
    const { enableLobby, enableNickname } = useSelector((state) => state['features/base/settings']);
    const [page, setPage] = useState(PAGE_TYPE.LOBBY);
    const _mount = () => dispatch(appWillMount(true));
    const _unmount = () => dispatch(appWillUnmount());
    const handlerPreventMenu = e => e.preventDefault();
    const leave = () => APP.management.leave();
    useEffect(() => {
        _mount();
        window.addEventListener('contextmenu', handlerPreventMenu);
        window.addEventListener('beforeunload', leave);
        return () => {
            leave();
            _unmount();
            dispatch(toggleLoad(false));
            window.removeEventListener('beforeunload', leave);
            window.removeEventListener('contextmenu', handlerPreventMenu);
        };
    }, []);
    // useEffect(() => {
    //     if (!appLoad) return;
    //     const test = async () => {
    //         await dispatch(tempEnterRoom({ code: "a6feae" }));
    //         dispatch(enterRoom());
    //     };
    //     test();
    // }, [appLoad]);
    const handlerChangePage = (page) => {
        if (page === PAGE_TYPE.LOBBY) {
            if (!enableLobby)
                setPage(PAGE_TYPE.ERROR);
            else
                setPage(PAGE_TYPE.LOBBY);
        }
        else if (page === PAGE_TYPE.NICKNAME) {
            if (!enableNickname)
                setPage(PAGE_TYPE.ERROR);
            else
                setPage(PAGE_TYPE.NICKNAME);
        }
        else {
            setPage(page);
        }
    };
    useEffect(() => {
        if (appLoad) {
            if (isJoined)
                setPage(PAGE_TYPE.MAIN);
            else {
                if (roomUid) {
                    if (navigator.product === 'ReactNative') {
                        if (!displayname)
                            handlerChangePage(PAGE_TYPE.NICKNAME);
                        else
                            dispatch(enterRoom());
                    }
                    else {
                        if (!displayname)
                            handlerChangePage(PAGE_TYPE.NICKNAME);
                        else
                            handlerChangePage(PAGE_TYPE.DEVICE);
                    }
                }
                else {
                    handlerChangePage(PAGE_TYPE.LOBBY);
                }
                dispatch(toggleLoad(false));
            }
        }
        else {
            dispatch(toggleLoad(true));
        }
    }, [appLoad, isJoined, roomUid, displayname]);
    if (!appLoad)
        return null;
    return React.createElement(BaseApp, { page: page, roomUid: roomUid, handlerChangePage: handlerChangePage });
};
export default BaseContainer;
