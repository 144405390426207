import Recorder from "../../../modules/server/recorder";
import { MEDIA_TYPE } from "../media";
import { getLocalIDMember, setMemberRecord } from "../members";
import { showNotification } from "../notification/actions";
import { MiddlewareRegistry } from "../redux";
import { TRACK_ADDED, TRACK_REMOVED } from "../tracks";
import { ServerTest } from "./ServerTest";
import { SET_RECORDING_ENABLED, TEMP_SERVER_RECORD_START, TEMP_SERVER_RECORD_STOP } from "./actionTypes";
import { addRecordResource, stopServerRecord } from "./actions";
import { RECORDING_TYPE } from "./constant";
import { getAudioTrackInRoom } from "./functions";


let serverClass;
MiddlewareRegistry.register(store => next => action => {
    const { dispatch, getState } = store;

    switch (action.type) {
        case TEMP_SERVER_RECORD_START:
            const startRecord = () => dispatch(addRecordResource());
            const stopRecord = () => dispatch(stopServerRecord());

            const serverTest = new ServerTest(startRecord, stopRecord);
            const recorder = new Recorder(serverTest);

            if (!recorder.isSupportedBrowser()) {
                dispatch(showNotification({
                    appearance: "error",
                    titleKey: "record.notAvaliable"
                }));
                return;
            }
            
            const tracks = getAudioTrackInRoom(getState);
            recorder.loadAudioStraem(new Set(tracks.values()));
            // start record 
            recorder.start(false);

            serverClass = recorder;
            break;

        case TEMP_SERVER_RECORD_STOP:
            if (serverClass) {
                serverClass.stop();
            }
            break;
            
        case TRACK_ADDED:
            if (action.track.track.type === MEDIA_TYPE.AUDIO) {
                if (serverClass) {
                    serverClass.connectAudio(action.track.track);
                }
            }
            break;

        case TRACK_REMOVED:
            if (action.track.type === MEDIA_TYPE.AUDIO) {
                if (serverClass) {
                    serverClass.disconnectAudio(action.track);
                }
            }
            
            break;

        case SET_RECORDING_ENABLED:
            if (action.recordType === RECORDING_TYPE.SERVER) {
                const localId = getLocalIDMember(getState);
                if (!action.enabled) serverClass = null;
                else {
                    if (serverClass) {
                        serverClass.recordClass && serverClass.recordClass.useResource(action.option);
                    } else break;
                }

                dispatch(setMemberRecord(localId, action.enabled));
            }
            break;
            
    }
    return next(action);
});