import React, { useMemo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { DEFAULT_INDEX, DOCUMENT_MODE, VOD_MODE, WHITE_MODE } from "../constants";
const DocumentImage = React.memo(({ list, isFocus, focusIndex, permission, handlerFocusItem }) => {
    const imageList = isFocus ? list : list.slice(0, 1);
    return (React.createElement("div", { className: `item_image ${permission ? 'cursor' : 'null'}` }, imageList.map((item, index) => {
        if (!item?.thumb_url)
            return null;
        return (React.createElement(LazyLoadImage, { key: item?.thumb_url, className: index === focusIndex ? "focus_img" : "img", draggable: false, src: item.thumb_url, onClick: () => handlerFocusItem(index), width: 130, height: 130 }));
    })));
});
const DocumentItem = React.memo(({ uuid, focus, list, shared, config, complete, permission, isFocus, focusIndex, labelList, renderLabelBtn, handlerFocusItem }) => {
    const isConverting = useMemo(() => (complete && list) || config === WHITE_MODE ? false : true, [complete, list, config]);
    const _renderLabel = useMemo(() => {
        return (React.createElement("div", { key: "label", className: `item_label ${focus ? 'focus' : ''} ${permission ? 'cursor' : ''}`, style: { textAlign: "center" } },
            labelList.map((label, index) => {
                if (label === '')
                    return null;
                return (React.createElement("div", { key: index, className: `label_child ${label}`, onClick: label === 'title' ? () => !isConverting && handlerFocusItem(DEFAULT_INDEX) : () => null }, renderLabelBtn(label)));
            }),
            shared && React.createElement("div", { className: "share_label" })));
    }, [uuid, labelList, shared, focus, permission, handlerFocusItem]);
    const _renderImage = () => {
        if (config === WHITE_MODE && APP.config.name === 'class') {
            return (React.createElement("div", { key: "image", className: "item_image_wrapper" },
                React.createElement("div", { className: `item_image ${permission ? 'cursor' : 'null'}` },
                    React.createElement("img", { className: focus ? "focus_img" : "img", draggable: "false", width: "130", height: "130", onClick: () => handlerFocusItem(DEFAULT_INDEX) }))));
        }
        if (config === VOD_MODE && !complete) {
            return (React.createElement("div", { key: "test", style: { color: '#000', textAlign: "center" } }, " \uBCC0\uD658\uC911 "));
        }
        if (config === DOCUMENT_MODE) {
            return (React.createElement("div", { key: "image", className: "item_image_wrapper" }, list && !isConverting ?
                React.createElement(DocumentImage, { list: list, isFocus: isFocus, focusIndex: focusIndex, permission: permission, handlerFocusItem: handlerFocusItem })
                : React.createElement("div", null, " \uBCC0\uD658\uC911 ")));
        }
    };
    const contents = [
        _renderLabel,
        _renderImage()
    ].filter(Boolean);
    return (React.createElement("div", { className: "share_item" }, contents));
});
export default DocumentItem;
