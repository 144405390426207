import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Input } from "../ui";
import { addMessage } from "./action";
import { throttle } from "lodash";
const ChatInputConatainer = React.memo(() => {
    const inputRef = useRef();
    const dispatch = useDispatch();
    const handlerAddEmoji = (data) => {
        if (data?.emoji) {
            if (inputRef.current) {
                inputRef.current.value = inputRef.current.value + data?.emoji;
                inputRef.current.focus();
            }
        }
    };
    useEffect(() => {
        APP.eventManager.subscribe('CHAT_ADD_EMOJI', handlerAddEmoji);
        return () => {
            APP.eventManager.unsubscribe('CHAT_ADD_EMOJI', handlerAddEmoji);
        };
    }, []);
    const onSendMessage = throttle(() => {
        if (inputRef.current) {
            const message = inputRef.current.value;
            if (message === '')
                return;
            dispatch(addMessage(message));
            inputRef.current.value = '';
        }
    }, 1000, { leading: true, trailing: false });
    const onKeyPress = e => {
        if (e.key === 'Space' || e.key === 'Enter' || e.key === 'NumpadEnter') {
            e.preventDefault();
            onSendMessage();
        }
    };
    return (React.createElement(Input, { ref: inputRef, className: "chat_input", textarea: true, minRows: 3, maxRows: 3, onKeyPress: onKeyPress }));
});
export default ChatInputConatainer;
