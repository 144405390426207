import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, useLocation } from 'react-router-dom';
import { Routes, useNavigate } from 'react-router-dom/dist';
import { DeviceSelectionContainer } from '../../../device-selection';
import { LobbyContainer } from '../../../lobby';
import { NicknameContainer } from '../../../nickname';
import RoomContainer from '../../../room/RoomContainer';
import { classMateLogin } from '../../local';
import { PAGE_TYPE } from '../constants';
import { InitStyled } from './web/styles';
const BaseApp = React.memo(({ page, roomUid, handlerChangePage }) => {
    return (React.createElement(BrowserRouter, null,
        React.createElement(Room, { page: page, roomUid: roomUid, handlerChangePage: handlerChangePage })));
});
export default BaseApp;
const Room = React.memo(({ page, roomUid, handlerChangePage }) => {
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const location = useLocation();
    const leave = () => APP.management.leave();
    useEffect(() => {
        changePage();
        const receiveMsgFromParent = async (e) => {
            if (e.origin.includes("mate") && e.data.type === "login") {
                dispatch(classMateLogin(e.data));
            }
        };
        const popStateEvent = (e) => {
            if (location.pathname.includes('room')) {
                if (location.hash.includes(PAGE_TYPE.NICKNAME))
                    handlerChangePage(PAGE_TYPE.NICKNAME);
                else if (location.hash.includes(PAGE_TYPE.DEVICE))
                    handlerChangePage(PAGE_TYPE.DEVICE);
                else if (!location.hash)
                    handlerChangePage(PAGE_TYPE.MAIN);
            }
            else if (location.pathname.includes(PAGE_TYPE.ERROR)) {
                handlerChangePage(PAGE_TYPE.ERROR);
            }
            else {
                navigator(`${location.search}`);
            }
            ;
        };
        window.addEventListener('popstate', popStateEvent);
        window.addEventListener('message', receiveMsgFromParent);
        window.addEventListener('unload', leave);
        return () => {
            window.removeEventListener('popstate', popStateEvent);
            window.removeEventListener('message', receiveMsgFromParent);
            window.removeEventListener('unload', leave);
        };
    }, []);
    useEffect(() => {
        changePage();
    }, [page]);
    const changePage = () => {
        let path = '';
        let state = PAGE_TYPE.LOBBY;
        switch (page) {
            case PAGE_TYPE.MAIN:
                path = `/room/${roomUid}${location.search}`;
                state = PAGE_TYPE.MAIN;
                break;
            case PAGE_TYPE.NICKNAME:
                path = `/room/${roomUid}${location.search}#${PAGE_TYPE.NICKNAME}`;
                state = PAGE_TYPE.NICKNAME;
                break;
            case PAGE_TYPE.DEVICE:
                path = `/room/${roomUid}${location.search}#${PAGE_TYPE.DEVICE}`;
                state = PAGE_TYPE.DEVICE;
                break;
            case PAGE_TYPE.ERROR:
                path = `/error${location.search}`;
                state = PAGE_TYPE.ERROR;
                break;
            default:
                path = `/${location.search}`;
                break;
        }
        navigator(path, { state });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(InitStyled, null),
        React.createElement(Routes, null,
            React.createElement(Route, { path: "/error", element: React.createElement(LobbyContainer, { isErrorPage: true }) }),
            React.createElement(Route, { path: `/room/*`, element: React.createElement(React.Fragment, null, page === PAGE_TYPE.NICKNAME ? React.createElement(NicknameContainer, null)
                    : page === PAGE_TYPE.DEVICE ? React.createElement(DeviceSelectionContainer, { isDevicePage: true })
                        : page === PAGE_TYPE.MAIN ? React.createElement(RoomContainer, null) : null) }),
            React.createElement(Route, { path: "/*", element: React.createElement(LobbyContainer, null) }))));
});
