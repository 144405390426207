import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getLocalID } from "../local";
import { deleteReservationRoom, enterRoom, getRoomReservationList } from "./components/ResevationAction";
import { ClickButton } from "../ui";
import { RESERVATION_PAGE_TYPE, ROOM_STATUS, SHOW_DEFAULT_COUNT } from "./constants";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { ReservationList } from "./components/index.web";
const ReservationListContainer = React.memo(({ page, setPage, handlerModifyPage }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const localID = useSelector((state) => getLocalID(state));
    const [list, setList] = useState([]);
    const [pageOption, setPageOption] = useState({ prev: false, next: false, start: 0, end: 0, pageDepth: 0 });
    const [pageIndex, setPageIndex] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    useEffect(() => {
        handlerUpdateRoom();
    }, [page, pageIndex]);
    useEffect(() => {
        const pageDepth = Math.floor(pageIndex / SHOW_DEFAULT_COUNT);
        const limitPage = Math.ceil(totalCount / SHOW_DEFAULT_COUNT);
        const limitPageDepth = Math.floor(limitPage / SHOW_DEFAULT_COUNT);
        setPageOption({
            prev: pageDepth > 0, next: pageDepth < limitPageDepth,
            start: pageDepth * SHOW_DEFAULT_COUNT,
            end: pageDepth >= limitPageDepth ? limitPage : (pageDepth + 1) * SHOW_DEFAULT_COUNT,
            pageDepth
        });
    }, [pageIndex, totalCount]);
    const handlerUpdateRoom = useCallback(() => {
        const roomSetHandler = {
            setList: setList.bind(this),
            setTotalCount: setTotalCount.bind(this)
        };
        getRoomReservationList(localID, page, pageIndex, roomSetHandler);
    }, [localID, page, pageIndex]);
    const renderEtc = (item, styles) => {
        const _onEnterRoom = () => enterRoom(t, dispatch, item);
        const _onModify = () => handlerModifyPage(RESERVATION_PAGE_TYPE.MODIFY, item);
        const _onDelete = () => deleteReservationRoom(t, item.uuid, handlerUpdateRoom);
        return (React.createElement(React.Fragment, null,
            item.owner_uuid === localID
                && item.status !== ROOM_STATUS.HELED
                && RESERVATION_PAGE_TYPE.RESERVE_ROOM === page
                && React.createElement(React.Fragment, null,
                    React.createElement(ClickButton, { styles: styles, icon: "pen", color: "#000", size: 18, onClick: _onModify }),
                    React.createElement(ClickButton, { styles: styles, icon: "delete", color: "#000", size: 18, onClick: _onDelete })),
            React.createElement(ClickButton, { styles: styles, icon: "out", color: "#000", size: 18, onClick: _onEnterRoom })));
    };
    const renderPaging = () => {
        const _goToPrevPage = () => {
            setPageIndex((pageOption.pageDepth - 1) * SHOW_DEFAULT_COUNT);
        };
        const _goToNextPage = () => {
            setPageIndex((pageOption.pageDepth + 1) * SHOW_DEFAULT_COUNT);
        };
        return (React.createElement(React.Fragment, null,
            pageOption.prev &&
                React.createElement(ClickButton, { isText: true, className: "page_button", label: t("reservation.preview"), onClick: _goToPrevPage }),
            _.range(pageOption.start, pageOption.end, 1).map(value => {
                return React.createElement(ClickButton, { key: value, className: `page_button ${value === pageIndex ? "isFocus" : ""}`, isText: true, label: value + 1, onClick: () => setPageIndex(value) });
            }),
            pageOption.next &&
                React.createElement(ClickButton, { isText: true, className: "page_button", label: t("reservation.next"), onClick: _goToNextPage })));
    };
    return (React.createElement(ReservationList, { t: t, list: list, pageIndex: pageIndex, renderPaging: renderPaging, renderEtc: renderEtc }));
});
export default ReservationListContainer;
