import React from "react";

function Login ({
    t, error, renderId, renderPwd, renderLoginButton
}) {
    return (
        <div className="account">
            <p className="guid"> { t("account.loginGuid") }</p>

            <form>
                { renderId() }
                { renderPwd() }
            </form>
            <p className="error"> { t(error) } </p>

            { renderLoginButton() }
        </div>
    )
}

export default React.memo(Login);