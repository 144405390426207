import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SHARE_MODE_LIST, VIDEO_MODE_LIST, getCurrentMode, videoLayoutMode } from '../../../video-layout';
import { ClickButton } from '../../ui';
import AbstractChangeLayout from '../AbstractChangeLayout';
import { useTranslation } from 'react-i18next';
function ChangeLayoutModeButton({ className, enableLable, permissions, iconStyles, fix_mode }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const current_mode = useSelector((state) => getCurrentMode(state));
    const [list, setList] = useState([]);
    const _renderButton = (mode) => {
        const isFocus = current_mode === mode ? 'isFocus' : '';
        const onClick = () => AbstractChangeLayout.changeMode(dispatch, mode);
        if (!APP.config.ui.layout[mode])
            return null;
        return (React.createElement(ClickButton, { key: mode, className: `${className} ${isFocus}`, icon: mode, label: enableLable ? t(`layout.${mode}`) : null, size: enableLable ? 20 : APP.config.style.enableIconSize, styles: iconStyles, onClick: onClick }));
    };
    useEffect(() => {
        const sharePermission = (fix_mode || (SHARE_MODE_LIST.indexOf(current_mode) !== -1 && permissions.indexOf('share') !== -1));
        const videoPermission = (fix_mode || (VIDEO_MODE_LIST.indexOf(current_mode) !== -1 && permissions.indexOf('layout') !== -1));
        setList([
            videoPermission ? videoLayoutMode.grid : null,
            videoPermission ? videoLayoutMode.pin : null,
            videoPermission ? videoLayoutMode.voice : null,
            videoPermission ? videoLayoutMode.seminar : null,
            sharePermission ? videoLayoutMode.document : null,
            sharePermission ? videoLayoutMode.note : null,
            sharePermission ? videoLayoutMode.vod : null,
        ]);
    }, [current_mode, fix_mode, permissions, enableLable]);
    return (list && list.map(item => item && _renderButton(item)));
}
export default ChangeLayoutModeButton;
