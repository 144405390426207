import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClickButton } from "../../ui";
/**
 * VOD 리스트 컴포넌트
 */
const VodList = React.memo(forwardRef(({ children, show_list, disableHeader, }, ref) => {
    const { t } = useTranslation();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "table vod_list center" },
            React.createElement("div", { className: "table_th" }, disableHeader && show_list.map(item => {
                return (React.createElement("div", { key: item, className: item }, t(`reservation.${item}`)));
            })),
            React.createElement("div", { className: "table_body", ref: ref }, children))));
}));
/**
 * VOD 아이템 컴포넌트
 */
const VodItem = React.memo(({ show_list, album_id, streaming_id, initCheck, is_playable_on_chrome, no, name, size, renderBtn }) => {
    const { t } = useTranslation();
    const [isChecked, setIsChecked] = useState(false);
    useEffect(() => {
        setIsChecked(initCheck);
    }, [initCheck]);
    useEffect(() => {
        APP.eventManager.publish('updateVodCheckList', { album_id, streaming_id, name, isChecked });
    }, [isChecked]);
    const renderEtc = useMemo(() => {
        if (is_playable_on_chrome) {
            return (React.createElement(React.Fragment, null,
                renderBtn('vodShow'),
                renderBtn('vodDown'),
                renderBtn('delete')));
        }
        else {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { style: { fontWeight: 'bold', color: "red", flex: 2 } },
                    " ",
                    t('reservation.vodError'),
                    " "),
                renderBtn('delete')));
        }
    }, [is_playable_on_chrome]);
    const renderItem = () => {
        return (show_list.map(item => {
            switch (item) {
                case 'check':
                    const onClick = () => setIsChecked(!isChecked);
                    return React.createElement(ClickButton, { key: item, className: "check", icon: isChecked ? 'checked' : 'unChecked', color: "#000", onClick: onClick });
                case 'no':
                    return React.createElement("div", { key: item, className: item }, no);
                case 'title':
                    return React.createElement("div", { key: item, className: item }, name);
                case 'size':
                    return React.createElement("div", { key: item, className: item }, size);
                case 'etc':
                    return (React.createElement("div", { key: item, className: item }, renderEtc));
            }
        }));
    };
    return (React.createElement("div", { className: "table_td" }, renderItem()));
});
export { VodItem, VodList };
