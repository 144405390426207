import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dialog } from "../dialog";
import { ClickButton, Input } from "../ui";
import { saveNotification } from "./actions";
const SendNotificationPopup = React.memo(() => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [message, setMessage] = useState();
    useEffect(() => {
        if (message && message?.length > 100) {
            APP.UI.alertMessage("error.max100");
            setMessage(message.slice(0, 99));
            return;
        }
    }, [message?.length]);
    const onKeyPress = e => {
        if (e.key === 'Space' || e.key === 'Enter' || e.key === 'NumpadEnter') {
            e.preventDefault();
            onClick();
        }
    };
    const onClick = () => {
        if (!message) {
            APP.UI.alertMessage("error.requestInput");
            return;
        }
        if (message?.length > 100) {
            APP.UI.alertMessage("error.max100");
            return;
        }
        dispatch(saveNotification(message, ''));
    };
    return (React.createElement(Dialog, { titleKey: t("dialog.notification"), className: "send_notification", width: 500 },
        React.createElement(Input, { className: "message_input", textarea: true, placeholder: t("dialog.notificationPlaceholder"), minRows: 6, value: message, onKeyPress: onKeyPress, onChange: setMessage }),
        React.createElement(ClickButton, { label: t("dialog.regist"), isText: true, onClick: onClick })));
});
export default SendNotificationPopup;
