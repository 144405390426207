import React, { useMemo, useState } from "react";
import { Dialog } from "../base/dialog";
import Loading from "../base/loading/components/web/Loading";
import { RESERVATION_PAGE_TYPE } from "../base/reservation/constants";
import RoomConfigurationBodyContainer from "./RoomConfigurationBodyContainer";
import RoomConfigurationDialogContainer from "./RoomConfigurationDialogContainer";
import RoomConfigurationHeadContainer from "./RoomConfigurationHeadContainer";
import { RoomConfiguration } from "./components";
import { DEFAULT_ROOM_PROPERTY } from './constant';
const RoomConfigurationContainer = React.memo(({ isDialog }) => {
    const [load, setLoad] = useState(false);
    const [page, setPage] = useState(DEFAULT_ROOM_PROPERTY);
    const renderHead = useMemo(() => {
        if ([RESERVATION_PAGE_TYPE.REGIST, RESERVATION_PAGE_TYPE.MODIFY].includes(page))
            return null;
        return (React.createElement(RoomConfigurationHeadContainer, { page: page, setPage: setPage }));
    }, [page, setPage]);
    const renderBody = useMemo(() => {
        return (React.createElement(RoomConfigurationBodyContainer, { page: page, setPage: setPage, setLoad: setLoad }));
    }, [page, setPage]);
    const renderDialog = useMemo(() => {
        return (React.createElement(RoomConfigurationDialogContainer, null));
    }, []);
    if (isDialog) {
        return (React.createElement(Dialog, { className: 'vod_dialog', disableHeader: true, width: 900 },
            React.createElement(RoomConfiguration, { page: page },
                renderHead,
                renderBody,
                renderDialog,
                load && React.createElement(Loading, { isLoad: true, load: load }))));
    }
    return (React.createElement(RoomConfiguration, { page: page },
        renderHead,
        renderBody,
        renderDialog,
        load && React.createElement(Loading, { isLoad: true, load: load })));
});
export default RoomConfigurationContainer;
