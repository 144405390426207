import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAudioInputDevice, setVideoInputDevice } from "../base/devices";
import { Dialog, toggleDialog } from "../base/dialog";
import { translate } from "../base/i18n";
import { toggleLoad } from "../base/loading";
import { updateSettings } from "../base/settings";
import { ClickButton } from "../base/ui";
import { enterRoom } from "../room";
import { DeviceSelection } from "./components";

/**
 * 
 * @param {Object} availableDevices 
 */
function DeviceSelectionContainer({
    t, isDevicePage, route, noShowMuted
}) {
    const dispatch = useDispatch();   
    useEffect(() => {
        dispatch(toggleLoad(true));
       
        return () => {
            dispatch(toggleLoad(false));
        }
    }, []); 

    const onSelectDevice = device => {
        dispatch(updateSettings(device));
    }

    const enterRoomButton = (videoId, micId, volume) => {
        const _handlerEnterRoom = () => {
            // dispatch(goToRoom());
            
            if (isDevicePage || (route && route.params && route.params.isDevicePage)) {
                dispatch(enterRoom());
            } else {
                console.log(videoId, micId)
                dispatch(setVideoInputDevice(videoId));
                dispatch(setAudioInputDevice(micId));

                dispatch(toggleDialog());
            }
        }

        return (
            <ClickButton isText={true}
                label={isDevicePage ? t('deviceSelection.enter') : t('dialog.add')} 
                onClick={_handlerEnterRoom} />
        )
    }

    const renderContent = () => {
        return (
            <DeviceSelection t={t} 
                noShowMuted={noShowMuted}
                isModal={!isDevicePage}
                onSelectDevice={onSelectDevice}
                enterRoomButton={enterRoomButton} />
        )
    }

    if (isDevicePage) {
        return renderContent();
    } else {
        return (
            <Dialog titleKey={t("dialog.settingDevice")} width="400">
                { renderContent() }
            </Dialog>
        )
    }
    
}

export default translate(DeviceSelectionContainer);