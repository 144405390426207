import React from "react";
import { useTranslation } from "react-i18next";

function SubAccount({
    auth_name, renderItems
}) {
    const { t } = useTranslation();
    
    return (
        <div className="sub" style={{ color: APP.config.style.subAccountColor }}>
            { auth_name && <span> {t("account.hello", {auth_name})} </span> }
            { renderItems() }
        </div>
    )
}

export default React.memo(SubAccount);